import React, { useState } from 'react'
import { useEffect } from 'react';
import { useFormik } from 'formik';
import { Link, useNavigate, useParams } from 'react-router-dom'
import * as Yup from 'yup';
import Select from "react-select";
import { Button } from '@app/components';
import { getFranchiseAdmins } from '@app/services/user';
import { toast } from 'react-toastify';
import AddUserModal from '../user/AddUserModal';
import { useSelector } from 'react-redux';
import { getFranchiseById, getSubjectHistory, getSubjectRateHistory, getSubjectsByFranchise, updateFranchise } from '@app/services/franchise';
import { Form } from 'react-bootstrap';
import moment from 'moment';
import SubjectHistoryModal from './SubjectHistoryModal';
import SubjectStatusModal from './SubjectStatusModal';
import UpdateSubjectRateModal from './UpdateSubjectRateModal';
import CurrencyFormat from 'react-currency-format';

const EditFranchise = () => {
    const franchiseStatus = [{id:'active', name:'Active'}, {id:'inactive', name:'Inactive'}, {id:'on_hold', name:'On Hold'}];
    const franchiseTypes = [{value:'company', label:'Limited Company'}, {value:'partnership', label:'Partnership'}, {value:'sole_trader', label:'Sole Trader'}];
    let obj: any = {}
    const [isLoading, setLoading] = useState(false);
    const [franchiseData, setFranchiseData] = useState(obj);
    const [franchisees, setFranchisees] = useState([]);
    const [selectedUsers, setSelectedUsers] = useState([]);
    const [subjects, setSubjects] = useState([]);
    const navigate = useNavigate();
    let { id } = useParams();
    const canAccess = useSelector((state: any) => state.auth.permissions.franchise);
    const role = useSelector((state: any) => state.auth.selectedRole);
    const [show, setShow] = useState(false);
    const [reasonShow, setReasonShow] = useState(false);
    const [userFlag, setUserFlag] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const [showHistory, setShowHistory] = useState(false);
    const [showSubStatus, setShowSubStatus] = useState(false);
    const handleSubStatusClose = () => setShowSubStatus(false);
    const handleHistoryClose = () => setShowHistory(false);
    const [subjectName, setSubjectName] = useState('');
    const [subjectHistory, setSubjectHistory] = useState([]);
    const [subjectRateHistory, setSubjectRateHistory] = useState([]);
    const [historyType, setHistoryType] = useState('');
    const [showUpdateRate, setShowUpdateRate] = useState(false);
    const handleUpdateRateClose = () => setShowUpdateRate(false);
    const [subjectPayload, setSubjectPayload] = useState({franchise_id: id, subject: {}});
    const [subjectRatePayload, setSubjectRatePayload] = useState({franchise_id: id, subject: {}});

    useEffect(() => {
        getSubjectsByFranchiseId(id);
    }, []);

    useEffect(() => {
        getFranchiseAdminData();
    }, [userFlag]);

    const getFranchiseAdminData = async () => {
        try {
            const franchiseeResp = await getFranchiseAdmins();
            if (franchiseeResp.success === true) {
                setFranchisees(franchiseeResp.collections);
                getFranchiseDetailsById(id);
            } else {
                toast.error(franchiseeResp?.errors[0]?.msg);
            }
        } catch (error: any) {
            toast.error(error?.response?.message || 'Failed');
        }
    };

    const getFranchiseDetailsById = async (id) => {
        try {
            const resp = await getFranchiseById(id);
            if(resp.success === true){
                setFranchiseData(resp.collections);
                if(resp?.collections?.franchisee.length > 0){
                    setSelectedUsers([].slice.call(resp.collections.franchisee).map((user) => user.id))
                }
                if(resp?.collections?.status === 'on_hold'){
                    setReasonShow(true);
                }
            }else{
                toast.error(resp?.errors[0]?.msg);
                navigate('/franchise');
            }
        } catch (error: any) {
            toast.error(error?.response?.message || 'Failed');
        }
    };

    const getSubjectsByFranchiseId = async (id) => {
        try {
            const resp = await getSubjectsByFranchise(id);
            if(resp.success === true){
                setSubjects(resp.collections);
            }else{
                toast.error(resp?.errors[0]?.msg);
            }
        } catch (error: any) {
            toast.error(error?.response?.message || 'Failed');
        }
    };

    const getSubjectHistoryData = async (id, subjectId) => {
        try {
            const resp = await getSubjectHistory(id, subjectId);
            if(resp.success === true){
                setSubjectHistory(resp.collections);
            }else{
                toast.error(resp?.errors[0]?.msg);
            }
        } catch (error: any) {
            toast.error(error?.response?.message || 'Failed');
        }
    };

    const getSubjectRateHistoryData = async (id, subjectId) => {
        try {
            const resp = await getSubjectRateHistory(id, subjectId);
            if(resp.success === true){
                setSubjectRateHistory(resp.collections);
            }else{
                toast.error(resp?.errors[0]?.msg);
            }
        } catch (error: any) {
            toast.error(error?.response?.message || 'Failed 123');
        }
    };

    const putFranchise = async (payload) => {
        try {
            setLoading(true);
            const resp = await updateFranchise(id, payload);
            if(resp.success === true){
                toast.success(resp?.message);
                setLoading(false);
                navigate('/franchise');
            }else{
                toast.error(resp?.errors[0]?.msg);
                setLoading(false);
            }
          } catch (error: any) {
            setLoading(false);
            toast.error(error?.response?.message || 'Failed');
          }
    };

    const { handleChange, values, handleSubmit, touched, errors, setFieldValue } = useFormik({
        initialValues: {
            name: franchiseData?.name || '',
            type: franchiseData?.type || '',
            address: franchiseData?.address || '',
            phone: franchiseData?.phone || '',
            status: franchiseData?.status || '',
            users: selectedUsers,
            kashflow_order_account: franchiseData?.kashflow_order_account || '',
            kashflow_license_account: franchiseData?.kashflow_license_account || '',
            start_date: franchiseData?.start_date || '',
            end_date: franchiseData?.end_date || '',
            vat: franchiseData?.vat || '',
            reason: franchiseData?.reason || '',
            effective_start_date: franchiseData?.effective_start_date || '',
            student_count_tax: franchiseData?.student_count_tax || false,
            enabled_student_count: franchiseData?.enabled_student_count ||  false,
            student_local_printing: franchiseData?.student_local_printing || false,
            enabled_holiday_camp: franchiseData?.enabled_holiday_camp || false,
            enabled_adhoc_printing: franchiseData?.enabled_adhoc_printing || false,
            enabled_manage_students: franchiseData?.enabled_manage_students || false,
            enabled_recurring_invoice: franchiseData?.enabled_recurring_invoice || false,
        },
        validationSchema: Yup.object({
            name: Yup.string().required('Please enter franchise name'),
            type: Yup.string().required('Please select franchise type'),
            address: Yup.string(),
            phone: Yup.string()
            .matches(/^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/,
				"Please enter valid number"
			),
            status: Yup.string().required('Please select status'),
            users: Yup.array()
            .of(Yup.string().trim())
            .min(1, "Please select at least 1 admin")
            .required("Please select admin"),
            kashflow_license_account: Yup.number().positive('Kashflow licence account must be a positive number').required('Please enter kashflow licence account'),
            kashflow_order_account: Yup.number().positive('Kashflow order account must be a positive number').required('Please enter kashflow order account'),
            start_date: Yup.string().required('Please enter start date'),
            reason: Yup.string().when("status", {
                is: (val: string) => (val === 'on_hold'),
                then: Yup.string().required('Please enter reason'),
            }),
            effective_start_date: Yup.string().required('Please enter effective start date'),
        }),
        onSubmit: (values) => {
            if(role.slug === 'franchise-owner') {
                values.effective_start_date = franchiseData?.effective_start_date
            }
            putFranchise(values);
        },
        enableReinitialize: true,
    });

    const handleAdminsChange = (options: readonly typeof Option[], setFieldValue) => {
        setFieldValue(
          "users",
          [].slice.call(options).map((option) => option.id)
        );
    };

    const statusChange = (e, setFieldValue) => {
        if(e.target.value === 'on_hold'){
            setReasonShow(true);
        }else{
            setReasonShow(false);
        }
        setFieldValue("status", e.target.value);
    };

    const handleHistoryShow = (subject) => {
        setShowHistory(true);
        setSubjectName(subject.name);
        setHistoryType('subject');
        setSubjectRateHistory([]);
        getSubjectHistoryData(id, subject.subject_id);
    }

    const handleSubRateHistoryShow = (subject) => {
        setShowHistory(true);
        setSubjectName(subject.name + ' Rate ');
        setHistoryType('rate');
        setSubjectHistory([]);
        getSubjectRateHistoryData(id, subject.subject_id);
    }

    const handleActivateSubject = (subject) => {
        setShowSubStatus(true);
        setSubjectPayload({franchise_id: id, subject: subject})
    }

    const handleUpdateSubjectRate = (subject) => {
        setShowUpdateRate(true);
        setSubjectName(subject.name);
        setSubjectRatePayload({franchise_id: id, subject: subject});
    }

    const OnWheelPreventChange = (e) => {
		e.currentTarget.blur();
	} 
    
  return (
    <div>
        <section className="content">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-12">
                        <div className="card card-primary">
                            <div className="card-header">
                                <h3 className="card-title">Update Franchise</h3>
                            </div>
                            <form onSubmit={handleSubmit}>
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col-sm-4">
                                            <div className="form-group">
                                                <label htmlFor="exampleInputEmail1">Franchise Name <span className="required">*</span></label>
                                                <input type="text" className="form-control" id="exampleInputEmail1" placeholder="Enter franchise name" 
                                                    name="name" 
                                                    onChange={handleChange} 
                                                    value={values.name}
                                                />
                                                {(touched.name && errors.name) && (
                                                    <div className="text-danger">
                                                        {errors.name}
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                        <div className="col-sm-4">
                                            <div className="form-group">
                                                <label htmlFor="exampleInputEmail1">Franchise Type <span className="required">*</span></label>
                                                <select
                                                    className="form-control"
                                                    name="type"
                                                    onChange={handleChange} 
                                                    value={values.type}
                                                >
                                                    <option value="">--Please select--</option>
                                                    {franchiseTypes.map((obj) => (
                                                        <option key={obj.value} value={obj.value}>
                                                            {obj.label}
                                                        </option>
                                                    ))}
                                                </select>
                                                {(touched.type && errors.type) && (
                                                    <div className="text-danger">
                                                        {errors.type}
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                        <div className="col-sm-4">
                                            <div className="form-group">
                                                <label htmlFor="exampleInputEmail1">Address </label>
                                                <textarea 
                                                    className="form-control" 
                                                    id="exampleInputEmail1" 
                                                    placeholder="Enter address" 
                                                    name="address" 
                                                    onChange={handleChange} 
                                                    value={values.address}
                                                ></textarea>
                                                {(touched.address && errors.address) && (
                                                    <div className="text-danger">
                                                        {errors.address}
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                        <div className="col-sm-4">
                                            <div className="form-group">
                                                <label htmlFor="exampleInputEmail1">Assign Franchisee(s) <span className="required">*</span></label>
                                                <Select
                                                    placeholder={<div>-- Please select --</div>}
                                                    isMulti
                                                    options={franchisees}
                                                    closeMenuOnSelect={false}
                                                    getOptionLabel={(option)=> option.first_name +' '+ option.last_name}
                                                    getOptionValue={(option)=>option.id}
                                                    name="users"
                                                    onChange={ (e) => {handleAdminsChange(e, setFieldValue)}}
                                                    value={
                                                        franchisees?.length
                                                        ? franchisees.filter((obj) =>
                                                            values.users.includes(obj.id)
                                                            )
                                                        : values.users
                                                    } // set selected values
                                                />
                                             <div style={{ marginBottom: 10 }} className="row">
                                                <div className="col-sm-12">
                                                    {(touched.users && errors.users) && (
                                                        <div className="text-danger">
                                                            {errors.users}
                                                        </div>
                                                    )}
                                                </div>
                                                <div className="col-sm-12 text-right">
                                                    { canAccess['create-user'] && <a href='#' onClick={handleShow}> + Add New Franchisee </a> }
                                                </div>
                                            </div>
                                            </div>
                                        </div>
                                        <div className="col-sm-4">
                                            <div className="form-group">
                                                <label htmlFor="exampleInputEmail1">Kashflow Licence Account <span className="required">*</span></label>
                                                <input type="number" className="form-control" id="exampleInputEmail1" placeholder="Enter kashflow license account" 
                                                    name="kashflow_license_account" 
                                                    onChange={handleChange} 
                                                    value={values.kashflow_license_account}
                                                    onWheel={ (e) => OnWheelPreventChange(e) }
                                                />
                                                {(touched.kashflow_license_account && errors.kashflow_license_account) && (
                                                    <div className="text-danger">
                                                        {errors.kashflow_license_account}
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                        <div className="col-sm-4">
                                            <div className="form-group">
                                                <label htmlFor="exampleInputEmail1">Kashflow Order Account <span className="required">*</span></label>
                                                <input type="number" className="form-control" id="exampleInputEmail1" placeholder="Enter kashflow order account" 
                                                    name="kashflow_order_account" 
                                                    onChange={handleChange} 
                                                    value={values.kashflow_order_account}
                                                    onWheel={ (e) => OnWheelPreventChange(e) }
                                                />
                                                {(touched.kashflow_order_account && errors.kashflow_order_account) && (
                                                    <div className="text-danger">
                                                        {errors.kashflow_order_account}
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                        <div className="col-sm-4">
                                            <div className="form-group">
                                                <label htmlFor="exampleInputEmail1">Start Date <span className="required">*</span></label>
                                                <Form.Control 
                                                    type="date" 
                                                    name="start_date" 
                                                    id="start_date" 
                                                    placeholder="Enter start date" 
                                                    value={moment(values.start_date).format('YYYY-MM-DD')}
                                                    onChange={handleChange}
                                                />
                                                {(touched.start_date && errors.start_date) && (
                                                    <div className="text-danger">
                                                        {errors.start_date}
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                        <div className="col-sm-4">
                                            <div className="form-group">
                                                <label htmlFor="exampleInputEmail1">End Date </label>
                                                <Form.Control 
                                                    type="date" 
                                                    name="end_date" 
                                                    id="end_date" 
                                                    placeholder="Enter end date" 
                                                    value={moment(values.end_date).format('YYYY-MM-DD')}
                                                    onChange={handleChange}
                                                />
                                                {(touched.end_date && errors.end_date) && (
                                                    <div className="text-danger">
                                                        {errors.end_date}
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                        <div className="col-sm-4">
                                            <div className="form-group">
                                                <label htmlFor="exampleInputEmail1">Effective Start Date <span className="required">*</span></label>
                                                <Form.Control 
                                                    type="date" 
                                                    name="effective_start_date" 
                                                    id="effective_start_date" 
                                                    placeholder="Enter effective start date" 
                                                    value={moment(values.effective_start_date).format('YYYY-MM-DD')}
                                                    onChange={handleChange}
                                                    disabled={role.slug === 'franchise-owner'}
                                                />
                                                {(touched.effective_start_date && errors.effective_start_date) && (
                                                    <div className="text-danger">
                                                        {errors.effective_start_date}
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                        <div className="col-sm-4">
                                            <div className="form-group">
                                                <label htmlFor="exampleInputEmail1">VAT No. </label>
                                                <input type="text" className="form-control" id="exampleInputEmail1" placeholder="Enter vat" 
                                                    name="vat" 
                                                    onChange={handleChange} 
                                                    value={values.vat}
                                                />
                                                {(touched.vat && errors.vat) && (
                                                    <div className="text-danger">
                                                        {errors.vat}
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                        <div className="col-sm-4">
                                            <div className="form-group">
                                                <label htmlFor="exampleInputEmail1">Phone </label>
                                                <input type="text" className="form-control" id="exampleInputEmail1" placeholder="Enter franchise phone" 
                                                    name="phone" 
                                                    onChange={handleChange} 
                                                    value={values.phone}
                                                />
                                                {(touched.phone && errors.phone) && (
                                                    <div className="text-danger">
                                                        {errors.phone}
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                        <div className="col-sm-4">
                                            <div className="form-group">
                                                <label htmlFor="exampleInputEmail1">Status <span className="required">*</span></label>
                                                <select
                                                    className="form-control"
                                                    name="status"
                                                    onChange={ e => statusChange(e, setFieldValue) }
                                                    value={values.status}
                                                >
                                                    <option value="">--Please select--</option>
                                                    {franchiseStatus.map((obj) => (
                                                        <option key={obj.id} value={obj.id}>
                                                            {obj.name}
                                                        </option>
                                                    ))}
                                                </select>
                                                {(touched.status && errors.status) && (
                                                    <div className="text-danger">
                                                        {errors.status}
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                        { reasonShow && (
                                            <div className="col-sm-4">
                                                <div className="form-group">
                                                    <label htmlFor="exampleInputEmail1">Reason <span className="required">*</span></label>
                                                    <textarea 
                                                        className="form-control" 
                                                        id="exampleInputEmail1" 
                                                        placeholder="Enter reason" 
                                                        name="reason" 
                                                        onChange={handleChange} 
                                                        value={values.reason}
                                                    ></textarea>
                                                    {(touched.reason && errors.reason) && (
                                                        <div className="text-danger">
                                                            {errors.reason}
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                    <div className='row content_inner_wrap'>
                                        <div className="col-sm-12">
                                            <label>Settings</label>
                                        </div>    
                                        <div className="col-sm-4">
                                            <div className="form-group">
                                                {/* <label htmlFor="student_count_tax"><b> VAT Applicable? </b></label> */}
                                                <div className="icheck-primary">
                                                    <input 
                                                        type="checkbox"
                                                        id="student_count_tax"  
                                                        name="student_count_tax" 
                                                        className="form-check-input"
                                                        checked={values.student_count_tax} 
                                                        onChange={(e) => { 
                                                            handleChange 
                                                            setFieldValue("student_count_tax", e.target.checked ? true : false)
                                                        }}
                                                    />
                                                    <label htmlFor="student_count_tax" className="form-check-label">VAT Applicable?</label>
                                                </div>
                                                {(touched.student_count_tax && errors.student_count_tax) && (
                                                    <div className="text-danger">
                                                        {errors.student_count_tax}
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                        <div className="col-sm-4">
                                            <div className="form-group">
                                                {/* <label htmlFor="enabled_student_count"><b> Is Student Count Mandatory? </b></label> */}
                                                <div className="icheck-primary">
                                                    <input 
                                                        type="checkbox"
                                                        id="enabled_student_count"  
                                                        name="enabled_student_count" 
                                                        className="form-check-input"
                                                        checked={values.enabled_student_count} 
                                                        onChange={(e) => { 
                                                            handleChange 
                                                            setFieldValue("enabled_student_count", e.target.checked ? true : false)
                                                        }}
                                                    />
                                                    <label htmlFor="enabled_student_count" className="form-check-label">Is Student Count Mandatory?</label>
                                                </div>
                                                {(touched.enabled_student_count && errors.enabled_student_count) && (
                                                    <div className="text-danger">
                                                        {errors.enabled_student_count}
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                        <div className="col-sm-4">
                                            <div className="form-group">
                                                {/* <label htmlFor="student_local_printing"><b> Enable Local Printing? </b></label> */}
                                                <div className="icheck-primary">
                                                    <input 
                                                        type="checkbox"
                                                        id="student_local_printing"  
                                                        name="student_local_printing" 
                                                        className="form-check-input"
                                                        checked={values.student_local_printing} 
                                                        onChange={(e) => { 
                                                            handleChange 
                                                            setFieldValue("student_local_printing", e.target.checked ? true : false)
                                                        }}
                                                    />
                                                    <label htmlFor="student_local_printing" className="form-check-label">Enable Local Printing?</label>
                                                </div>
                                                {(touched.student_local_printing && errors.student_local_printing) && (
                                                    <div className="text-danger">
                                                        {errors.student_local_printing}
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                        <div className="col-sm-4">
                                            <div className="form-group">
                                                {/* <label htmlFor="enabled_holiday_camp"><b> Enable Holiday Camp? </b></label> */}
                                                <div className="icheck-primary">
                                                    <input 
                                                        type="checkbox"
                                                        id="enabled_holiday_camp"  
                                                        name="enabled_holiday_camp" 
                                                        className="form-check-input"
                                                        checked={values.enabled_holiday_camp} 
                                                        onChange={(e) => { 
                                                            handleChange 
                                                            setFieldValue("enabled_holiday_camp", e.target.checked ? true : false)
                                                        }}
                                                    />
                                                    <label htmlFor="enabled_holiday_camp" className="form-check-label">Enable Holiday Camp?</label>
                                                </div>
                                                {(touched.enabled_holiday_camp && errors.enabled_holiday_camp) && (
                                                    <div className="text-danger">
                                                        {errors.enabled_holiday_camp}
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                        <div className="col-sm-4">
                                            <div className="form-group">
                                                {/* <label htmlFor="enabled_adhoc_printing"><b> Enable Adhoc Printing? </b></label> */}
                                                <div className="icheck-primary">
                                                    <input 
                                                        type="checkbox"
                                                        id="enabled_adhoc_printing"  
                                                        name="enabled_adhoc_printing" 
                                                        className="form-check-input"
                                                        checked={values.enabled_adhoc_printing} 
                                                        onChange={(e) => { 
                                                            handleChange 
                                                            setFieldValue("enabled_adhoc_printing", e.target.checked ? true : false)
                                                        }}
                                                    />
                                                    <label htmlFor="enabled_adhoc_printing" className="form-check-label">Enable Adhoc Printing?</label>
                                                </div>
                                                {(touched.enabled_adhoc_printing && errors.enabled_adhoc_printing) && (
                                                    <div className="text-danger">
                                                        {errors.enabled_adhoc_printing}
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                        <div className="col-sm-4">
                                            <div className="form-group">
                                                {/* <label htmlFor="enabled_manage_students"><b> Enable Adhoc Printing? </b></label> */}
                                                <div className="icheck-primary">
                                                    <input 
                                                        type="checkbox"
                                                        id="enabled_manage_students"  
                                                        name="enabled_manage_students" 
                                                        className="form-check-input"
                                                        checked={values.enabled_manage_students} 
                                                        onChange={(e) => { 
                                                            handleChange 
                                                            setFieldValue("enabled_manage_students", e.target.checked ? true : false)
                                                        }}
                                                    />
                                                    <label htmlFor="enabled_manage_students" className="form-check-label">Enable Manage Students?</label>
                                                </div>
                                                {(touched.enabled_manage_students && errors.enabled_manage_students) && (
                                                    <div className="text-danger">
                                                        {errors.enabled_manage_students}
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                        <div className="col-sm-4">
                                            <div className="form-group">
                                                {/* <label htmlFor="enabled_recurring_invoice"><b> Enable Adhoc Printing? </b></label> */}
                                                <div className="icheck-primary">
                                                    <input 
                                                        type="checkbox"
                                                        id="enabled_recurring_invoice"  
                                                        name="enabled_recurring_invoice" 
                                                        className="form-check-input"
                                                        checked={values.enabled_recurring_invoice} 
                                                        onChange={(e) => { 
                                                            handleChange 
                                                            setFieldValue("enabled_recurring_invoice", e.target.checked ? true : false)
                                                        }}
                                                    />
                                                    <label htmlFor="enabled_recurring_invoice" className="form-check-label">Enable Recurring Invoice?</label>
                                                </div>
                                                {(touched.enabled_recurring_invoice && errors.enabled_recurring_invoice) && (
                                                    <div className="text-danger">
                                                        {errors.enabled_recurring_invoice}
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="card-footer">
                                    <Button type="submit" isLoading={isLoading} className="btn btn-primary mr-2">Update </Button>
                                    <Link className="btn btn-default" to='/franchise'>Close</Link>
                                </div>
                            </form>
                        </div>

                        <div className="card card-primary">
                            <div className="card-header">
                                <h3 className="card-title">Manage Assigned Subjects</h3>
                            </div>
                            <div className="card-body">
                                <div className="row">
                                        {subjects?.length > 0 && (
                                            <>
                                            <div className="col-md-6">
                                                <table className="table table-bordered" id="client_id_table">
                                                    <tbody>
                                                        <tr className='text-center'>
                                                            <th>Subject</th>
                                                            <th>Status</th>
                                                            <th>Start Date</th>
                                                            <th>End Date</th>
                                                            <th>History</th>
                                                        </tr>
                                                        {subjects?.length > 0  && subjects.map((subject) => (
                                                            <tr key={subject.subject_id}>
                                                                <td>{ subject.name }</td>
                                                                <td>
                                                                    <div className="custom-control custom-switch custom-switch-off-danger custom-switch-on-success" data-toggle="tooltip" title="Activate/Inactivate">
                                                                        <input type="checkbox" name={subject.name} checked={subject.status === 'active'} onClick={ (e) => { canAccess['assign-subjects'] ? handleActivateSubject(subject) : 'javascript:void(0);'} } className="custom-control-input" id={subject?.name}/>
                                                                        <label className="custom-control-label" htmlFor={subject.name}></label>
                                                                    </div>
                                                                    {/* <a href="#" onClick={ (e) => { canAccess['assign-subjects'] ? handleActivateSubject(subject) : 'javascript:void(0);'} } data-toggle="tooltip" title="Activate/Inactivate">
                                                                        { subject.status === 'active' ? 
                                                                        <span className="badge bg-success">Active</span> 
                                                                        : <span className="capitalize badge bg-danger">{subject.status.charAt(0).toUpperCase() + subject.status.slice(1)}</span> }
                                                                    </a> */}
                                                                </td>
                                                                <td width={100}>{ (subject.start_date != null) ? moment(subject.start_date).format('DD-MM-YYYY') : 'N/A'}</td>
                                                                <td width={100}>{ (subject.end_date != null) ? moment(subject.end_date).format('DD-MM-YYYY') : 'N/A'}</td>
                                                                <td className='text-center'><a href="#" onClick={ (e) => handleHistoryShow(subject) } data-toggle="tooltip" title="History"> <i className="fas fa-history mr-2" /> </a> </td>
                                                            </tr>
                                                        ))}
                                                    </tbody>
                                                </table>
                                            </div>  
                                            <div className="col-md-3">
                                                <table className="table table-bordered" id="client_id_table">
                                                    <tbody>
                                                        <tr className='text-center'>
                                                            <th>Rate</th>
                                                            <th>Action</th>
                                                        </tr>
                                                        {subjects?.length > 0  && subjects.map((subject) => (
                                                            <tr key={subject.subject_id}>
                                                                <td className='text-center'>
                                                                { subject?.rate ? <CurrencyFormat value={ subject?.rate } decimalScale={2} displayType={'text'} thousandSeparator={true} prefix={'£ '} /> : '---' }
                                                                </td>
                                                                <td className='text-center'>
                                                                    { subject.status === 'active' && (
                                                                        <a href="#" onClick={ (e) => handleUpdateSubjectRate(subject) } data-toggle="tooltip" title="Edit Subject Rate"> <i className="fas fa-pen mr-2" /> </a> 
                                                                    )}
                                                                    <a href="#" onClick={ (e) => handleSubRateHistoryShow(subject) } data-toggle="tooltip" title="Subject Rate History"> <i className="fas fa-history mr-2" /> </a> 
                                                                </td>
                                                            </tr>
                                                        ))}
                                                    </tbody>
                                                </table>
                                            </div>  
                                            </>
                                        )}
                                        {subjects?.length == 0 && (
                                            <span style={{ color: "red", textAlign: "center" }}>There are no records to display</span>
                                        )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <AddUserModal 
                show={show}
                handleClose={handleClose}
                setUserFlag={setUserFlag}
            />
            <SubjectHistoryModal
                show={showHistory}
                handleClose={handleHistoryClose}
                subjectName={subjectName}
                type={historyType}
                historyData={subjectHistory}
                rateHistoryData={subjectRateHistory}
            />
            <SubjectStatusModal
                show={showSubStatus}
                handleClose={handleSubStatusClose}
                payload={subjectPayload}
                getSubjectsByFranchiseId={getSubjectsByFranchiseId}
            />

            <UpdateSubjectRateModal
                show={showUpdateRate}
                handleClose={handleUpdateRateClose}
                payload={subjectRatePayload}
                getSubjectsByFranchiseId={getSubjectsByFranchiseId}
            />
        </section>
    </div>
  )
}

export default EditFranchise;