import React, { useState } from 'react'
import { useEffect } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom'
import { toast } from 'react-toastify';
import { getFranchiseById, getSubjectsByFranchise } from '@app/services/franchise';
import { Form } from 'react-bootstrap';
import moment from 'moment';
import { useSelector } from 'react-redux';
import CurrencyFormat from 'react-currency-format';

const ViewFranchise = () => {
    const franchiseTypes = [{value:'company', label:'Limited Company'}, {value:'partnership', label:'Partnership'}, {value:'sole_trader', label:'Sole Trader'}];
    const franchiseStatus = [{value:'active', label:'Active'}, {value:'inactive', label:'Inactive'}, {value:'on_hold', label:'On Hold'}];
    let obj: any = {}
    const [isLoading, setLoading] = useState(false);
    const [franchise, setFranchiseData] = useState(obj);
    const [subjects, setSubjects] = useState([]);
    const [selectedUsers, setSelectedUsers] = useState([]);
    const canAccess = useSelector((state: any) => state.auth.permissions.franchise);
    const navigate = useNavigate();
    let { id } = useParams();
    
    useEffect(() => {
        getFranchiseDetailsById(id);
        getSubjectsByFranchiseId(id);
    }, []);

    const getFranchiseDetailsById = async (id) => {
        try {
            setLoading(true);
            const resp = await getFranchiseById(id);
            if(resp.success === true){
                setLoading(false);
                setFranchiseData(resp.collections);
                if(resp?.collections?.franchisee !== null){
                    setSelectedUsers(resp?.collections?.franchisee.map.call(resp?.collections?.franchisee, function(user) { return user.first_name +' '+user.last_name; }).join(", "))
                }
            }else{
                setLoading(false);
                toast.error(resp?.errors[0]?.msg);
                navigate('/franchise');
            }
        } catch (error: any) {
            setLoading(false);
            toast.error(error?.response?.message || 'Failed');
        }
    };

    const getSubjectsByFranchiseId = async (id) => {
        try {
            const resp = await getSubjectsByFranchise(id);
            if(resp.success === true){
                setSubjects(resp.collections);
            }else{
                toast.error(resp?.errors[0]?.msg);
            }
        } catch (error: any) {
            toast.error(error?.message || 'Failed');
        }
    };

  return (
    <div>
        <section className="content">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-12">
                        <div className="card card-primary">
                            <div className="card-header">
                                <h3 className="card-title">View Franchise</h3>
                            </div>
                            <div className="card-body">
                                <div className="row">
                                    <div className="col-sm-4">
                                        <div className="form-group">
                                            <label htmlFor="exampleInputEmail1">Franchise Name </label>
                                            <Form.Control plaintext readOnly value={franchise.name} />
                                        </div>
                                    </div>
                                    <div className="col-sm-4">
                                        <div className="form-group">
                                            <label htmlFor="exampleInputEmail1">Franchise Type </label>
                                            <Form.Control plaintext readOnly value={franchise?.type ? (franchiseTypes.find(obj => { return obj.value === franchise?.type}))?.label : '---'} />
                                        </div>
                                    </div>
                                    <div className="col-sm-4">
                                        <div className="form-group">
                                            <label htmlFor="exampleInputEmail1">Address </label>
                                            <dd>{franchise.address ? franchise.address : '---'}</dd>
                                        </div>
                                    </div>
                                    <div className="col-sm-4">
                                        <div className="form-group">
                                            <label htmlFor="exampleInputEmail1">Franchisee(s) </label>
                                            <Form.Control plaintext readOnly value={selectedUsers ? selectedUsers : '---'} />   
                                        </div>
                                    </div>
                                    {/* <div className="col-sm-4">
                                        <div className="form-group">
                                            <label htmlFor="exampleInputEmail1">Kashflow Licence Account </label>
                                            <Form.Control plaintext readOnly value={franchise.kashflow_license_account ? franchise.kashflow_license_account : '---'} />
                                        </div>
                                    </div>
                                    <div className="col-sm-4">
                                        <div className="form-group">
                                            <label htmlFor="exampleInputEmail1">Kashflow Order Account </label>
                                            <Form.Control plaintext readOnly value={franchise.kashflow_order_account ? franchise.kashflow_order_account : '---'} />
                                        </div>
                                    </div> */}
                                    <div className="col-sm-4">
                                        <div className="form-group">
                                            <label htmlFor="exampleInputEmail1">Start Date </label>
                                            <Form.Control plaintext readOnly value={franchise?.start_date ? moment(franchise.start_date).format('DD/MM/YYYY') : '---'} />
                                        </div>
                                    </div>
                                    <div className="col-sm-4">
                                        <div className="form-group">
                                            <label htmlFor="exampleInputEmail1">End Date </label>
                                            <Form.Control plaintext readOnly value={franchise?.end_date ? moment(franchise.end_date).format('DD/MM/YYYY') : '---'} />
                                        </div>
                                    </div>
                                    <div className="col-sm-4">
                                        <div className="form-group">
                                            <label htmlFor="exampleInputEmail1">Effective Start Date </label>
                                            <Form.Control plaintext readOnly value={franchise?.effective_start_date ? moment(franchise.effective_start_date).format('DD/MM/YYYY') : '---'} />
                                        </div>
                                    </div>
                                    <div className="col-sm-4">
                                        <div className="form-group">
                                            <label htmlFor="exampleInputEmail1">VAT </label>
                                            <Form.Control plaintext readOnly value={franchise.vat ? franchise.vat : '---'} />
                                        </div>
                                    </div>
                                    <div className="col-sm-4">
                                        <div className="form-group">
                                            <label htmlFor="exampleInputEmail1">Phone </label>
                                            <Form.Control plaintext readOnly value={franchise.phone ? franchise.phone : '---'} />
                                        </div>
                                    </div>
                                    <div className="col-sm-4">
                                        <div className="form-group">
                                            <label htmlFor="exampleInputEmail1">Status </label>
                                            <Form.Control plaintext readOnly value={franchise?.status ? (franchiseStatus.find(obj => { return obj.value === franchise?.status}))?.label : '---'} />
                                        </div>
                                    </div>
                                    { franchise.reason != null && (
                                        <div className="col-sm-4">
                                            <div className="form-group">
                                                <label htmlFor="exampleInputEmail1">Reason </label>
                                                <dd>{franchise.reason ? franchise.reason : '---'}</dd>
                                            </div>
                                        </div>
                                    )}
                                </div>
                                <div className='row content_inner_wrap'>
                                    <div className="col-sm-12">
                                        <label>Settings</label>
                                    </div>  
                                    <div className="col-sm-4">
                                        <div className="form-group">
                                            <dt>Is VAT Applicable? </dt>
                                            <dd>{franchise?.student_count_tax ? 'Yes' : 'No'} </dd>
                                        </div>
                                    </div>
                                    <div className="col-sm-4">
                                        <div className="form-group">
                                            <dt>Is Student Count Mandatory? </dt>
                                            <dd>{franchise?.enabled_student_count ? 'Yes' : 'No'} </dd>
                                        </div>
                                    </div>
                                    <div className="col-sm-4">
                                        <div className="form-group">
                                            <dt>Enabled Local Printing? </dt>
                                            <dd>{franchise?.student_local_printing ? 'Yes' : 'No'} </dd>
                                        </div>
                                    </div>
                                    <div className="col-sm-4">
                                        <div className="form-group">
                                            <dt>Enabled Holiday Camp? </dt>
                                            <dd>{franchise?.enabled_holiday_camp ? 'Yes' : 'No'} </dd>
                                        </div>
                                    </div>
                                    <div className="col-sm-4">
                                        <div className="form-group">
                                            <dt>Enabled Adhoc Printing? </dt>
                                            <dd>{franchise?.enabled_adhoc_printing ? 'Yes' : 'No'} </dd>
                                        </div>
                                    </div>
                                    <div className="col-sm-4">
                                        <div className="form-group">
                                            <dt>Enabled Manage Students? </dt>
                                            <dd>{franchise?.enabled_manage_students ? 'Yes' : 'No'} </dd>
                                        </div>
                                    </div>
                                    <div className="col-sm-4">
                                        <div className="form-group">
                                            <dt>Enabled Recurring Invoice? </dt>
                                            <dd>{franchise?.enabled_recurring_invoice ? 'Yes' : 'No'} </dd>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="card-footer">
                            { canAccess?.edit && 
                                <Link className="btn btn-primary mr-2" to={`/franchise/edit/${id}`}>Edit</Link>
                            }
                                <Link className="btn btn-default" to='/franchise'>Close</Link>
                            </div>
                        </div>
                        <div className="card card-primary">
                            <div className="card-header">
                                <h3 className="card-title">Manage Assigned Subjects</h3>
                            </div>
                            <div className="card-body">
                                <div className="row">
                                    <div className="col-md-6">
                                        {subjects?.length > 0 && (
                                            <table className="table table-bordered" id="client_id_table">
                                                <tbody>
                                                    <tr className='text-center'>
                                                        <th>Subject</th>
                                                        <th>Status</th>
                                                        <th>Start Date</th>
                                                        <th>End Date</th>
                                                        <th>Rate</th>
                                                        {/* <th>History</th> */}
                                                    </tr>
                                                    {subjects?.length > 0  && subjects.map((subject) => (
                                                        <tr key={subject.subject_id}>
                                                            <td>{ subject.name }</td>
                                                            <td>
                                                                    { subject.status === 'active' ? 
                                                                    <span className="badge bg-success">Active</span> 
                                                                    : <span className="capitalize badge bg-danger">{subject.status.charAt(0).toUpperCase() + subject.status.slice(1)}</span> }
                                                            </td>
                                                            <td width={100}>{ (subject.start_date != null) ? moment(subject.start_date).format('DD-MM-YYYY') : 'N/A'}</td>
                                                            <td width={100}>{ (subject.end_date != null) ? moment(subject.end_date).format('DD-MM-YYYY') : 'N/A'}</td>
                                                            <td className=''>
                                                                { subject?.rate ? <CurrencyFormat value={ subject?.rate } decimalScale={2} displayType={'text'} thousandSeparator={true} prefix={'£ '} /> : '---' }
                                                            </td>
                                                            {/* <td className='text-center'><a href="#" onClick={ (e) => handleHistoryShow(subject) } data-toggle="tooltip" title="History"> <i className="fas fa-history mr-2" /> </a> </td> */}
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </table>
                                        )}
                                        {subjects?.length == 0 && (
                                            <span style={{ color: "red", textAlign: "center" }}>There are no records to display</span>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
  )
}

export default ViewFranchise;