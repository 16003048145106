import React, { useState } from 'react'
import { useEffect } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom'
import { toast } from 'react-toastify';
import { getDBSDocumentById, viewUserById } from '@app/services/user';
import PreviewModal from '../ops-manual/PreviewModal';
import { useSelector } from 'react-redux';
import fileDownload from 'js-file-download';
import moment from 'moment';
import { getExtension } from '@app/services/opsManual';

const ViewUser = () => {
    const userStatus = [{value:'active', label:'Active'}, {value:'inactive', label:'Inactive'}];
    let obj: any = {}
    const [isLoading, setLoading] = useState(false);
    const [user, setUserData] = useState(obj);
    const [roles, setRoles] = useState('');
    const [franchise, setFranchise] = useState('');
    const [centres, setCentres] = useState('');
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const [url, setUrl] = useState('');
    const [filename, setFileName] = useState('');
    const [dbsDocuments, setDBSDocuments] = useState([]);
    const canAccess = useSelector((state: any) => state.auth.permissions.users);
    const navigate = useNavigate();
    let { id } = useParams();
    
    useEffect(() => {
        getUserDetailsById(id);
    }, []);

    const getUserDetailsById = async (id) => {
        try {
            setLoading(true);
            const resp = await viewUserById(id);
            if(resp?.success === true){
                setLoading(false);
                setUserData(resp.collections);
                if(resp?.collections?.roles !== null){
                    setRoles(resp?.collections?.roles.map.call(resp?.collections?.roles, function(role) { return role.name; }).join(", "))
                }
                if(resp?.collections?.franchise !== null){
                    setFranchise(resp?.collections?.franchise.map.call(resp?.collections?.franchise, function(record) { return record.name; }).join(", "))
                }
                if(resp?.collections?.centre !== null){
                    setCentres(resp?.collections?.centre.map.call(resp?.collections?.centre, function(record) { return record.name; }).join(", "))
                }
                if(Object.keys(resp?.collections?.dbs).length > 0){
                    setDBSDocuments(resp?.collections?.dbs?.dbs_documents)
                }
            }else{
                setLoading(false);
                toast.error(resp?.errors[0]?.msg);
                navigate('/users');
            }
        } catch (error: any) {
            setLoading(false);
            toast.error(error?.message || 'Failed');
        }
    };

    const previewFile = async (docId, name) => {
        try{
            const resp = await getDBSDocumentById(docId);
            setUrl(URL.createObjectURL(resp))
            setFileName(name)
            handleShow();

        } catch (error) {
            setLoading(false);
            console.log(error)
        }
    }

    const downloadDocument = async (docId, name) => {
        try{
            setLoading(true);
            const resp = await getDBSDocumentById(docId);
            fileDownload(resp, name);
            setLoading(false);        
        } catch (error) {
            setLoading(false);
            console.log(error)
        }
    }

  return (
    <div>
        <section className="content">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-12">
                        <div className="card card-primary">
                            <div className="card-header">
                                <h3 className="card-title">View User</h3>
                            </div>
                            <div className="card-body">
                                <div className="row">
                                    <div className="col-sm-4">
                                        <div className="form-group">
                                            <label htmlFor="exampleInputEmail1">First Name </label>
                                            <dd>{user?.first_name ? user?.first_name : '---'}</dd>
                                        </div>
                                    </div>
                                    <div className="col-sm-4">
                                        <div className="form-group">
                                            <label htmlFor="exampleInputEmail1">Last Name </label>
                                            <dd>{user?.last_name ? user?.last_name : '---'}</dd>
                                        </div>
                                    </div>
                                    <div className="col-sm-4">
                                        <div className="form-group">
                                            <label htmlFor="exampleInputEmail1">Email </label>
                                            <dd>{user?.email ? user?.email : '---'}</dd>
                                        </div>
                                    </div>
                                    <div className="col-sm-4">
                                        <div className="form-group">
                                            <label htmlFor="exampleInputEmail1">Alternate Email </label>
                                            <dd>{user?.alt_email ? user?.alt_email : '---'}</dd>
                                        </div>
                                    </div>
                                    <div className="col-sm-4">
                                        <div className="form-group">
                                            <label htmlFor="exampleInputEmail1">Mobile </label>
                                            <dd>{user?.phone ? user?.phone : '---'}</dd>
                                        </div>
                                    </div>
                                    <div className="col-sm-4">
                                        <div className="form-group">
                                            <label htmlFor="exampleInputEmail1">Alternate Mobile </label>
                                            <dd>{user?.alt_phone ? user?.alt_phone : '---'}</dd>
                                        </div>
                                    </div>
                                    <div className="col-sm-4">
                                        <div className="form-group">
                                            <label htmlFor="exampleInputEmail1">Address </label>
                                            <dd>{user?.address ? user?.address : '---'}</dd>
                                        </div>
                                    </div>
                                    <div className="col-sm-4">
                                        <div className="form-group">
                                            <label htmlFor="exampleInputEmail1">Roles </label>
                                            <dd>{roles ? roles : '---'}</dd>
                                        </div>
                                    </div>
                                    <div className="col-sm-4">
                                        <div className="form-group">
                                            <label htmlFor="exampleInputEmail1">Franchise Attached </label>
                                            <dd>{franchise ? franchise : '---'}</dd>
                                        </div>
                                    </div>
                                    <div className="col-sm-4">
                                        <div className="form-group">
                                            <label htmlFor="exampleInputEmail1">Centre Attached </label>
                                            <dd>{centres ? centres : '---'}</dd>
                                        </div>
                                    </div>
                                    <div className="col-sm-4">
                                        <div className="form-group">
                                            <label htmlFor="exampleInputEmail1">Status </label>
                                            <dd>{user?.status ? (userStatus.find(obj => { return obj.value === user?.status}))?.label : '---'}</dd>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="card-footer">
                                <Link className="btn btn-primary mr-2" to={`/users/edit/${id}`}> <i className="fas fa-pencil-alt"></i> Edit</Link>
                                <Link className="btn btn-default" to='/users'>Close</Link>
                            </div>
                        </div>
                    </div>
                    {canAccess['view-dbs'] && (
                        <div className="col-md-12">
                        <div className="card card-primary">
                            <div className="card-header">
                                <h3 className="card-title">View DBS Details </h3>
                            </div>
                            <div className="card-body">
                                
                                {user?.dbs && Object.keys(user?.dbs).length == 0 && (
                                    <div className='col-md-12'>
                                        <span className='text-danger'>No DBS certificate details have been added for this user.</span>
                                    </div>
                                )}
                                {user?.dbs && Object.keys(user?.dbs).length > 0 && (
                                    <>
                                    <div className="row">
                                        <div className="col-sm-4">
                                            <div className="form-group">
                                                <dt>DBS Cert. Date </dt>
                                                <dd>{ user?.dbs?.cert_date ? moment(user?.dbs?.cert_date).format('DD/MM/YYYY') : '---' }</dd>
                                            </div>
                                        </div>
                                        <div className="col-sm-4">
                                            <div className="form-group">
                                                <dt>DBS Cert. Number </dt>
                                                <dd>{ user?.dbs?.cert_number ?? '---' }</dd>
                                            </div>
                                        </div>
                                        <div className="col-sm-4">
                                            <div className="form-group">
                                                <dt>DOB For Update Service Check </dt>
                                                <dd>{ user?.dbs?.update_service_check_date ? moment(user?.dbs?.update_service_check_date).format('DD/MM/YYYY') : '---' }</dd>
                                            </div>
                                        </div>
                                        <div className="col-sm-4">
                                            <div className="form-group">
                                                <dt>Update Service </dt>
                                                <dd>{ user?.dbs?.update_service ? 'Yes' : 'No' }</dd>
                                            </div>
                                        </div>
                                        <div className="col-sm-4">
                                            <div className="form-group">
                                                <dt>DBS Cert Copy Held? </dt>
                                                <dd>{ user?.dbs?.status_issues ? 'Yes' : 'No' }</dd>
                                            </div>
                                        </div>
                                        <div className="col-sm-4">
                                            <div className="form-group">
                                                <dt>Surname Per DBS Cert For Update Service Check </dt>
                                                <dd>{ user?.dbs?.surname_cert ?? '---' }</dd>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        {dbsDocuments.length > 0 && (
                                            <div className='col-md-6'>
                                            <div className='content'>
                                            <div className='content_inner_wrap'>
                                            <div className='row'>
                                            <div className='col-xl-12'>
                                                <b>DBS Documents</b>
                                            </div>
                                            <div className='col-xl-12'>
                                            <div className='list_module_wrapper'>
                                                <table className="table table-bordered" id="client_id_table">
                                                    <tbody>
                                                        <tr className='text-center'>
                                                            <th>Document</th>
                                                            <th>Action</th>
                                                        </tr>
                                                        {dbsDocuments.length > 0 && dbsDocuments.map(file => (
                                                            <tr key={file?.id}>
                                                                <td>
                                                                    <a href='javascript:void(0)' onClick={ (e) => { downloadDocument(file?.id, file?.name)} } data-toggle="tooltip" title="Preview" className='mx-3'> 
                                                                        {file?.name}
                                                                    </a>
                                                                </td>
                                                                <td className='pl-5'>
                                                                    <a href='javascript:void(0)' onClick={ (e) => { downloadDocument(file?.id, file?.name)} } data-toggle="tooltip" title="Download Document">
                                                                        <i className="fas fa-download mr-2"></i>
                                                                    </a> 
                                                                    { (['docx', 'doc', 'xlsx', 'xls', 'wav'].findIndex((ext) => ext == getExtension(file?.name))) < 0 &&
                                                                        <a href='javascript:void(0)'
                                                                        onClick={ (e) => { previewFile(file?.id, file?.name)} }
                                                                            data-toggle="tooltip" title="Preview Document">
                                                                            <i className="fas fa-eye fa-sm mr-2"/>
                                                                        </a>
                                                                    }
                                                                </td>
                                                            </tr>
                                                        ))}
                                                    </tbody>
                                                </table>
                                            
                                            </div>
                                            </div>
                                            </div>
                                            </div>
                                            </div>
                                            </div>
                                        )}
                                        {dbsDocuments.length == 0 && (
                                            <span className='text-danger'>No DBS documents have been uploaded for this user.</span>
                                        )}
                                    </div>
                                    </>
                                )}
                            </div>
                            <div className="card-footer">
                                <Link className="btn btn-primary mr-2" to={`/users/dbs/${id}`}> <i className="fas fa-pencil-alt"></i> Edit DBS Cert.</Link>
                                <Link className="btn btn-default" to='/users'>Close</Link>
                            </div>
                        </div>
                        </div>
                    )}
                </div>
            </div>
            <PreviewModal
                show={show}
                handleClose={handleClose}
                url={url}
                filename={filename}
            />
        </section>
    </div>
  )
}

export default ViewUser;