import axios from "axios";
import { addStudentURL,listStudentURL,getStudentByIdURL,updateStudentURL,getAllGuardianPaginateURL, learningPathwayURL, studentWorkURL, downloadStudentWorksheetsURL, studentURL, studentsURL, adhocPrintURL, adhocPrintHistoryURL, studentFeesCreditsURL, studentInvoiceURL } from "@app/constants/ApiEndPoints";

export const addStudentService = async (payload) => {
	try {
		const response = await axios.post(addStudentURL, payload);
		if (response.status === 201 /*&& response.statusText === "Created"*/) {
			return response.data
		}
	} catch (error) {
		return error?.response?.data;
	}
};

export const getAllStudentWithPaginationService = async (page, perPage, sortField, sortDirection, search) => {
	try {
		const response = await axios.get(listStudentURL + `?page=${page}&perPage=${perPage}&sortField=${sortField}&sortDirection=${sortDirection}&firstName=${search.first_name}&lastName=${search.last_name}&franchiseId=${search.franchiseId}&campOnly=${search.campOnly}&status=${search.status}&email=${search.email}`);
		if (response.status === 200 /*&& response.statusText === "Created"*/) {
			return response.data
		}
	} catch (error) {
		return error?.response?.data;
	}
};

export const getStudentById = async (id: number) => {
	try {
		const response = await axios.get(getStudentByIdURL + id);
		if (response.status === 200 /*&& response.statusText === "OK"*/) {
			return response.data
		}
	} catch (error) {
		return error?.response?.data;
	}
};

export const updateStudent = async (id, payload) => {
	try {
		const response = await axios.put(updateStudentURL + id, payload);
		if (response.status === 201 /*&& response.statusText === "Created"*/) {
			return response.data
		}
	} catch (error) {
		return error?.response?.data;
	}
};

export const getAllParentWithPaginationService = async (page, perPage, sortField, sortDirection, search) => {
	try {
		const response = await axios.get(getAllGuardianPaginateURL + `?page=${page}&perPage=${perPage}&sortField=${sortField}&sortDirection=${sortDirection}&firstName=${search.first_name}&lastName=${search.last_name}&franchise=${search.franchise_id}&status=${search.status}&email=${search.email}`);
		if (response.status === 200 /*&& response.statusText === "Created"*/) {
			return response.data
		}
	} catch (error) {
		return error?.response?.data;
	}
};

export const getStudentRollNumberCounter = async (payload) => {
	try {
		const response = await axios.post(studentsURL + 'roll-number/counter', payload);
		if (response.status === 200 /*&& response.statusText === "OK"*/) {
			return response.data
		}
	} catch (error) {
		return error?.response?.data;
	}
};

export const getStudAssignWorkById = async (payload) => {
	try {
		const response = await axios.post(learningPathwayURL + 'student/work', payload);
		if (response.status === 200 /*&& response.statusText === "OK"*/) {
			return response.data
		}
	} catch (error) {
		return error?.response?.data;
	}
};

export const processStudentWork = async (payload) => {
	try {
		const response = await axios.post(studentWorkURL + 'create/work', payload);
		if (response.status === 200 /*&& response.statusText === "Created"*/) {
			return response.data
		}
	} catch (error) {
		return error?.response?.data;
	}
};

export const getStudentWorkSummaryById = async (payload) => {
	try {
		const response = await axios.post(studentWorkURL +'work/summary', payload);
		if (response.status === 200 /*&& response.statusText === "Created"*/) {
			return response.data
		}
	} catch (error) {
		return error?.response?.data;
	}
};

export const downloadStudentWorksheets = async (payload) => {
	try {
		const response = await axios.post(downloadStudentWorksheetsURL, payload, { responseType: 'blob' });
		if (response.status === 200 /*&& response.statusText === "OK"*/) {
			return response.data
		}
	} catch (error) {
		return error?.response?.data;
	}
};

export const getStudentWorkHistoryWithPagination = async (studId, page, perPage, sortField, sortDirection, search) => {
	try {
		const response = await axios.get(studentWorkURL + studId + `/work/history?page=${page}&perPage=${perPage}&sortField=${sortField}&sortDirection=${sortDirection}&moduleId=${search.moduleId}&fromDate=${search.fromDate}&toDate=${search.toDate}`);
		if (response.status === 200 /*&& response.statusText === "Created"*/) {
			return response.data
		}
	} catch (error) {
		return error?.response?.data;
	}
};

export const getStudentSubjectsById = async (studId) => {
	try {
		const response = await axios.get(studentURL +'subjects/' + studId);
		if (response.status === 200 /*&& response.statusText === "Created"*/) {
			return response.data
		}
	} catch (error) {
		return error?.response?.data;
	}
};

export const getStudentActiveSubjectsById = async (studId) => {
	try {
		const response = await axios.get(studentURL +'subjects/' + studId + '/active');
		if (response.status === 200 /*&& response.statusText === "Created"*/) {
			return response.data
		}
	} catch (error) {
		return error?.response?.data;
	}
};

export const downloadStudentWeekSummary = async (payload) => {
	try {
		const response = await axios.post(studentWorkURL + 'weeks/summary', payload, { responseType: 'blob' });
		if (response.status === 200 /*&& response.statusText === "OK"*/) {
			return response.data
		}
	} catch (error) {
		return error?.response?.data;
	}
};

export const createStudentWSPrintHistory = async (payload) => {
	try {
		const response = await axios.post(studentWorkURL +'print/history', payload);
		if (response.status === 200 /*&& response.statusText === "Created"*/) {
			return response.data
		}
	} catch (error) {
		return error?.response?.data;
	}
};

export const updatePrinterStatusByHistoryId = async (payload) => {
	try {
		const response = await axios.put(studentWorkURL +'print/history', payload);
		if (response.status === 200 /*&& response.statusText === "Created"*/) {
			return response.data
		}
	} catch (error) {
		return error?.response?.data;
	}
};

export const addWSToPrintQueue = async (payload) => {
	try {
		const response = await axios.post(adhocPrintURL, payload);
		if (response.status === 201 /*&& response.statusText === "Created"*/) {
			return response.data
		}
	} catch (error) {
		return error?.response?.data;
	}
};

export const getPrintQueueByStudentId = async (id: number) => {
	try {
		const response = await axios.get(adhocPrintURL + '/' + id);
		if (response.status === 200 /*&& response.statusText === "OK"*/) {
			return response.data
		}
	} catch (error) {
		return error?.response?.data;
	}
};

export const clearPrintQueueByStudentId = async (id) => {
	try {
		const response = await axios.get(adhocPrintURL + '/clear/' + id);
		if (response.status === 200 /*&& response.statusText === "OK"*/) {
			return response.data
		}
	} catch (error) {
		return error?.response?.data;
	}
};

export const deleteItemFromPrintQueue = async (id: number) => {
	try {
		const response = await axios.get(adhocPrintURL + '/clear/item/' + id);
		if (response.status === 200 /*&& response.statusText === "OK"*/) {
			return response.data
		}
	} catch (error) {
		return error?.response?.data;
	}
};

export const downloadStudentAdhocWorksheets = async (id) => {
	try {
		const response = await axios.get(adhocPrintURL + '/preview/' + id, { responseType: 'blob' });
		// const response = await axios.get('http://13.40.177.40/api/v1/admin/students/print/preview/' + id, { responseType: 'blob' });
		if (response.status === 200 /*&& response.statusText === "OK"*/) {
			return response.data
		}
	} catch (error) {
		return error?.response?.data;
	}
};

export const createStudAdhocWSPrintHistory = async (payload) => {
	try {
		const response = await axios.post(adhocPrintHistoryURL +'create/history', payload);
		if (response.status === 200 /*&& response.statusText === "Created"*/) {
			return response.data
		}
	} catch (error) {
		return error?.response?.data;
	}
};

export const updateStudAdhocWSPrinterStatus = async (payload) => {
	try {
		const response = await axios.post(adhocPrintHistoryURL +'update/history', payload);
		if (response.status === 200 /*&& response.statusText === "Created"*/) {
			return response.data
		}
	} catch (error) {
		return error?.response?.data;
	}
};

export const getStudentsByFranchiseId = async (id: number) => {
	try {
		const response = await axios.get(studentURL + '/franchise/' + id);
		if (response.status === 200 /*&& response.statusText === "OK"*/) {
			return response.data
		}
	} catch (error) {
		return error?.response?.data;
	}
};

export const updateStudentSubject = async (studId, payload) => {
	try {
		const response = await axios.put(studentURL +'subjects/' + studId, payload);
		if (response.status === 200 /*&& response.statusText === "Created"*/) {
			return response.data
		}
	} catch (error) {
		return error?.response?.data;
	}
};

export const getStudentSubjectHistory = async (payload) => {
	try {
		const response = await axios.post(studentURL +'subjects/history', payload);
		if (response.status === 200 /*&& response.statusText === "Created"*/) {
			return response.data
		}
	} catch (error) {
		return error?.response?.data;
	}
};

export const getFeesAndCreditsByStudentWithPagination = async (page, perPage, sortField, sortDirection, search) => {
	try {
		const response = await axios.get(studentFeesCreditsURL + `?page=${page}&perPage=${perPage}&sortField=${sortField}&sortDirection=${sortDirection}
															&studentId=${search.studentId}&vatIncluded=${search.vatIncluded}&amount=${search.amount}&status=${search.status}`);
		if (response.status === 200 /*&& response.statusText === "OK"*/) {
			return response.data
		}
	} catch (error) {
		return error?.response?.data;
	}
};

export const addFeesCredits = async (payload) => {
	try {
		const response = await axios.post(studentFeesCreditsURL + '/create', payload);
		if (response.status === 201 /*&& response.statusText === "Created"*/) {
			return response.data
		}
	} catch (error) {
		return error?.response?.data;
	}
};

export const deleteFeesCreditById = async (id: number) => {
	try {
		const response = await axios.delete(studentFeesCreditsURL + '/' + id);
		if (response.status === 200 /*&& response.statusText === "OK"*/) {
			return response.data
		}
	} catch (error) {
		return error?.response?.data;
	}
};

export const getStudentInvoicesWithPagination = async (page, perPage, sortField, sortDirection, search) => {
	try {
		const response = await axios.get(studentInvoiceURL + `?page=${page}&perPage=${perPage}&sortField=${sortField}&sortDirection=${sortDirection}
															&studentName=${search.studentName}&franchiseId=${search.franchiseId}&yearGroupId=${search.yearGroupId}&invoiceNumber=${search.invoiceNumber}&invoiceAmount=${search.invoiceAmount}&invoiceStatus=${search.invoiceStatus}`);
		if (response.status === 200 /*&& response.statusText === "OK"*/) {
			return response.data
		}
	} catch (error) {
		return error?.response?.data;
	}
};

export const getStudentInvoiceDetailsById = async (id) => {
	try {
		const response = await axios.get(studentInvoiceURL + `/${id}/details`);
		if (response.status === 200 /*&& response.statusText === "Created"*/) {
			return response.data
		}
	} catch (error) {
		return error?.response?.data;
	}
};

export const genarateStudentRecurringInvoices = async (payload) => {
	try {
		const response = await axios.post(studentInvoiceURL + '/recurring', payload);
		if (response.status === 200 /*&& response.statusText === "Created"*/) {
			return response.data
		}
	} catch (error) {
		return error?.response?.data;
	}
};

export const downloadStudentInvoice = async (invoiceId) => {
	try {
		const response = await axios.get(studentInvoiceURL + `/${invoiceId}/file`, { responseType: 'blob' });
		if (response.status === 200 /*&& response.statusText === "OK"*/) {
			return response.data
		}
	} catch (error) {
		return error?.response?.data;
	}
};