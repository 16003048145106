import React, { useState } from 'react'
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import { Button } from '@app/components';
import { Form, Modal } from 'react-bootstrap';
import { updateStudentSubject } from '@app/services/student';
import moment from 'moment';
import CurrencyFormat from 'react-currency-format';

const UpdateStudentSubjectModal = (props) => {

    const [isLoading, setLoading] = useState(false);
    let vatTypes = []
    if(props?.subjectVAT)
        vatTypes = [{value: 'inclusive' , name:'Inclusive', isDisabled: false, isChecked: false}, {value: 'exclusive', name:'Exclusive', isDisabled: false, isChecked: false}, {value: 'not_applicable', name:'N/A', isDisabled: true, isChecked: false}]
    else
        vatTypes = [{value: 'inclusive' , name:'Inclusive', isDisabled: true, isChecked: false}, {value: 'exclusive', name:'Exclusive', isDisabled: true, isChecked: false}, {value: 'not_applicable', name:'N/A', isDisabled: false, isChecked: true}]

    const updateStudentSubjectAPI = async (payload) => {
        try {
            setLoading(true);
            const resp = await updateStudentSubject(props?.studentId, payload);
            if(resp.success === true){
                setLoading(false);
                toast.success(resp?.message);
                props.handleClose();
                props.getSubjectsByStudentId(props?.studentId);
            }else{
                toast.error(resp?.errors[0]?.msg);
                setLoading(false);
            }
        } catch (error: any) {
            setLoading(false);
            toast.error(error?.message || 'Internal Server Error');
        }
    };

    const { handleChange, values, handleSubmit, touched, errors, setFieldValue } = useFormik({
        initialValues: {
            subject_id: props?.payload?.subject?.id ?? '',
            rate: props?.payload?.subject?.rate ?? 0,
            vat_status: props?.subjectVAT ? props?.payload?.subject?.vat_status : 'not_applicable',
            start_date: props?.payload?.subject?.start_date ?? '',
            first_invoice_rate: props?.payload?.subject?.first_invoice_rate ?? '',
            is_default: false,
            end_date: '',
            status: props?.payload?.subject?.status == 'active' ? 'inactive' : 'active',
        },
        validationSchema: Yup.object({
            rate: Yup.number().required('Please enter rate per month')
                    .min(0, "Rate must be greater than or equal to 0")
                    .max(1000000, "Rate must be less than or equal to 1,000,000"),
            vat_status: Yup.string().nullable().required('Please select VAT'),
            start_date: Yup.date().when('status', {
                is: (val: string) => val == 'active' && props?.payload?.type == 'subject',
                then: Yup.date().required('Please enter start date')
            }),
            first_invoice_rate: Yup.number().when('status', {
                is: (val: string) => val == 'active' && props?.payload?.type == 'subject',
                then:  Yup.number().required('Please enter first invoice amount')
                        .min(0, "Rate must be greater than or equal to 0")
                        .max(1000000, "Rate must be less than or equal to 1,000,000"),
            }),
            end_date: Yup.date().when('status', {
                is: (val: string) => val == 'inactive' && props?.payload?.type == 'subject',
                then: Yup.date().required('Please enter end date')
                .min(new Date(moment(props?.payload?.subject?.start_date !== null ? props?.payload?.subject?.start_date : new Date()).format('YYYY-MM-DD')), 'Date should be greater than start date'),
            }),
        }),
        onSubmit: (values) => {
            if(props?.payload?.type == 'rate'){
                delete values.start_date
                delete values.end_date
                delete values.status
                delete values.first_invoice_rate
                delete values.is_default
            }
            if(!values.is_default){
                values.first_invoice_rate = values.rate
            }
            updateStudentSubjectAPI(values);
        },
        enableReinitialize: true,
    });

    return (
    <div>
        <Modal show={props.show} onHide={props.handleClose} size="sm">
            <Modal.Header closeButton>
                <Modal.Title>{ props?.payload?.type == 'rate' ? 'Update Subject Rate' : (props?.payload?.subject?.status == 'active' ? 'Inactivate Subject' : 'Activate Subject')} </Modal.Title>
            </Modal.Header>
            <form onSubmit={handleSubmit}>
                <Modal.Body>
                    <div className='row'>
                        <div className="col-sm-12">
                            <div className="form-group">
                                <dt>Subject Name </dt>
                                <dd>{ props?.payload?.subject?.name } </dd>
                            </div>
                        </div>
                        {((props?.payload?.subject?.status != 'active' && props?.payload?.type == 'subject') || props?.payload?.type == 'rate') && (
                            <>
                            <div className="col-sm-12">
                                <div className="form-group">
                                    <label htmlFor="exampleInputEmail1">Rate Per Month<span className="required">*</span></label>
                                    <div className='input-group'>
                                        <div className="input-group-prepend">
                                            <span className="input-group-text">{ props?.currency }</span>
                                        </div>
                                        <Form.Control 
                                            type="number" 
                                            name="rate" 
                                            id="rate" 
                                            placeholder="Enter subject rate" 
                                            value={values.rate}
                                            onChange={ (e) => {
                                                setFieldValue('rate', e.target.value)
                                                if(!values.is_default){
                                                    setFieldValue('first_invoice_rate', e.target.value)
                                                }
                                            }}
                                            onWheel={ (e) => e.currentTarget.blur() }
                                            disabled={values?.status == 'inactive' && props?.payload?.type == 'subject'}
                                        />
                                    </div>
                                    {(touched.rate && errors.rate) && (
                                        <div className="text-danger">
                                            {errors.rate}
                                        </div>
                                    )}
                                </div>
                            </div>
                            <div className="col-sm-12">
                                <div className="form-group">
                                    <label htmlFor="vat_status">VAT <span className="required">*</span></label>
                                    {(vatTypes).map((obj) => (
                                        <div className="form-check col-sm-12" key={obj?.value}>
                                            <input 
                                                className="form-check-input" 
                                                id="vat_status"
                                                type="radio" 
                                                name="vat_status" 
                                                onChange={ (e) => {
                                                    setFieldValue('vat_status', e.target.value)
                                                }}
                                                value={obj?.value}
                                                checked={obj?.value == values.vat_status}
                                                disabled={obj?.isDisabled || (values?.status == 'inactive' && props?.payload?.type == 'subject')}
                                            />
                                            <label className="form-check-label">{ obj?.name }</label><br/>
                                        </div>
                                    )) }
                                    {(touched.vat_status && errors.vat_status) && (
                                        <div className="text-danger">
                                            {errors.vat_status}
                                        </div>
                                    )}
                                </div>
                            </div>
                            </>
                        )}
                        {props?.payload?.subject?.status == 'active' && props?.payload?.type == 'subject' && (
                            <>
                            <div className="col-sm-12">
                                <div className="form-group">
                                    <dt>Rate Per Month </dt>
                                    <dd>
                                        <CurrencyFormat value={ props?.payload?.subject?.rate } decimalScale={2} displayType={'text'} thousandSeparator={true} prefix={props?.currency + ' '} /> &nbsp;
                                        (<b>VAT</b> : { props?.payload?.subject?.vat_status ? (vatTypes.find(obj => { return obj.value === props?.payload?.subject?.vat_status}))?.name : '---'})
                                    </dd>
                                </div>
                            </div>
                            </>
                        )}
                        {values.status == 'active' && props?.payload?.type == 'subject' && (
                            <>
                            <div className="col-sm-12">
                                <div className="form-group">
                                    <label htmlFor="start_date">Start Date <span className="required">*</span></label>
                                    <Form.Control 
                                        type="date" 
                                        id="start_date" 
                                        name="start_date" 
                                        placeholder="Enter start date" 
                                        value={moment(values.start_date).format('YYYY-MM-DD')}
                                        onChange={ handleChange }
                                    />
                                    {(touched.start_date && errors.start_date) && (
                                        <div className="text-danger">
                                            {errors.start_date}
                                        </div>
                                    )}
                                </div>
                            </div>
                            <div className="col-sm-12">
                                <div className="form-group">
                                    <label htmlFor="exampleInputEmail1">First Invoice Amount<span className="required">*</span></label>
                                    <div className='input-group'>
                                        <div className="input-group-prepend">
                                            <span className="input-group-text">{ props?.currency }</span>
                                        </div>
                                        <Form.Control 
                                            type="number" 
                                            name="first_invoice_rate" 
                                            id="first_invoice_rate" 
                                            placeholder="Enter first invoice amount" 
                                            value={!values.is_default ? values.rate : values.first_invoice_rate}
                                            onChange={handleChange}
                                            onWheel={ (e) => e.currentTarget.blur() }
                                            disabled={!values.is_default}
                                        />
                                    </div>
                                    {(touched.first_invoice_rate && errors.first_invoice_rate) && (
                                        <div className="text-danger">
                                            {errors.first_invoice_rate}
                                        </div>
                                    )}
                                </div>
                            </div>
                            <div className="col-sm-12">
                                <div className="icheck-primary" style={{marginTop: '38px !important'}}>
                                    <input 
                                        type="checkbox" 
                                        id="is_default" 
                                        name="is_default" 
                                        className="form-check-input" 
                                        checked={values.is_default} 
                                        // onChange={ handleChange }
                                        onChange={ (e) => {
                                            setFieldValue('is_default', e.target.checked)
                                            setFieldValue('first_invoice_rate', !e.target.checked? values.rate : '')
                                        }}
                                    />
                                    <label htmlFor="is_default" className="form-check-label"> Is First Invoice Amount Different? </label>
                                </div>
                            </div>
                            </>
                        )}
                        {values.status != 'active' && props?.payload?.type == 'subject' && (
                            <div className="col-sm-12">
                                <div className="form-group">
                                    <label htmlFor="end_date">End Date <span className="required">*</span></label>
                                    <Form.Control 
                                        type="date" 
                                        id="end_date" 
                                        name="end_date" 
                                        placeholder="Enter end date" 
                                        value={moment(values.end_date).format('YYYY-MM-DD')}
                                        onChange={ handleChange }
                                    />
                                    {(touched.end_date && errors.end_date) && (
                                        <div className="text-danger">
                                            {errors.end_date}
                                        </div>
                                    )}
                                </div>
                            </div>
                        )}
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button type="submit" isLoading={isLoading} className="btn btn-primary mr-2">Update </Button>
                    <Button className='btn btn-default' onClick={props.handleClose}>Close</Button>
                </Modal.Footer>
            </form>
        </Modal>
    </div>
  )
}

export default UpdateStudentSubjectModal;