import React, { useEffect, useRef, useState } from 'react'
import { Link } from 'react-router-dom';
import 'react-confirm-alert/src/react-confirm-alert.css';
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';
import { getAllStudentWithPaginationService } from '@app/services/student';
import DataTable, { TableStyles } from 'react-data-table-component';
import { Button } from '@app/components';
 import { getAllActiveFranchise } from '@app/services/franchise';
import Select from "react-select";

const ListStudents = () => {

    const selectRef = useRef(null);
    const [students, setStudents] = useState([]);
    const [isLoading, setLoading] = useState(false);
    const [totalRows, setTotalRows] = useState(0);
    const [perPage, setPerPage] = useState(10);
    const [currentPage, setCurrentPage] = useState(1);
    const [sortField, setSortField] = useState('');
    const [sortDirection, setSortDirection] = useState('');
    const [search, setSearch] = useState({first_name: '',last_name: '', franchiseId: '', email: '', campOnly: '', status: ''});
    const [franchises, setFranchises] = useState([]);
    const [franchiseCount, setFranchiseCount] = useState(0);
    const studentStatus = [{label:'Active', value:'active'}, {label:'Inactive', value:'inactive'}]
    const canAccess = useSelector((state: any) => state.auth.permissions);

    const customStyle: TableStyles = {
        head: {
            style: {
                color: "#5E5873",
                fontSize: "13px",
                fontWeight: 900,
            },
        },
        headCells: {
            style: {
                borderColor: "#E9ECEF",
                textTransform: "uppercase",
                letterSpacing: "1px",
            },
        },
        headRow: {
            style: {
                backgroundColor: "#F7F7F8",
                minHeight: "44px",
                fontWeight: 500,
            },
        },
    };

    const customStylesSelect = {
        dropdownIndicator: () => ({
            display: 'none',
        }),
    }

    useEffect(() => {
        getAllStudentService(1, perPage, sortField, sortDirection);
    },[search]);

    useEffect(() => {
        getAllActiveFranchiseData();
    },[]);

    const getAllStudentService = async (page, perPage, sortField, sortDirection) => {
        try {
            setLoading(true);
            const studentsResp = await getAllStudentWithPaginationService(page, perPage, sortField, sortDirection, search);
            if (studentsResp.success === true) {
                setLoading(false);
                setStudents(studentsResp.collections.data);
                setTotalRows(studentsResp?.collections?.pagination?.total);
            } else {
                setLoading(false);
                toast.error(studentsResp?.errors[0]?.msg);
            }
        } catch (error: any) {
            setLoading(false);
            toast.error(error?.response?.message || 'Failed');
        }
    };

    const getAllActiveFranchiseData = async () => {
        try {
            const resp = await getAllActiveFranchise();
            if (resp?.success === true) {
                setFranchises(resp?.collections);
                setFranchiseCount(resp?.collections.filter(obj => obj?.enabled_manage_students).length)
            } else {
                toast.error(resp?.errors[0]?.msg);
            }
        } catch (error: any) {
            toast.error(error?.response?.message || 'Failed');
        }
    };

    const columns = [
        {
            name: 'First Name',
            selector: (row: { first_name: string;}) => row.first_name,
            sortable: true,
            sortField: 'first_name',
            wrap: true,
        },
        {
            name: 'Last Name',
            selector: (row: {last_name: string;}) => row.last_name,
            sortable: true,
            sortField: 'last_name',
            wrap: true,
        },
        {
            name: 'Gender',
            selector: (row: { gender:string; }) => row.gender.charAt(0).toUpperCase(),
            sortable: true,
            sortField: 'gender',
            wrap: true,
        },
        {
            name: 'DOB',
            selector: (row: { date_of_birth: string; }) => row.date_of_birth ? row.date_of_birth : '---',
            sortable: true,
            sortField: 'date_of_birth',
        },
        {
            name: 'School',
            selector: (row: { school_name: string; }) => row.school_name ? row.school_name : '---',
            sortable: true,
            sortField: 'school_name',
        },
        {
            name: 'Franchise',
            selector: (row: { franchise_name: string; }) => row.franchise_name ? row.franchise_name : '---',
            sortable: true,
            sortField: 'franchise_name',
            wrap: true,
        },
        {
            name: 'Centre',
            selector: (row: { centre_name: string; }) => row.centre_name ? row.centre_name : '---',
            sortable: true,
            sortField: 'centre_name',
            wrap: true,
        },
        {
            name: 'Camp Only',
            selector: (row: { camp_only:string; }) => row.camp_only,
            cell: (row: { camp_only: boolean }) => (
                row.camp_only ? <span className="badge bg-success">Yes</span> : <span className="capitalize badge bg-danger">No</span>
            ),
            sortable: true,
            sortField: 'camp_only',
            wrap: true,
        },
        {
            name: 'Status',
            selector: (row: { status: string; }) => row.status,
            cell: (row: { status: string; }) => (
                row.status === 'active' ? <span className="badge bg-success">Active</span> : <span className="capitalize badge bg-danger">{row.status}</span>
            ),
            sortable: true,
            sortField: 'status',
        },
        {  
            name: 'Action',
            cell: (row: { id: number, status: string, student_local_printing: boolean, camp_only: boolean, enabled_holiday_camp: boolean, enabled_adhoc_printing:boolean}) => (
              <>
                { canAccess?.student?.edit &&
                    <Link
                    to={`/student/edit/${row.id}`}
                    role="button"
                    state={{ id: row.id }}
                    data-toggle="tooltip" title="Edit Student Details"
                    >
                    <i className="fas fa-pen mr-2"/>
                    </Link>
                }
                { canAccess?.student?.view &&
                    <Link
                    to={`/student/view/${row.id}`}
                    role="button"
                    state={{ id: row.id }}
                    data-toggle="tooltip" title="View Student Details"
                    >
                    <i className="fas fa-eye mr-2"/>
                    </Link>
                }
                { canAccess?.student?.['set-work'] && row.status === 'active' && !row.camp_only &&
                    <Link
                        to={`/student/set-work/${row.id}`}
                        role="button"
                        state={{ id: row.id }}
                        data-toggle="tooltip" title="Set Work"
                    >
                        <i className="fas fa-network-wired mr-2"/>
                    </Link>
                }
                { canAccess?.student?.['work-summary'] && !row.camp_only &&
                    <Link
                        to={`/student/work-summary/${row.id}`}
                        role="button"
                        state={{ id: row.id }}
                        data-toggle="tooltip" title="Work Summary"
                    >
                        <i className="fas fa-book-reader mr-2"/>
                    </Link>
                }
                {/* { canAccess?.student?.['work-history'] &&
                    <Link
                        to={`/student/work-history/${row.id}`}
                        role="button"
                        state={{ id: row.id }}
                        data-toggle="tooltip" title="Work History"
                    >
                        <i className="fas fa-history mr-2" />
                    </Link>
                } */}
                { canAccess?.student?.['adhoc-printing'] && row.enabled_adhoc_printing && row.status === 'active' &&
                    <Link
                        to={`/student/adhoc-printing/${row.id}`}
                        role="button"
                        state={{ id: row.id }}
                        data-toggle="tooltip" title="Adhoc Printing"
                    >
                        <i className="fas fa-print mr-2" />
                    </Link>
                }

                { canAccess?.student?.['enroll-in-camp'] && row.enabled_holiday_camp && row.status === 'active' &&
                    <Link
                        to={`/student/enroll-in-camp/${row.id}`}
                        role="button"
                        state={{ id: row.id }}
                        data-toggle="tooltip" title="Enroll In The Camp"
                    >   
                        <i className="fas fa-chalkboard-teacher"/>
                    </Link>
                }
              </>
            ),
            minWidth: '14%'
        },
    ];

    const handlePageChange = page => {
        getAllStudentService(page, perPage, sortField, sortDirection);
        setCurrentPage(page);
    };

    const handlePerRowsChange = async (newPerPage, page) => {
        getAllStudentService(page, newPerPage, sortField, sortDirection);
        setPerPage(newPerPage);
    };

    const handleSort = async (column, sortDirection) => {
        setSortField(column.sortField);
        setSortDirection(sortDirection);
        getAllStudentService(currentPage, perPage, column.sortField, sortDirection);
    };

    const handleResetFilter = async () => {
        selectRef.current.setValue('');
        setSearch({first_name:'',last_name: '', franchiseId: '', email: '', campOnly: '', status: ''});
    };

    const onChangeFranchise = (option) => {
        setSearch({ ...search, franchiseId: option.id });
    };

    return (
        <div>
            <div>
                {/* Content Header (Page header) */}
                <section className="content-header">
                    <div className="container-fluid">
                        <div className="row mb-2">
                            <div className="col-sm-6">
                                <h1>Manage Students</h1>
                            </div>
                            <div className="col-sm-6">
                                <ol className="breadcrumb float-sm-right">
                                    <li className="breadcrumb-item"><a href="/">Home</a></li>
                                    <li className="breadcrumb-item active">Manage Students</li>
                                </ol>
                            </div>
                        </div>
                    </div>{/* /.container-fluid */}
                </section>
                {/* Main content */}
                <section className="content">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-12">
                                <div style={{ marginBottom: 10 }} className="row">
                                    
                                </div>
                                <div className="card">
                                    <div className="card-header">
                                        <h3 className="card-title">Manage Students</h3>
                                        <div className="col-lg-12 text-right">
                                            { canAccess?.student?.create && franchiseCount > 0 &&
                                                <Link className="btn btn-primary" to="/add-student" > + Add New Student </Link>
                                            }
                                            { franchiseCount == 0 &&
                                                <div className='text-danger'>
                                                    <i className="icon fas fa-exclamation-triangle" />
                                                    This panel will be live shortly
                                                </div>
                                            }
                                        </div>
                                    </div>
                                    {/* /.card-header */}
                                    <div className="card-body">
                                        <div className="row mb-2">
                                            <div className="col-sm-2">
                                                <input type="text" name='firstName' className="form-control" value={search.first_name} onChange={(e) => setSearch({ ...search, first_name: e.target.value })} placeholder="Search By First Name" />
                                            </div>
                                            <div className="col-sm-2">
                                                <input type="text" name='lastName' className="form-control" value={search.last_name} onChange={(e) => setSearch({ ...search, last_name: e.target.value })} placeholder="Search By Last Name" />
                                            </div>
                                            <div className="col-sm-3">
                                                <Select
                                                    ref={selectRef}
                                                    name="franchiseId"
                                                    placeholder="Search By Franchise"
                                                    options={franchises}
                                                    getOptionValue={(option)=>`${option['id']}`}
                                                    getOptionLabel={(option)=>`${option['name']}`}
                                                    onChange={ (option) => {onChangeFranchise(option)}}
                                                    // styles={customStylesSelect}
                                                />
                                            </div>
                                            <div className="col-sm-2">
                                                <input type="text" name='email' className="form-control" value={search.email} onChange={(e) => setSearch({ ...search, email: e.target.value })} placeholder="Search By Email" />
                                            </div>
                                            <div className="col-sm-3">
                                                <div className="icheck-primary">
                                                    <input 
                                                        type="checkbox" 
                                                        className="form-check-input" 
                                                        // checked={values.camp_only}
                                                        id="camp_only"
                                                        name="camp_only"
                                                        onChange={(e) => setSearch({ ...search, campOnly: e.target.checked ? 'true' : 'false' })}
                                                    />
                                                    <label htmlFor="camp_only" className="form-check-label">
                                                        Holiday Camp Only
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='row mb-2'>
                                            <div className="col-sm-2">
                                                <select
                                                    className="form-control"
                                                    name="status"
                                                    onChange={(e) => setSearch({ ...search, status: e.target.value })}
                                                    value={search.status}
                                                >
                                                    <option value="">Search By Status</option>
                                                    {studentStatus.map((obj) => (
                                                        <option key={obj.value} value={obj.value}>
                                                            {obj.label}
                                                        </option>
                                                    ))}
                                                </select>
                                            </div>
                                            <div className='col-sm-2'>
                                                <Button type="submit" isLoading={isLoading} onClick={handleResetFilter} className="btn btn-warning">Reset Filter</Button>
                                            </div>
                                        </div>

                                        <DataTable
                                            columns={columns}
                                            data={students}
                                            pagination
                                            paginationServer
                                            paginationTotalRows={totalRows}
                                            paginationDefaultPage={currentPage}
                                            onChangePage={handlePageChange}
                                            onChangeRowsPerPage={handlePerRowsChange}
                                            paginationPerPage={perPage}
                                            paginationRowsPerPageOptions={[5, 10, 20, 30, 50, 100]}
                                            progressPending={isLoading}
                                            sortServer
                                            onSort={handleSort}
                                            customStyles={customStyle}
                                        />
                                    </div>
                                    {/* /.card-body */}
                                </div>
                            </div>
                            {/* /.col */}
                        </div>
                        {/* /.row */}
                    </div>
                    {/* /.container-fluid */}
                </section>
                {/* /.content */}
            </div>

        </div>
    )
};

export default ListStudents;