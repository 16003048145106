import React, { useState } from 'react'
import { useEffect } from 'react';
import { Link,useNavigate, useParams } from 'react-router-dom'
import { toast } from 'react-toastify';
import { getFeesAndCreditsByStudentWithPagination, getStudentById, getStudentSubjectsById } from '@app/services/student';
import { useSelector } from 'react-redux';
import moment from 'moment';
import CurrencyFormat from 'react-currency-format';
import { getPostcodeCountryByFranchiseId } from '@app/services/franchise';
import DataTable, { TableStyles } from 'react-data-table-component';
import StudentAggregatedCreditsModal from './StudentAggregatedCreditsModal';

const ViewStudent = () => {
    let obj: any = {}
    const [isLoading, setLoading] = useState(false);
    const [student, setStudentData] = useState(obj);
    const [subjects, setStudentSubjects] = useState([]);
    const canAccess = useSelector((state: any) => state.auth.permissions.student);
    const [currency, setCurrency] = useState('');
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const [aggregatedData, setAggregatedData] = useState([]);
    const navigate = useNavigate();
    let { id } = useParams();

    const [totalRows, setTotalRows] = useState(0);
    const [perPage, setPerPage] = useState(10);
    const [currentPage, setCurrentPage] = useState(1);
    const [sortField, setSortField] = useState('');
    const [sortDirection, setSortDirection] = useState('');
    const [availableCredits, setAvailableCredits] = useState(0);
    const [outstandingFees, setOutstandingFees] = useState(0);
    const [search, setSearch] = useState({studentId: id, type: '', vatIncluded: '', amount: '', status: ''});
    const [studentCreditFees, setStudentCreditFees] = useState([]);
    const vatTypes = [{value: 'inclusive' , name:'Inclusive'}, {value: 'exclusive', name:'Exclusive'}, {value: 'not_applicable', name:'N/A'}];

    useEffect(() => {
        getStudentDetailsById(id);
        getSubjectsByStudentId(id);
        getCreditFeesByStudentId(1, perPage, sortField, sortDirection)
    }, []);

    const getStudentDetailsById = async (id) => {
        try {
            setLoading(true);
            const resp = await getStudentById(id);
            if(resp.success === true){
                setLoading(false);
                setStudentData(resp.collections);
                getCountryByFranchiseId(resp.collections?.franchise_id)
            }else{
                setLoading(false);
                toast.error(resp?.errors[0]?.msg);
                navigate('/students');
            }
        } catch (error: any) {
            setLoading(false);
            toast.error(error?.message || 'Internal server error');
        }
    };

    const getSubjectsByStudentId = async (id) => {
        try {
            const resp = await getStudentSubjectsById(id);
            if(resp.success === true){
                setStudentSubjects(resp.collections);
            }else{
                toast.error(resp?.errors[0]?.msg);
            }
        } catch (error: any) {
            toast.error(error?.message || 'Internal server error');
        }
    };

    const getCountryByFranchiseId = async (franchiseId) => {
        try {
            const resp = await getPostcodeCountryByFranchiseId(franchiseId);
            if (resp?.success === true) {
                setCurrency(resp?.collections?.currency ?? '')
            } else {
                toast.error(resp?.errors[0]?.msg);
            }
        } catch (error: any) {
            toast.error(error?.message || 'Internal server error');
        }
    };

    const getCreditFeesByStudentId = async (page, perPage, sortField, sortDirection) => {
        try {
            const resp = await getFeesAndCreditsByStudentWithPagination(page, perPage, sortField, sortDirection, search);
            if(resp.success === true){
                setLoading(false);
                setStudentCreditFees(resp.collections.data);
                setTotalRows(resp?.collections?.pagination?.total);
                setAvailableCredits(resp?.collections?.pagination?.available_credits)
                setOutstandingFees(resp?.collections?.pagination?.outstanding_fees)
            }else{
                toast.error(resp?.errors[0]?.msg);
            }
        } catch (error: any) {
            toast.error(error?.message || 'Internal server error');
        }
    };

    const columns = [
        {
            name: '#Id',
            selector: (row: { id: string; }) => `#${row.id}`,
            sortable: true,
            sortField: 'id',
            // width: '7%',
            wrap: true
        },
        {
            name: 'Type',
            selector: (row: { type: string; }) => row.type.toUpperCase(),
            sortable: true,
            sortField: 'type',
            // width: '8%',
        },
        // {
        //     name: 'Description',
        //     selector: (row: { description: string; }) => row.description,
        //     sortable: true,
        //     sortField: 'description',
        //     wrap: true
        // },
        {
            name: 'Amount',
            selector: (row: { amount: string; }) => row.amount,
            format: (row: { amount: string }) => <CurrencyFormat value={ parseFloat(row.amount).toFixed(2) } decimalScale={2} displayType={'text'} thousandSeparator={true} prefix={currency + ' '} />,
            sortable: true,
            sortField: 'amount',
        },
        {
            name: 'VAT',
            selector: (row: { vat_status: string; }) => (vatTypes.find(obj => { return obj.value === row.vat_status}))?.name,
            sortable: true,
            sortField: 'vat_status',
            // width: '7%',
            wrap: true
        },
        // {
        //     name: 'Settled Amount',
        //     selector: (row: { invoiced_amount: string; }) => row.invoiced_amount,
        //     format: (row: { invoiced_amount: string }) => row.invoiced_amount !== null ? <CurrencyFormat value={ parseFloat(row.invoiced_amount).toFixed(2) } decimalScale={2} displayType={'text'} thousandSeparator={true} prefix={currency + ' '} /> : '---',
        //     sortable: true,
        //     sortField: 'total_price',
        // },
        {
            name: 'Status',
            selector: (row: { status: string, aggregated_credits: [];}) => row.status,
            format: (row: { status: string, aggregated_credits: [] }) => (
                row.status !== null ? (row.status === 'active' ? 
                    <span className="badge badge-success">{ row.status.charAt(0).toUpperCase() + row.status.slice(1)}</span> : 
                    <><span className="badge badge-secondary">{ row.status.charAt(0).toUpperCase() + row.status.slice(1) } </span> {row.aggregated_credits.length > 0  ? <a href='#' onClick={(e) => handleAggregatedCredit(e, row.aggregated_credits)}> <i className='fa fa-info-circle' title='View Aggregated Credits'/> </a>: ''} </>) : '---'
            ),
            sortable: true,
            sortField: 'status',
            // width: '10%',
        },
        // {
        //     name: 'Invoice No.',
        //     selector: (row: { invoice_number_id : string; }) => row.invoice_number_id ?? '---',
        //     sortable: true,
        //     sortField: 'invoice_number_id',
        //     // width: '12%',
        //     wrap: true
        // },
        {
            name: 'Created On',
            selector: (row: { created_at: string; }) => row.created_at,
            cell: (row: { created_at: string; }) => (
                row.created_at ? moment(row.created_at).format('DD/MM/YYYY') : '---'
            ),
            sortable: true,
            sortField: 'created_at',
            // width: '10%',
        },
        // {
        //     name: 'Settled On',
        //     selector: (row: { invoiced_at: string; }) => row.invoiced_at,
        //     cell: (row: { invoiced_at: string; }) => (
        //         row.invoiced_at ? moment(row.invoiced_at).format('DD/MM/YYYY') : '---'
        //     ),
        //     sortable: true,
        //     sortField: 'invoiced_at',
        //     // width: '10%',
        //     wrap: true
        // }
    ];

    const handleAggregatedCredit = (e, aggregated_credits) => {
        e.preventDefault()
        setShow(true)
        setAggregatedData(aggregated_credits)
    }

    const customStyle: TableStyles = {
        head: {
            style: {
                color: "#5E5873",
                fontSize: "13px",
                fontWeight: 900
            },
        },
        headCells: {
            style: {
                borderColor: "#E9ECEF",
                textTransform: "uppercase",
                letterSpacing: "1px",
            },
        },
        headRow: {
            style: {
                backgroundColor: "#F7F7F8",
                minHeight: "44px",
                fontWeight: 500,
            },
        },
    };

    const handlePageChange = page => {
        getCreditFeesByStudentId(page, perPage, sortField, sortDirection);
        setCurrentPage(page);
    };

    const handlePerRowsChange = async (newPerPage, page) => {
        getCreditFeesByStudentId(page, newPerPage, sortField, sortDirection);
        setPerPage(newPerPage);
    };

    const handleSort = async (column, sortDirection) => {
        setSortField(column.sortField);
        setSortDirection(sortDirection);
        getCreditFeesByStudentId(currentPage, perPage, column.sortField, sortDirection);
    };

    const ExpandedComponent = ({ data }) => 
        <div className='border border-secondary rounded'>
            <div className="row m-2" style={{fontSize: '14px'}}>
                <div className="col-sm-3">
                    <div className="form-group">
                        <label htmlFor="exampleInputEmail1">Description </label>
                        <dd>{ data?.description ? data?.description : '---' }</dd>
                    </div>
                </div>
                <div className="col-sm-3">
                    <div className="form-group">
                        <label htmlFor="exampleInputEmail1">Invoiced Amount </label>
                        <dd>{ data?.invoiced_total_amount !== null ? <CurrencyFormat value={ parseFloat(data?.invoiced_total_amount).toFixed(2) } decimalScale={2} displayType={'text'} thousandSeparator={true} prefix={currency + ' '} /> : '---'}</dd>
                    </div>
                </div>
                <div className="col-sm-3">
                    <div className="form-group">
                        <label htmlFor="exampleInputEmail1">Invoiced On </label>
                        <dd>{ data?.invoiced_at ? moment(data?.invoiced_at).format('DD/MM/YYYY') : '---' }</dd>
                    </div>
                </div>
                <div className="col-sm-3">
                    <div className="form-group">
                        <label htmlFor="exampleInputEmail1">Invoice ID </label>
                        <dd>{ data?.student_invoice_id ? data?.student_invoice_id : '---' }</dd>
                    </div>
                </div>
            </div>
        </div>;

  return (
    <div>
        <section className="content">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-12">
                        <div className="card card-primary" key={1}>
                            <div className="card-header">
                                <h3 className="card-title">View Student Details</h3>
                            </div>
                            <div className="card-body">
                                <div className="row">
                                    <div className="col-sm-3">
                                        <div className="form-group">
                                            <label htmlFor="exampleInputEmail1">Student Name </label>
                                            <dd>{student.first_name+' '+student.last_name} </dd>
                                        </div>
                                    </div>
                                    <div className="col-sm-3">
                                        <div className="form-group">
                                            <label htmlFor="exampleInputEmail1">Date Of Birth </label>
                                            <dd>{student.date_of_birth ? moment(student.date_of_birth).format('YYYY-MM-DD') : '---'}</dd>
                                        </div>
                                    </div>
                                    <div className="col-sm-3">
                                        <div className="form-group">
                                            <label htmlFor="exampleInputEmail1">Gender </label>
                                            <dd>{student.gender ? student.gender.charAt(0).toUpperCase()+ student.gender.slice(1) : '---'}</dd>   
                                        </div>
                                    </div>
                                    <div className="col-sm-3">
                                        <div className="form-group">
                                            <label htmlFor="exampleInputEmail1">Roll No. </label>
                                            <dd>{student?.roll_number}</dd>
                                        </div>
                                    </div>
                                    <div className="col-sm-3">
                                        <div className="form-group">
                                            <label htmlFor="exampleInputEmail1">Grade </label>
                                            <dd>{student.grade_name ? student.grade_name : '---'}</dd>
                                        </div>
                                    </div>
                                    <div className="col-sm-3">
                                        <div className="form-group">
                                            <label htmlFor="exampleInputEmail1">School </label>
                                            <dd>{student.school_name ? student.school_name : '---'}</dd>
                                        </div>
                                    </div>
                                    <div className="col-sm-3">
                                        <div className="form-group">
                                            <label htmlFor="exampleInputEmail1">Franchise </label>
                                            <dd>{student?.franchise_name}</dd>
                                        </div>
                                    </div>
                                    <div className="col-sm-3">
                                        <div className="form-group">
                                            <label htmlFor="exampleInputEmail1">Centre </label>
                                            <dd>{student.centre_name ? student.centre_name : '---'}</dd>
                                        </div>
                                    </div>
                                    <div className="col-sm-3">
                                        <div className="form-group">
                                            <label htmlFor="exampleInputEmail1">Invoice Type </label>
                                            <dd>{student.invoice_generation_type ? student.invoice_generation_type.charAt(0).toUpperCase() + student.invoice_generation_type.slice(1).replace(/_/g, ' ') : '---'}</dd>
                                        </div>
                                    </div>
                                    <div className="col-sm-3">
                                        <div className="form-group">
                                            <label htmlFor="exampleInputEmail1">Address Line 1 </label>
                                            <dd>{student?.address}</dd>
                                        </div>
                                    </div>
                                    <div className="col-sm-3">
                                        <div className="form-group">
                                            <label htmlFor="exampleInputEmail1">Address Line 2 </label>
                                            <dd>{student?.address_line_2 ?? '---' }</dd>
                                        </div>
                                    </div>
                                    <div className="col-sm-3">
                                        <div className="form-group">
                                            <label htmlFor="exampleInputEmail1">City </label>
                                            <dd>{student?.city ?? '---' }</dd>
                                        </div>
                                    </div>
                                    <div className="col-sm-3">
                                        <div className="form-group">
                                            <label htmlFor="exampleInputEmail1">Postcode </label>
                                            <dd>{student?.postcode ?? '---' }</dd>
                                        </div>
                                    </div>
                                    <div className="col-sm-3">
                                        <div className="form-group">
                                            <label htmlFor="exampleInputEmail1">Country </label>
                                            <dd>{student.country ? student.country.toUpperCase() : '---'}</dd>
                                        </div>
                                    </div>
                                    <div className="col-sm-3">
                                        <div className="form-group">
                                            <label htmlFor="exampleInputEmail1">Status </label>
                                            <dd>{student.status ? student.status.charAt(0).toUpperCase()+ student.status.slice(1) : '---'}</dd>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="card-footer">
                                <Link className="btn btn-default" to='/students'>Close</Link>
                                <Link className="btn btn-default ml-2" to={`/student/edit/${student?.id}`}>Edit</Link>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-9" key={2}>
                        <div className="card card-primary">
                            <div className="card-header">
                                <h3 className="card-title">Student Subjects</h3>
                            </div>
                            <div className="card-body">
                                <div className="row">
                                    <div className="col-md-12">
                                        {subjects?.length > 0 && (
                                            <table className="table table-bordered" id="client_id_table">
                                                <tbody>
                                                    <tr className='text-center' key={'student-subject'}>
                                                        <th>Subject</th>
                                                        <th>Rate Per Month</th>
                                                        {/* <th>First Invoice Amount</th> */}
                                                        <th>VAT</th>
                                                        <th>Start Date</th>
                                                        <th>End Date</th>
                                                        <th>Status</th>
                                                    </tr>
                                                    {subjects?.length > 0  && subjects.map((subject) => (
                                                        <tr key={subject?.subject_id}>
                                                            <td>{ subject?.name }</td>
                                                            <td>
                                                                { subject?.rate ? <CurrencyFormat value={ subject?.rate } decimalScale={2} displayType={'text'} thousandSeparator={true} prefix={currency + ' '} /> : '---' }
                                                            </td>
                                                            {/* <td>
                                                                { subject?.first_invoice_rate ? <CurrencyFormat value={ subject?.first_invoice_rate } decimalScale={2} displayType={'text'} thousandSeparator={true} prefix={currency + ' '} /> : '---' }
                                                            </td> */}
                                                            <td>
                                                                { subject.vat_status ? (vatTypes.find(obj => { return obj.value === subject.vat_status}))?.name : '---'}
                                                            </td>
                                                            <td>{ (subject?.start_date != null) ? moment(subject?.start_date).format('DD-MM-YYYY') : '---'}</td>
                                                            <td>{ (subject?.end_date != null) ? moment(subject?.end_date).format('DD-MM-YYYY') : '---'}</td>
                                                            <td>
                                                                { subject.status === 'active' ? <span className="badge bg-success">Active</span> 
                                                                : (subject.status === 'inactive' ? <span className="capitalize badge bg-danger">{subject.status.charAt(0).toUpperCase() + subject.status.slice(1)}</span> : <span className="capitalize badge bg-warning">{subject.status.charAt(0).toUpperCase() + subject.status.slice(1)}</span>)  }
                                                            </td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </table>
                                        )}
                                        {subjects?.length == 0 && (
                                            <span style={{ color: "red", textAlign: "center" }}>There are no records to display</span>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-3" key={3}>
                        <div className="card card-primary">
                            <div className="card-header">
                                <h3 className="card-title">Parent Details</h3>
                            </div>
                            <div className="card-body">
                                <div className="row">
                                    { student?.parents?.length > 0  && student.parents.map((parent, i) => (
                                        <div className="col-md-12" key={parent.id + i}>
                                            <div className="card m-b-30">
                                                {/* <div className="row">
                                                    <div className="col-12 text-center">
                                                        <a href=""><img width={'30%'} height={'30%'} src="/img/default-profile.png" alt="" className="img-fluid rounded-circle w-60" /></a>
                                                        <p className="mt-2">{parent.relationship.charAt(0).toUpperCase()+parent.relationship.slice(1)}</p>
                                                    </div>
                                                </div> */}
                                                <ul className="list-group list-group-flush">
                                                    <Link to={`/parent/view/${parent.id}`} role="button" state={{ id: parent.id }} data-toggle="tooltip" title="View Parent Details" target={'_blank'}>
                                                        <li className="list-group-item"><i className="fa fa-user mr-1"></i>Name : {parent.first_name+' '+parent.last_name}</li>
                                                    </Link>
                                                    <li className="list-group-item"><i className="fa fa-phone mr-1"></i>Contact No. : {parent.contact}</li>
                                                    {/* <li className="list-group-item"><i className="fa fa-envelope mr-1"></i>Email : <a href="#">{parent.email}</a></li>
                                                    <li className="list-group-item"><i className="fa fa-phone mr-1"></i>Alt. Contact No. : {parent.alt_contact ? parent.alt_contact : 'Not Present'}</li>
                                                    <li className="list-group-item"><i className="fa fa-home mr-1"></i>Address : {parent.address}</li> */}
                                                    <li className="list-group-item"><i className="fa fa-credit-card mr-1"></i>Bill Payer : {(parent.bill_payer) ? <img width={'20%'} height={'20%'} src='/img/right.png'  /> : <img width={'12%'} height={'12%'} src='/img/wrong.png' className='ml-2'/>}
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    ))}
                                    {student?.parents?.length == 0 && (
                                        <span style={{ color: "red", textAlign: "center" }}>There are no records to display</span>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='col-md-12'>
                        <div className="card card-primary" key={4}>
                            <div className="card-header">
                                <h3 className="card-title">Student Fees/Credit</h3>
                            </div>
                            <div className="card-body">
                                <div className="row">
                                    <div className="col-12 col-sm-3">
                                        <div className='info-box bg-light'>
                                            <div className="info-box-content">
                                                <span className='info-box-text text-center text-muted'>Student Name </span>
                                                <span className='info-box-number text-center text-muted mb-0'>{student?.first_name+' '+student?.last_name} </span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12 col-sm-3">
                                        <div className='info-box bg-light'>
                                            <div className="info-box-content">
                                                <span className='info-box-text text-center text-muted'>Outstanding Fees </span>
                                                <span className='info-box-number text-center text-muted mb-0'>{ <CurrencyFormat value={outstandingFees} decimalScale={2} displayType={'text'} thousandSeparator={true} prefix={currency + ' '} /> } </span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12 col-sm-3">
                                        <div className='info-box bg-light'>
                                            <div className="info-box-content">
                                                <span className='info-box-text text-center text-muted'>Available Credits </span>
                                                <span className='info-box-number text-center text-muted mb-0'>{ <CurrencyFormat value={availableCredits} decimalScale={2} displayType={'text'} thousandSeparator={true} prefix={currency + ' '} /> } </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                        {subjects?.length > 0 && (
                                            <>
                                            <div className="col-md-12">
                                                <DataTable
                                                    columns={columns}
                                                    data={studentCreditFees}
                                                    pagination
                                                    paginationServer
                                                    paginationTotalRows={totalRows}
                                                    paginationDefaultPage={currentPage}
                                                    onChangePage={handlePageChange}
                                                    onChangeRowsPerPage={handlePerRowsChange}
                                                    paginationPerPage={perPage}
                                                    paginationRowsPerPageOptions={[5, 10, 20, 30, 50, 100]}
                                                    progressPending={isLoading}
                                                    sortServer
                                                    onSort={handleSort}
                                                    customStyles={customStyle}
                                                    highlightOnHover
                                                    expandableRows
                                                    expandableRowsComponent={ExpandedComponent}
                                                />
                                            </div> 
                                            </>
                                        )}
                                        {subjects?.length == 0 && (
                                            <span style={{ color: "red", textAlign: "center" }}>There are no records to display</span>
                                        )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <StudentAggregatedCreditsModal
            show={show}
            handleClose={handleClose}
            aggregatedData={aggregatedData}
            currency={currency}
        />
    </div>
  )
}

export default ViewStudent;