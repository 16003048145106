import React from 'react';
import {useSelector} from 'react-redux';
import {Link} from 'react-router-dom';
import {MenuItem} from '@components';

export interface IMenuItem {
  name: string;
  path?: string;
  icon?: string;
  children?: Array<IMenuItem>;
  permitted?: boolean;
  disabled: boolean;
}

const MenuSidebar = () => {
  const user = useSelector((state: any) => state.auth.currentUser);
  const role = useSelector((state: any) => state.auth.selectedRole.name);
  const sidebarSkin = useSelector((state: any) => state.ui.sidebarSkin);
  const menuItemFlat = useSelector((state: any) => state.ui.menuItemFlat);
  const menuChildIndent = useSelector((state: any) => state.ui.menuChildIndent);
  const permissions = useSelector((state: any) => state.auth.permissions);
  
  const MENU: IMenuItem[] = [
    {
      name: 'menusidebar.label.dashboard',
      path: '/',
      icon: 'fas fa-tachometer-alt',
      permitted: permissions?.dashboard?.view,
      disabled: false
    },
    {
      name: 'Manage Users',
      path: '/users',
      icon: 'fas fa-users',
      permitted: permissions?.users?.list,
      disabled: false
    },
    {
      name: 'Manage Roles',
      path: '/roles',
      icon: 'fas fa-user-tag',
      permitted: permissions?.roles?.list,
      disabled: false
    },
    {
      name: 'Manage Permissions',
      path: '/permissions',
      icon: 'fas fa-user-shield',
      permitted: false, //permissions?.permissions?.list
      disabled: false
    },
    {
      name: 'Manage Franchises',
      path: '/franchise',
      icon: 'fas fa-briefcase',
      permitted: permissions?.franchise?.list,
      disabled: false
    },
    {
      name: 'Manage Postcodes',
      path: '/postcodes',
      icon: 'fas fa-envelope',
      permitted: permissions?.postcodes?.list,
      disabled: false
    },
    {
      name: 'Manage Centres',
      path: '/centers',
      icon: 'fas fa-code-branch',
      permitted: permissions?.centre?.list,
      disabled: false
    },
    {
      name: 'Student Subject Count',
      permitted: permissions?.['student-count']?.['list-student-count'],
      icon: 'fas fa-book-reader',
      children: [
        // {
        //   name: 'Submit Student Count',
        //   path: '/submit-student-counts',
        //   permitted: permissions?.['student-count']?.['create-student-count'],
        //   disabled: false
        // },
        {
          name: 'Student Subject Counts',
          path: '/student-counts',
          permitted: permissions?.['student-count']?.['list-student-count'],
          disabled: false
        },
        {
          name: 'Transactions',
          path: '/student-count-transactions',
          permitted: permissions?.['student-count']?.['list-student-count'],
          disabled: false
        }
      ],
      disabled: false
    },
    {
      name: 'Material Orders',
      permitted: permissions?.orders?.list,
      icon: 'fas fa-shopping-cart',
      children: [
        {
          name: 'Place New Order',
          path: '/orders/place',
          permitted: permissions?.orders?.place,
          disabled: false
        },
        {
          name: 'In-Progress',
          path: '/in-progress-orders',
          permitted: permissions?.orders?.list,
          disabled: false
        },
        {
          name: 'Dispatched/Cancelled',
          path: '/dispatched-cancelled-orders',
          permitted: permissions?.orders?.list,
          disabled: false
        }
      ],
      disabled: false
    },
    {
      name: 'Manage Subjects',
      path: '/subjects',
      icon: 'fas fa-book-open',
      permitted: permissions?.subjects?.list,
      disabled: false
    },
    {
      name: 'Manage Students',
      permitted: permissions?.student?.list,
      icon: 'fas fa-user-graduate',
      children: [
        {
          name: 'Student List',
          path: '/students',
          permitted: permissions?.student?.list,
          disabled: false
        },
        {
          name: 'Student Invoices',
          path: '/student-invoices',
          permitted: permissions?.student?.invoices,
          disabled: false
        },
        {
          name: 'Parent List',
          path: '/parents',
          permitted: permissions?.student?.list,
          disabled: false
        }
      ],
      disabled: false
    },
    {
      name: 'Print Summary',
      path: '/print-summary',
      icon: 'fas fa-chart-line',
      permitted: permissions?.['print-summary']?.list,
      disabled: false
    },
    {
      name: 'Learning Pathways',
      path: '/learning-pathway',
      icon: 'fas fa-bezier-curve',
      permitted: permissions?.['learning-pathway']?.list,
      disabled: false
    },
    {
      name: 'Setting Patterns',
      path: '/setting-patterns',
      icon: 'fas fa-layer-group',
      permitted: permissions?.['setting-pattern']?.list,
      disabled: false
    },
    {
      name: 'Manage Modules',
      path: '/modules',
      icon: 'fas fa-list',
      permitted: permissions?.modules?.list,
      disabled: false
    },
    {
      name: 'Manage Sets',
      path: '/sets',
      icon: 'fas fa-list',
      permitted: permissions?.sets?.list,
      disabled: false
    },
    {
      name: 'Manage Blocks',
      path: '/blocks',
      icon: 'fas fa-list',
      permitted: permissions?.blocks?.list,
      disabled: false
    },
    {
      name: 'Manage Handbooks',
      path: '/handbooks',
      icon: 'fas fa-book',
      permitted: permissions?.handbooks?.list,
      disabled: false
    },
    // {
    //   name: 'Manage Handbook',
    //   permitted: permissions?.handbooks?.list,
    //   icon: 'fas fa-book',
    //   children: [
    //     {
    //       name: 'Handbooks',
    //       path: '/handbooks',
    //       permitted: permissions?.handbooks?.list
    //     },
    //     {
    //       name: 'Handbook Supplements',
    //       path: '/handbook-supplements',
    //       permitted: permissions['handbook-supplement']?.list
    //     }
    //   ],
    // },
    {
      name: 'Other Items',
      path: '/other-items',
      icon: 'fas fa-list',
      permitted: permissions['other-items']?.list,
      disabled: false
    },
    {
      name: 'Pricing Plans',
      path: '/pricing-plans',
      icon: 'fas fa-pound-sign',
      permitted: permissions['pricing-plans']?.list,
      disabled: false
    },
    {
      name: 'Camps',
      path: '/camps',
      icon: 'fas fa-campground',
      permitted: permissions['camps']?.list,
      disabled: false
    },
    {
      name: 'Masters',
      permitted: permissions?.masters?.list,
      icon: 'fas fa-circle',
      disabled: false,
      children: [
        {
          name: 'Regions',
          path: '/regions',
          permitted: permissions?.masters?.region,
          disabled: false
        },
  
        {
          name: 'Areas',
          path: '/areas',
          permitted: permissions?.masters?.area,
          disabled: false
        }
      ],
    },
    {
      name: 'Manage Settings',
      path: '/settings',
      icon: 'fas fa-cog',
      permitted: permissions?.setting?.list,
      disabled: false
    },
    {
      name: 'Ops Manual',
      path:  '/ops-manual',
      icon: 'fas fa-folder',
      permitted: permissions?.opsmanual?.list,
      disabled: false
    },
    {
      name: 'Marketing Shop',
      path: 'https://proco.infigosoftware.com/firstclasslearning/login',
      icon: 'fas fa-store',
      permitted: true,
      disabled: false
    },
    {
      name: 'Sales Training Portal',
      path: 'https://first-class-learning.mn.co/sign_in',
      icon: 'fas fa-video',
      permitted: true,
      disabled: false
    },
    {
      name: 'Student Work',
      path: null,
      icon: 'fas fa-school',
      permitted: true,
      disabled: true
    },
    {
      name: 'Reports',
      path: null,
      icon: 'fas fa-chart-line',
      permitted: true,
      disabled: true
    },
  ];

  return (
    <aside className={`main-sidebar elevation-4 ${sidebarSkin}`}>
      <Link to="/" className="brand-link">
        <img
          src={process.env.REACT_APP_ENV !== 'production' ? "/img/FCL-Landscape.png" : "/img/FCL-Landscape-White.png"}
          alt="FCL Logo"
          className="brand-image"
          style={{opacity: '.8'}}
        />
        <span className="brand-text font-weight-light"> {process.env.REACT_APP_ENV === 'production' ? "FCL" : "UAT"}</span>
      </Link>
      <div className="sidebar">
        <div className="user-panel mt-3 pb-3 mb-3 d-flex">
          <div className="image">
            <img
              src={user.picture || '/img/default-profile.png'}
              className="img-circle elevation-2"
              alt="User"
            />
          </div>
          <div className="info">
            <Link to="/profile" className="d-block">
              {user.first_name +' '+ user.last_name}
            </Link>
            <div className={process.env.REACT_APP_ENV === 'production' ? "text-white-50" : "text-black-50"}> { role } </div>
          </div>
        </div>
        <nav className="mt-2" style={{overflowY: 'hidden'}}>
          <ul
            className={`nav nav-pills nav-sidebar flex-column${
              menuItemFlat ? ' nav-flat' : ''
            }${menuChildIndent ? ' nav-child-indent' : ''}`}
            role="menu"
          >
            {MENU.map((menuItem: IMenuItem) => (
              menuItem?.permitted ? <MenuItem key={menuItem.name} menuItem={menuItem} /> : ''
            ))}
          </ul>
        </nav>
      </div>
    </aside>
  );
};

export default MenuSidebar;
