import { useFormik } from 'formik';
import React, { useEffect, useState } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'
import * as Yup from 'yup';
import {Form} from 'react-bootstrap';
import {toast} from 'react-toastify';
import { Button } from '@app/components';
import { createFMSContract } from '@app/services/FMScontracts';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { getPostcodeCountryByFranchiseId } from '@app/services/franchise';

const AddFMSContract = () => {

    const contractTerms = [{value:'2', label:'2 Years'}, {value:'5', label:'5 Years'}]
    const financingTypes = [{value: 'yes' , name:'Yes', isDisabled: false, isChecked: false}, {value: 'no', name:'No', isDisabled: false, isChecked: false}, {value: 'yes-but-failed', name:'Yes but failed', isDisabled: false, isChecked: false}]
    const subjectsTo = [{value:'0', label:'0'}, {value:'30', label:'30'}, {value:'50', label:'50'}, {value:'80', label:'80'}, {value:'100', label:'100'}]
    const [fileList, setFileList] = useState([]);
    const [fileError, setFileError] = useState('');
    const [isLoading, setLoading] = useState(false);
    const [currency, setCurrency] = useState('');
    const navigate = useNavigate();
    const canAccess = useSelector((state: any) => state.auth.permissions.franchise);
    let { id } = useParams();

    useEffect(() => {
        if(!canAccess['add-contract']){
            navigate('/franchise/view-contract/' + id);
        }
        getCountryByFranchiseId(id)
    }, []);

    const getCountryByFranchiseId = async (franchiseId) => {
        try {
            const resp = await getPostcodeCountryByFranchiseId(franchiseId);
            if (resp?.success === true) {
                setCurrency(resp?.collections?.currency)
            } else {
                toast.error(resp?.errors[0]?.msg);
            }
        } catch (error: any) {
            toast.error(error?.message || 'Internal server error');
        }
    };

    const addFMSContract = async (payload) => {
        try {
            setLoading(true);
            const resp = await createFMSContract(payload);
            if(resp.success === true){
                setLoading(false);
                toast.success(resp?.message);
                navigate('/franchise/view-contract/' + id);
            }else{
                setLoading(false);
                toast.error(resp?.errors[0]?.msg);
            }
          } catch (error: any) {
            setLoading(false);
            toast.error(error?.message || 'Something went wrong');
          }
    };

    const { handleChange, values, handleSubmit, touched, errors, setFieldValue } = useFormik({
        initialValues: {
            franchise_id: id,
            contract_term: '',
            contract_initial_fee: '',
            deposit_amount: '',
            deposit_paid_date: '',
            plan_financed_amount: '',
            financing: '',
            special_payment_amount: '',
            monthly_franchise_fees: '',
            monthly_student_fee_before: '',
            monthly_student_fee_after: '',
            subject_to: '',
            monthly_student_fee_tier_two: '',
            renewal_fee: '',
            renewal_date: '',
            contract_commencement_date: '',
            kashflow_billing_start_date: '',
        },
        validationSchema: Yup.object({
            contract_term: Yup.number().required('Please select contract term'),
            contract_initial_fee: Yup.number().required('Please enter initial fee').min(0, "Fee must be greater than or equal to 0").max(10000, 'Fee must be less than or equal to 10,000'),
            deposit_amount: Yup.number().required('Please enter deposit amount').min(0, "Amount must be greater than or equal to 0").max(10000, 'Amount must be less than or equal to 10,000'),
            deposit_paid_date: Yup.string().required('Please enter deposit paid date')
            .matches(/^(\d{4})(\/|-)(\d{1,2})(\/|-)(\d{1,2})$/, 'Please enter a valid date'),
            plan_financed_amount: Yup.number().required('Please enter finance amount').min(0, "Amount must be greater than or equal to 0").max(10000, 'Amount must be less than or equal to 10,000'),
            financing: Yup.string().required('Please select financing option'),
            special_payment_amount: Yup.number().required('Please enter special payment amount').min(0, "Amount must be greater than or equal to 0").max(10000, 'Amount must be less than or equal to 10,000'),
            monthly_franchise_fees: Yup.number().required('Please enter monthly franchise fee').min(0, "Fee must be greater than or equal to 0").max(10000, 'Fee must be less than or equal to 10,000'),
            monthly_student_fee_before: Yup.number().required('Please enter monthly student fee before').min(0,"Fee must be greater than or equal to 0").max(10000, 'Fee must be less than or equal to 10,000'),
            monthly_student_fee_after: Yup.number().required('Please enter monthly student fee after').min(0,"Fee must be greater than or equal to 0").max(10000, 'Fee must be less than or equal to 10,000'),
            monthly_student_fee_tier_two: Yup.number().required('Please enter fee').min(0, "Fee must be greater than or equal to 0").max(10000, 'Fee must be less than or equal to 10,000'),
            subject_to: Yup.string().required('Please select subject to'),
            renewal_fee: Yup.number().required('Please enter renewal fee').min(0,"Fee must be greater than or equal to 0").max(10000, 'Fee must be less than or equal to 10,000'),
            renewal_date: Yup.string().required('Please enter renewal date'),
            contract_commencement_date: Yup.string().required('Please enter contract commencement date')
            .matches(/^(\d{4})(\/|-)(\d{1,2})(\/|-)(\d{1,2})$/, 'Please enter a valid date'),
            kashflow_billing_start_date: Yup.string().required('Please enter kashflow billing start date')
            .matches(/^(\d{4})(\/|-)(\d{1,2})(\/|-)(\d{1,2})$/, 'Please enter a valid date'),
        }),
        onSubmit: (values) => {
            const data = new FormData();
            if (fileList.length) {
                Array.from(fileList).forEach((file) => {
                    data.append("contract_documents", file);
                });
            }
          
            Object.keys(values).forEach(fieldName => {
                data.append(fieldName, values[fieldName]);
            })
            addFMSContract(data);
        }
    });

    const handleContractDocumentChange = (e) => {
        if(e.target.files.length > 10){
            setFileError('You can only upload a maximum of 10 documents')
            setFileList([])
        }else{
            setFileError('')
            setFileList(e.target.files);
        }
    };

    return (
    <div>
        <section className="content">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-12">
                        <div className="card card-primary">
                            <div className="card-header">
                                <h3 className="card-title">Add Contract</h3>
                            </div>
                            <form onSubmit={handleSubmit}>
                                <div className="card-body">
                                    <div className="row">
                                        <dt className="col-sm-3">Franchise Details</dt>
                                        <div className="col-sm-3">
                                            <div className="form-group">
                                                <label htmlFor='kashflow_billing_start_date'>Billing Start Date As Per Kashflow <span className="required">*</span></label>
                                                <Form.Control 
                                                    type="date" 
                                                    id='kashflow_billing_start_date' 
                                                    name='kashflow_billing_start_date'
                                                    placeholder="Enter kashflow billing start date" 
                                                    value={moment(values.kashflow_billing_start_date).format('YYYY-MM-DD')}
                                                    // min={moment((batches.find(obj => { return obj.id == formik.values.batches[index].id }))?.start_date).format('YYYY-MM-DD')}
                                                    // max={moment((batches.find(obj => { return obj.id == formik.values.batches[index].id }))?.end_date).format('YYYY-MM-DD')}
                                                    onChange={handleChange}
                                                />
                                                {(touched.kashflow_billing_start_date && errors.kashflow_billing_start_date) && (
                                                    <div className="text-danger">
                                                        {errors.kashflow_billing_start_date}
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                    <hr/>
                                    <div className="row">
                                        <dt className="col-sm-3">Contract</dt>
                                        <div className="col-sm-3">
                                            <div className="form-group">
                                                <label htmlFor="exampleInputEmail1">Contract Term 11.1 (Years) <span className="required">*</span></label>
                                                <select
                                                    className="form-control"
                                                    name="contract_term"
                                                    onChange={handleChange} 
                                                    value={values.contract_term}
                                                >
                                                    <option value="">Please select</option>
                                                    {contractTerms.map((obj) => (
                                                        <option key={obj.value} value={obj.value}>
                                                            {obj.label}
                                                        </option>
                                                    ))}
                                                </select>
                                                {(touched.contract_term && errors.contract_term) && (
                                                    <div className="text-danger">
                                                        {errors.contract_term}
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                        <div className="col-sm-3">
                                            <div className="form-group">
                                                <label htmlFor="exampleInputEmail1">Contracted Initial Fee<span className="required">*</span></label>
                                                <div className='input-group'>
                                                    <div className="input-group-prepend">
                                                        <span className="input-group-text">{ currency }</span>
                                                    </div>
                                                    <Form.Control 
                                                        type="number" 
                                                        className="form-control" 
                                                        id="exampleInputEmail1" 
                                                        placeholder="Enter initial fee"
                                                        name="contract_initial_fee" 
                                                        onChange={handleChange} 
                                                        value={values.contract_initial_fee}
                                                        onWheel={ (e) => e.currentTarget.blur() }
                                                        />
                                                </div>
                                                {(touched.contract_initial_fee && errors.contract_initial_fee) && (
                                                    <div className="text-danger">
                                                        {errors.contract_initial_fee}
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                        <div className="col-sm-3">
                                            <div className="form-group">
                                                <label htmlFor='contract_commencement_date'>Commencement Date As Per Contract<span className="required">*</span></label>
                                                <Form.Control 
                                                    type="date" 
                                                    id='contract_commencement_date' 
                                                    name='contract_commencement_date'
                                                    placeholder="Enter contract commencement date" 
                                                    value={moment(values.contract_commencement_date).format('YYYY-MM-DD')}
                                                    // min={moment((batches.find(obj => { return obj.id == formik.values.batches[index].id }))?.start_date).format('YYYY-MM-DD')}
                                                    // max={moment((batches.find(obj => { return obj.id == formik.values.batches[index].id }))?.end_date).format('YYYY-MM-DD')}
                                                    onChange={handleChange}
                                                />
                                                {(touched.contract_commencement_date && errors.contract_commencement_date) && (
                                                    <div className="text-danger">
                                                        {errors.contract_commencement_date}
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                    <hr/>
                                    <div className="row">
                                        <dt className="col-sm-3">Renewal</dt>
                                        <div className="col-sm-3">
                                            <div className="form-group">
                                                <label htmlFor="renewal_fee">Renewal Fee (Under 'Term' in Contract)<span className="required">*</span></label>
                                                <div className='input-group'>
                                                    <div className="input-group-prepend">
                                                        <span className="input-group-text">{ currency }</span>
                                                    </div>
                                                    <Form.Control 
                                                        type="number" 
                                                        className="form-control" 
                                                        id="renewal_fee" 
                                                        placeholder="Enter renewal fee"
                                                        name="renewal_fee" 
                                                        onChange={handleChange} 
                                                        value={values.renewal_fee}
                                                        onWheel={ (e) => e.currentTarget.blur() }
                                                    />
                                                </div>
                                                {(touched.renewal_fee && errors.renewal_fee) && (
                                                    <div className="text-danger">
                                                        {errors.renewal_fee}
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                        <div className="col-sm-3">
                                            <div className="form-group">
                                                <label htmlFor='renewal_date'>Renewal Date <span className="required">*</span></label>
                                                <Form.Control 
                                                    type="date" 
                                                    id='renewal_date' 
                                                    name='renewal_date'
                                                    placeholder="Enter kashflow billing start date" 
                                                    value={moment(values.renewal_date).format('YYYY-MM-DD')}
                                                    // min={moment((batches.find(obj => { return obj.id == formik.values.batches[index].id }))?.start_date).format('YYYY-MM-DD')}
                                                    // max={moment((batches.find(obj => { return obj.id == formik.values.batches[index].id }))?.end_date).format('YYYY-MM-DD')}
                                                    onChange={handleChange}
                                                />
                                                {(touched.renewal_date && errors.renewal_date) && (
                                                    <div className="text-danger">
                                                        {errors.renewal_date}
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                    <hr/>
                                    <div className="row">
                                        <dt className="col-sm-3">Deposit</dt>
                                        <div className="col-sm-3">
                                            <div className="form-group">
                                                <label htmlFor="exampleInputEmail1">Deposit As Per Proposed Letter <span className="required">*</span></label>
                                                <div className='input-group'>
                                                    <div className="input-group-prepend">
                                                        <span className="input-group-text">{ currency }</span>
                                                    </div>
                                                    <Form.Control 
                                                        type="number" 
                                                        className="form-control" 
                                                        id="exampleInputEmail1" 
                                                        placeholder="Enter deposit amount"
                                                        name="deposit_amount" 
                                                        onChange={handleChange} 
                                                        value={values.deposit_amount}
                                                        onWheel={ (e) => e.currentTarget.blur() }
                                                    />
                                                </div>
                                                {(touched.deposit_amount && errors.deposit_amount) && (
                                                    <div className="text-danger">
                                                        {errors.deposit_amount}
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                        <div className="col-sm-3">
                                            <div className="form-group">
                                                <label htmlFor='deposit_paid_date'>Deposit Paid Date <span className="required">*</span></label>
                                                <Form.Control 
                                                    type="date" 
                                                    id='deposit_paid_date' 
                                                    name='deposit_paid_date'
                                                    placeholder="Enter deposit paid date" 
                                                    value={moment(values.deposit_paid_date).format('YYYY-MM-DD')}
                                                    // min={moment((batches.find(obj => { return obj.id == formik.values.batches[index].id }))?.start_date).format('YYYY-MM-DD')}
                                                    // max={moment((batches.find(obj => { return obj.id == formik.values.batches[index].id }))?.end_date).format('YYYY-MM-DD')}
                                                    onChange={handleChange}
                                                />
                                                {(touched.deposit_paid_date && errors.deposit_paid_date) && (
                                                    <div className="text-danger">
                                                        {errors.deposit_paid_date}
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                    <hr/>
                                    <div className="row">
                                        <dt className="col-sm-3">Initial Fee Balance Due Arrangements</dt>
                                        <div className="col-sm-3">
                                            <div className="form-group">
                                                <label htmlFor="plan_financed_amount">Plan/Financed Amount <i className='fa fa-info-circle' title='By straight cash, as per proposed letter'/> <span className="required">*</span></label>
                                                <div className='input-group'>
                                                    <div className="input-group-prepend">
                                                        <span className="input-group-text">{ currency }</span>
                                                    </div>
                                                    <Form.Control 
                                                        type="number" 
                                                        className="form-control" 
                                                        id="plan_financed_amount" 
                                                        placeholder="Enter plan financed amount"
                                                        name="plan_financed_amount" 
                                                        onChange={handleChange} 
                                                        value={values.plan_financed_amount}
                                                        onWheel={ (e) => e.currentTarget.blur() }
                                                    />
                                                </div>
                                                {(touched.plan_financed_amount && errors.plan_financed_amount) && (
                                                    <div className="text-danger">
                                                        {errors.plan_financed_amount}
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                        <div className="col-sm-3">
                                            <div className="form-group">
                                                <label htmlFor="financing">Financing <span className="required">*</span></label>
                                                {(financingTypes).map((obj) => (
                                                    <div className="form-check col-sm-12" key={obj?.value}>
                                                        <input 
                                                            className="form-check-input" 
                                                            id="financing"
                                                            type="radio" 
                                                            name="financing" 
                                                            onChange={ (e) => {
                                                                setFieldValue('financing', e.target.value)
                                                            }}
                                                            value={obj?.value}
                                                            checked={obj?.value == values.financing}
                                                            disabled={obj?.isDisabled}
                                                        />
                                                        <label className="form-check-label">{ obj?.name }</label><br/>
                                                    </div>
                                                )) }
                                                {(touched.financing && errors.financing) && (
                                                    <div className="text-danger">
                                                        {errors.financing}
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                        <div className="col-sm-3">
                                            <div className="form-group">
                                                <label htmlFor="special_payment_amount">Special Payment Terms <i className='fa fa-info-circle' title='To claim initial fee portion via alt growth mechanism'/> <span className="required">*</span></label>
                                                <div className='input-group'>
                                                    <div className="input-group-prepend">
                                                        <span className="input-group-text">{ currency }</span>
                                                    </div>
                                                    <Form.Control 
                                                        type="number" 
                                                        className="form-control" 
                                                        id="special_payment_amount" 
                                                        placeholder="Enter special payment amount"
                                                        name="special_payment_amount" 
                                                        onChange={handleChange} 
                                                        value={values.special_payment_amount}
                                                        onWheel={ (e) => e.currentTarget.blur() }
                                                    />
                                                </div>
                                                {(touched.special_payment_amount && errors.special_payment_amount) && (
                                                    <div className="text-danger">
                                                        {errors.special_payment_amount}
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                    <hr/>
                                    <div className="row">
                                        <dt className="col-sm-3">Standard Fees</dt>
                                        <div className="col-sm-3">
                                            <div className="form-group">
                                                <label htmlFor="monthly_franchise_fees">Monthly Franchise Fee <span className="required">*</span></label>
                                                <div className='input-group'>
                                                    <div className="input-group-prepend">
                                                        <span className="input-group-text">{ currency }</span>
                                                    </div>
                                                    <Form.Control 
                                                        type="number" 
                                                        className="form-control" 
                                                        id="monthly_franchise_fees" 
                                                        placeholder="Enter monthly franchise fee"
                                                        name="monthly_franchise_fees" 
                                                        onChange={handleChange} 
                                                        value={values.monthly_franchise_fees}
                                                        onWheel={ (e) => e.currentTarget.blur() }
                                                    />
                                                </div>
                                                {(touched.monthly_franchise_fees && errors.monthly_franchise_fees) && (
                                                    <div className="text-danger">
                                                        {errors.monthly_franchise_fees}
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                        <div className="col-sm-3">
                                            <div className="form-group">
                                                <label htmlFor="monthly_student_fee_before">Monthly Student Fee Before <span className="required">*</span></label>
                                                <div className='input-group'>
                                                    <div className="input-group-prepend">
                                                        <span className="input-group-text">{ currency }</span>
                                                    </div>
                                                    <Form.Control 
                                                        type="number" 
                                                        className="form-control" 
                                                        id="monthly_student_fee_before" 
                                                        placeholder="Enter monthly student before"
                                                        name="monthly_student_fee_before" 
                                                        onChange={handleChange} 
                                                        value={values.monthly_student_fee_before}
                                                        onWheel={ (e) => e.currentTarget.blur() }
                                                    />
                                                </div>
                                                {(touched.monthly_student_fee_before && errors.monthly_student_fee_before) && (
                                                    <div className="text-danger">
                                                        {errors.monthly_student_fee_before}
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                        <div className="col-sm-3">
                                            <div className="form-group">
                                                <label htmlFor="monthly_student_fee_after">Monthly Student Fee After <span className="required">*</span></label>
                                                <div className='input-group'>
                                                    <div className="input-group-prepend">
                                                        <span className="input-group-text">{ currency }</span>
                                                    </div>
                                                    <Form.Control 
                                                        type="number" 
                                                        className="form-control" 
                                                        id="monthly_student_fee_after" 
                                                        placeholder="Enter monthly student fee after"
                                                        name="monthly_student_fee_after" 
                                                        onChange={handleChange} 
                                                        value={values.monthly_student_fee_after}
                                                        onWheel={ (e) => e.currentTarget.blur() }
                                                    />
                                                </div>
                                                {(touched.monthly_student_fee_after && errors.monthly_student_fee_after) && (
                                                    <div className="text-danger">
                                                        {errors.monthly_student_fee_after}
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                    <hr/>
                                    <div className="row">
                                        <dt className="col-sm-3">Non-Standard Fees</dt>
                                        <div className="col-sm-3">
                                            <div className="form-group">
                                                <label htmlFor="monthly_student_fee_tier_two">Monthly Student Fee Tier 2 <span className="required">*</span></label>
                                                <div className='input-group'>
                                                    <div className="input-group-prepend">
                                                        <span className="input-group-text">{ currency }</span>
                                                    </div>
                                                    <Form.Control 
                                                        type="number" 
                                                        className="form-control" 
                                                        id="monthly_student_fee_tier_two" 
                                                        placeholder="Enter monthly student fee tier two"
                                                        name="monthly_student_fee_tier_two" 
                                                        onChange={handleChange} 
                                                        value={values.monthly_student_fee_tier_two}
                                                        onWheel={ (e) => e.currentTarget.blur() }
                                                    />
                                                </div>
                                                {(touched.monthly_student_fee_tier_two && errors.monthly_student_fee_tier_two) && (
                                                    <div className="text-danger">
                                                        {errors.monthly_student_fee_tier_two}
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                        <div className="col-sm-3">
                                            <div className="form-group">
                                                <label htmlFor="subject_to">Subjects To <span className="required">*</span></label>
                                                <select
                                                    className="form-control"
                                                    name="subject_to"
                                                    onChange={handleChange} 
                                                    value={values.subject_to}
                                                >
                                                    <option value="">Please select </option>
                                                    {subjectsTo.map((obj) => (
                                                        <option key={obj.value} value={obj.value}>
                                                            {obj.label}
                                                        </option>
                                                    ))}
                                                </select>
                                                {(touched.subject_to && errors.subject_to) && (
                                                    <div className="text-danger">
                                                        {errors.subject_to}
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                    <hr/>
                                    <div className="row">
                                        <dt className="col-sm-3">Contract Repository</dt>
                                        <div className="col-sm-3">
                                            <div className="form-group">
                                                <label htmlFor="contract_documents">Upload Contract Document(s)</label>
                                                <div className="input-group">
                                                    <div className="custom-file">
                                                        <input type="file" className="custom-file-input" id="contract_documents" onChange={handleContractDocumentChange} multiple/>
                                                        <label className="custom-file-label" htmlFor="contract_documents">
                                                            {fileList.length > 0 ? `${fileList.length} file(s) selected` : 'Choose file'}
                                                        </label>
                                                    </div>
                                                    {/* <div className="input-group-append">
                                                        <span className="input-group-text">Upload</span>
                                                    </div> */}
                                                </div>
                                                <div className="text-danger">
                                                    {fileError}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="card-footer">
                                    <Button type="submit" isLoading={isLoading} className="btn btn-primary mr-2">Add </Button>
                                    <Link className="btn btn-default" to={'/franchise/view-contract/' + id}>Cancel</Link>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
  )
}

export default AddFMSContract
