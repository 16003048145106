import { Modal } from 'react-bootstrap';
import moment from 'moment';
import CurrencyFormat from 'react-currency-format';

const StudentAggregatedCreditsModal = (props) => {

    const vatTypes = [{value: 'inclusive' , name:'Inclusive'}, {value: 'exclusive', name:'Exclusive'}, {value: 'not_applicable', name:'N/A'}];

    return (
    <div>
        <Modal show={props.show} onHide={props.handleClose} size="lg">
            <Modal.Header closeButton>
                <Modal.Title> Aggregated Credits</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className='row'>
                    <div className="col-md-12">
                        {(props.aggregatedData)?.length > 0 && (
                            <table className="table table-bordered" id="client_id_table" key='student-subject-history'>
                                <tbody>
                                    <tr className='text-center'>
                                        <th>Id</th>
                                        <th>Description</th>
                                        <th>Type</th>
                                        <th>Amount</th>
                                        <th>VAT</th>
                                        <th>Status</th>
                                        <th>Created Date</th>
                                    </tr>
                                    {(props.aggregatedData)?.length > 0  && (props.aggregatedData).map((subject, i) => (
                                        <tr key={subject.id + '-' + i} className='text-center'>
                                            <td>#{ subject?.id }</td>
                                            <td>{ subject?.description }</td>
                                            <td>{ subject?.type.toUpperCase() }</td>
                                            <td><CurrencyFormat value={ subject?.amount } decimalScale={2} displayType={'text'} thousandSeparator={true} prefix={props?.currency + ' '} /> </td>
                                            <td>
                                                { subject?.vat_status ? (vatTypes.find(obj => { return obj.value === subject.vat_status}))?.name : '---'}
                                            </td>
                                            <td><span className="capitalize badge badge-secondary">{ subject.status.charAt(0).toUpperCase() + subject.status.slice(1) }</span></td>
                                            <td>{ (subject.created_at != null) ? moment(subject.created_at).format('DD/MM/YYYY HH:mm') : 'N/A'}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        )}
                        {(props.aggregatedData)?.length == 0 && (
                            <span style={{ color: "red", textAlign: "center" }}>There are no records to display</span>
                        )}
                    </div>
                </div>
            </Modal.Body>
            {/* <Modal.Footer>
                <Button className='btn btn-default' onClick={props.handleClose}>Close</Button>
            </Modal.Footer> */}
        </Modal>
    </div>
  )
}

export default StudentAggregatedCreditsModal;