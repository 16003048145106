import { useFormik } from 'formik';
import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import * as Yup from 'yup';
import {toast} from 'react-toastify';
import { Button } from '@app/components';
import { createNote, getAllActiveHeadings, getAllSubHeadingsById } from '@app/services/note';

const AddNote = () => {

    const [headings, setHeadings] = useState([]);
    const [subHeadings, setSubHeadings] = useState([]);
    const [fileList, setFileList] = useState([]);
    const [fileError, setFileError] = useState('');
    const [isLoading, setLoading] = useState(false);
    const navigate = useNavigate();
    let { franchise_id } = useParams();

    useEffect(() => {
        geteHeadings();
    },[]);

    const geteHeadings = async () => {
        try {
            const resp = await getAllActiveHeadings()
            if (resp?.success === true) {
                setHeadings(resp?.collections);
            } else {
                toast.error(resp?.errors[0]?.msg);
            }
        } catch (error: any) {
            toast.error(error?.message || 'Something went wrong');
        }
    };

    const getSubHeadingsByHeadingId = async (headingId) => {
        try {
            const resp = await getAllSubHeadingsById(headingId)
            if (resp?.success === true) {
                setSubHeadings(resp?.collections);
            } else {
                toast.error(resp?.errors[0]?.msg);
            }
        } catch (error: any) {
            toast.error(error?.message || 'Something went wrong');
        }
    };

    const addNote = async (payload) => {
        try {
            setLoading(true);
            const resp = await createNote(payload);
            if (resp.success === true) {
                toast.success(resp?.message);
                setLoading(false);
                navigate(-1);
            } else {
                toast.error(resp?.errors[0]?.msg);
                setLoading(false);
            }
        } catch (error: any) {
            setLoading(false);
            toast.error(error?.message || 'Something went wrong');
        }
    };

    const { handleChange, values, handleSubmit, touched, errors, setFieldValue} = useFormik({
        initialValues: {
            franchise_id: franchise_id,
            heading_id: '',
            sub_heading_id: '',
            note: '',
        },
        validationSchema: Yup.object({
            heading_id: Yup.string().required('Please select heading'),
            note: Yup.string().trim().required('Please enter note'),
        }),
        onSubmit: (values) => {
            const data = new FormData();
            if (fileList.length) {
                Array.from(fileList).forEach((file) => {
                    data.append("note_documents", file);
                });
            }
            Object.keys(values).forEach(fieldName => {
                data.append(fieldName, values[fieldName]);
            })
            addNote(data);
        }
    });

    const handleNoteDocument = (e) => {
        if(e.target.files.length > 10){
            setFileError('You can only upload a maximum of 10 documents')
            setFileList([])
        }else{
            setFileError('')
            setFileList(e.target.files);
        }
    };

    return (
    <div>
        <section className="content">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-12">
                        <div className="card card-primary">
                            <div className="card-header">
                                <h3 className="card-title">Add Note</h3>
                            </div>
                            <form onSubmit={handleSubmit}>
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col-sm-6">
                                            <div className="form-group">
                                                <label htmlFor="heading_id">Heading <span className="required">*</span></label>
                                                <select
                                                    className="form-control"
                                                    name="heading_id"
                                                    onChange={(e) => {
                                                        setFieldValue('heading_id', e.target.value)
                                                        if(e.target.value){
                                                            getSubHeadingsByHeadingId(e.target.value);
                                                        }
                                                    }}
                                                    value={values.heading_id}
                                                >
                                                   <option value="">Select Heading</option>
                                                    {headings.map((obj) => (
                                                        <option key={obj?.id} value={obj?.id}>
                                                            {obj?.heading}
                                                        </option>
                                                    ))}
                                                </select>
                                                {(touched.heading_id && errors.heading_id) && (
                                                    <div className="text-danger">
                                                        {errors.heading_id}
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                        <div className="col-sm-6">
                                            <div className="form-group">
                                                <label htmlFor="sub_heading_id">Subheading </label>
                                                <select
                                                    className="form-control"
                                                    id="sub_heading_id"
                                                    name="sub_heading_id"
                                                    onChange={handleChange} 
                                                    value={values.sub_heading_id}
                                                >
                                                   <option value="">Select Subheading</option>
                                                    {subHeadings.map((obj) => (
                                                        <option key={obj?.id} value={obj?.id}>
                                                            {obj?.sub_heading}
                                                        </option>
                                                    ))}
                                                </select>
                                                {(touched.sub_heading_id && errors.sub_heading_id) && (
                                                    <div className="text-danger">
                                                        {errors.sub_heading_id}
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                        <div className="col-sm-6">
                                            <div className="form-group">
                                                <label htmlFor="note">Note <span className="required">*</span></label>
                                                <textarea 
                                                    className="form-control" 
                                                    id="note" 
                                                    placeholder="Enter note" 
                                                    name="note" 
                                                    onChange={handleChange} 
                                                    value={values.note}
                                                ></textarea>
                                                {(touched.note && errors.note) && (
                                                    <div className="text-danger">
                                                        {errors.note}
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                        <div className="col-sm-6">
                                            <div className="form-group">
                                                <label htmlFor="note_documents">Upload Note Document(s)</label>
                                                <div className="input-group">
                                                    <div className="custom-file">
                                                        <input type="file" className="custom-file-input" id="note_documents" onChange={ handleNoteDocument } multiple/>
                                                        <label className="custom-file-label" htmlFor="note_documents">
                                                            {fileList.length > 0 ? `${fileList.length} file(s) selected` : 'Choose file'}
                                                        </label>
                                                    </div>
                                                    {/* <div className="input-group-append">
                                                        <span className="input-group-text">Upload</span>
                                                    </div> */}
                                                </div>
                                                <div className="text-danger">
                                                    {fileError}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="card-footer">
                                    <Button type="submit" isLoading={isLoading} className="btn btn-primary mr-2">Add </Button>
                                    <Button className="btn btn-default mr-2" onClick={() => navigate(-1)}>Cancel</Button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
  )
}

export default AddNote;