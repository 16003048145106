import React, { useEffect, useState } from 'react'
import 'react-confirm-alert/src/react-confirm-alert.css';
import { toast } from 'react-toastify';
import { Link, useParams } from 'react-router-dom';
import { getFranchiseById } from '@app/services/franchise';
import DataTable, { TableStyles } from 'react-data-table-component';
import { getAllActiveHeadings, getAllSubHeadingsById, getNotesByFranchiseWithPagination, getNoteUsers } from '@app/services/note';
import { useSelector } from 'react-redux';
import { Button } from '@app/components';
import moment from 'moment';
import { Form } from 'react-bootstrap';

const ListNotes = () => {

    const [isLoading, setLoading] = useState(false);
    const canAccess = useSelector((state: any) => state.auth.permissions.notes);
    const [notes, setNotes] = useState([]);
    const [headings, setHeadings] = useState([]);
    const [subHeadings, setSubHeadings] = useState([]);
    const [franchise, setFranchise] = useState([]);
    const [users, setUsers] = useState([]);
    const [totalRows, setTotalRows] = useState(0);
    const [perPage, setPerPage] = useState(10);
    const [currentPage, setCurrentPage] = useState(1);
    const [sortField, setSortField] = useState('');
    const [sortDirection, setSortDirection] = useState('');
    const [search, setSearch] = useState({note: '', heading_id: '', sub_heading_id: '', status: '', created_by: '', created_from_date: '', created_to_date: ''});
    const noteStatus = [{label:'Open', value:'open'}, {label:'Closed', value:'closed'}];
    let { franchise_id } = useParams();
    const [toDateError, setToDateError] = useState('');
    const [fromDateError, setFromDateError] = useState('');

    useEffect(() => {

        if(search.created_from_date != '' && search.created_to_date == ''){
            setToDateError('Please select To date')
        }else{
            setToDateError('')
        }

        if(search.created_from_date == '' && search.created_to_date != ''){
            setFromDateError('Please select From date')
        }else{
            setFromDateError('')
        }

        let fromDate = new Date(search.created_from_date).getTime();
        let toDate = new Date(search.created_to_date).getTime();

        if((search.created_from_date == '' && search.created_to_date == '') || (search.created_from_date != '' && search.created_to_date != '')){
            if (fromDate > toDate) {
                setFromDateError('From date must be less than To date')
            } else {
                setFromDateError('')
                getNotesWithPagination(franchise_id, 1, perPage, sortField, sortDirection);
            }
        }
    },[search]);

    useEffect(() => {
        getFranchiseDetailsById(franchise_id)
        getHeadings()
        getNoteCreatedUsers()
    },[]);

    const getFranchiseDetailsById = async (franchise_id) => {
        try {
            setLoading(true);
            const resp = await getFranchiseById(franchise_id);
            if(resp.success === true){
                setLoading(false);
                setFranchise(resp.collections);
            }else{
                setLoading(false);
                toast.error(resp?.errors[0]?.msg);
            }
        } catch (error: any) {
            setLoading(false);
            toast.error(error?.message || 'Failed');
        }
    };

    const getHeadings = async () => {
        try {
            const resp = await getAllActiveHeadings()
            if (resp?.success === true) {
                setHeadings(resp?.collections);
            } else {
                toast.error(resp?.errors[0]?.msg);
            }
        } catch (error: any) {
            toast.error(error?.message || 'Something went wrong');
        }
    };

    const getSubHeadingsByHeadingId = async (headingId) => {
        try {
            const resp = await getAllSubHeadingsById(headingId)
            if (resp?.success === true) {
                setSubHeadings(resp?.collections);
            } else {
                toast.error(resp?.errors[0]?.msg);
            }
        } catch (error: any) {
            toast.error(error?.message || 'Something went wrong');
        }
    };

    const getNoteCreatedUsers = async () => {
        try {
            const resp = await getNoteUsers(parseInt(franchise_id))
            if (resp?.success === true) {
                setUsers(resp?.collections);
            } else {
                toast.error(resp?.errors[0]?.msg);
            }
        } catch (error: any) {
            toast.error(error?.message || 'Something went wrong');
        }
    };

    const getNotesWithPagination = async (franchise_id, page, perPage, sortField, sortDirection) => {
        try {
            setLoading(true);
            const resp = await getNotesByFranchiseWithPagination(franchise_id, page, perPage, sortField, sortDirection, search);
            if (resp.success === true) {
                setLoading(false);
                setNotes(resp.collections.data);
                setTotalRows(resp?.collections?.pagination?.total);
            } else {
                setLoading(false);
                toast.error(resp?.errors[0]?.msg);
            }
        } catch (error: any) {
            setLoading(false);
            toast.error(error?.message || 'Something went wrong');
        }
    };

    const customStyle: TableStyles = {
        head: {
            style: {
                color: "#5E5873",
                fontSize: "13px",
                fontWeight: 900,
                // borderBottomWidth: '0px',
                // borderBottomColor: theme.divider.default,
                // borderBottomStyle: 'solid',
            },
        },
        headCells: {
            style: {
                borderColor: "#E9ECEF",
                textTransform: "uppercase",
                letterSpacing: "1px",
            },
        },
        headRow: {
            style: {
                backgroundColor: "#F7F7F8",
                minHeight: "44px",
                fontWeight: 500,
            },
        },
    };

    const columns = [
        {
            name: 'Heading',
            selector: (row: { heading: string; }) => row.heading ?? '---',
            sortable: true,
            sortField: 'heading_id',
        },
        {
            name: 'Sub Heading',
            selector: (row: { sub_heading: string; }) => row.sub_heading ?? '---',
            sortable: true,
            sortField: 'sub_heading_id',
        },
        {
            name: 'Note',
            selector: (row: { note: string; }) => row.note,
            format: (row: { note: string }) => row.note.slice(0,25),
            sortable: true,
            sortField: 'note',
        },
        {
            name: 'Status',
            selector: (row: { status: string; }) => row.status.charAt(0).toUpperCase() + row.status.slice(1),
            cell: (row: { status: string; }) => (
                row.status === 'open' ? <span className="badge bg-success">{ row.status.charAt(0).toUpperCase() + row.status.slice(1) } </span> : <span className="badge bg-danger">{ row.status.charAt(0).toUpperCase() + row.status.slice(1) } </span>
            ),
            sortable: true,
            sortField: 'status',
        },
        {
            name: 'Created By',
            selector: (row: { username: string; }) => row.username ?? '---',
            sortable: true,
            sortField: 'username',
            wrap: true
        },
        {
            name: 'Created At',
            selector: (row: { created_at: string; }) => row.created_at,
            cell: (row: { created_at: string; }) => (
                row.created_at ? moment(row.created_at).format('DD/MM/YYYY HH:mm') : '---'
            ),
            sortable: true,
            sortField: 'created_at',
        },
        {  
            name: 'Action',
            cell: (row: { id: number, franchise_id:number }) => (
                <>
                    { canAccess?.view && 
                        <Link
                            to={`/franchise/${row.franchise_id}/notes/${row.id}/view`}
                            role="button"
                            state={{ id: row.id }}
                            data-toggle="tooltip" title="View Note"
                        >
                            <i className="fas fa-eye mr-2"/>
                        </Link>
                    }
                </>
            ),
        },
    ];

    const handlePageChange = page => {
        getNotesWithPagination(franchise_id, page, perPage, sortField, sortDirection);
        setCurrentPage(page);
    };

    const handlePerRowsChange = async (newPerPage, page) => {
        getNotesWithPagination(franchise_id, page, newPerPage, sortField, sortDirection);
        setPerPage(newPerPage);
    };

    const handleSort = async (column, sortDirection) => {
        setSortField(column.sortField);
        setSortDirection(sortDirection);
        getNotesWithPagination(franchise_id, currentPage, perPage, column.sortField, sortDirection);
    };

    const handleResetFilter = async () => {
        setSearch({note: '', heading_id: '', sub_heading_id: '', status: '', created_by: '', created_from_date: '', created_to_date: ''});
    };

    return (
        <div>
            {/* Content Header (Page header) */}
            <section className="content-header">
                <div className="container-fluid">
                    <div className="row mb-2">
                        <div className="col-sm-6">
                            <h1>Manage Notes</h1>
                        </div>
                        <div className="col-sm-6">
                            <ol className="breadcrumb float-sm-right">
                                <li className="breadcrumb-item"><a href="/">Home</a></li>
                                <li className="breadcrumb-item"><a href="/franchise">Manage Franchise</a></li>
                                <li className="breadcrumb-item active">Manage Notes</li>
                            </ol>
                        </div>
                    </div>
                </div>{/* /.container-fluid */}
            </section>
            {/* Main content */}
            <section className="content">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12">
                            <div style={{ marginBottom: 10 }} className="row">
                                
                            </div>
                            <div className="card">
                                <div className="card-header">
                                    <h3 className="card-title">{ franchise?.['name'] } - Notes</h3>
                                    <div className="col-lg-12 text-right">
                                        <Link className="btn btn-default bg-gradient-secondary mr-2" to='/franchise'><i className="fas fa-caret-left"></i> Back</Link>
                                        { canAccess?.create &&
                                            <Link className="btn btn-primary" to='add' > + Add New Note </Link>
                                        }
                                    </div>
                                </div>
                                {/* /.card-header */}
                                <div className="card-body">
                                    <div className="row mb-2">
                                        <div className="col-sm-2">
                                            <label>Heading</label>
                                            <select
                                                className="form-control"
                                                name="heading_id"
                                                onChange={(e) => {
                                                    setSearch({ ...search, heading_id: e.target.value })
                                                    if(e.target.value){
                                                        getSubHeadingsByHeadingId(e.target.value);
                                                    }
                                                }}
                                                value={search.heading_id}
                                            >
                                                <option value="">Search By Heading</option>
                                                {headings.map((obj) => (
                                                    <option key={obj?.id} value={obj?.id}>
                                                        {obj?.heading}
                                                    </option>
                                                ))}
                                            </select>
                                        </div>
                                        <div className="col-sm-2">
                                            <label>Subheading</label>
                                            <select
                                                className="form-control"
                                                name="sub_heading_id"
                                                onChange={(e) => setSearch({ ...search, sub_heading_id: e.target.value })}
                                                value={search.sub_heading_id}
                                            >
                                                <option value="">Search By Subheading</option>
                                                {subHeadings.map((obj) => (
                                                    <option key={obj?.id} value={obj?.id}>
                                                        {obj?.sub_heading}
                                                    </option>
                                                ))}
                                            </select>
                                        </div>
                                        <div className="col-sm-4">
                                            <label>Note</label>
                                            <input type="text" name='note' className="form-control" value={search.note} onChange={(e) => setSearch({ ...search, note: e.target.value })} placeholder="Search By Note" />
                                        </div>
                                        <div className="col-sm-2">
                                            <label>Status</label>
                                            <select
                                                className="form-control"
                                                name="status"
                                                onChange={(e) => setSearch({ ...search, status: e.target.value })}
                                                value={search.status}
                                            >
                                                <option value="">Search By Status</option>
                                                {noteStatus.map((obj) => (
                                                    <option key={obj.value} value={obj.value}>
                                                        {obj.label}
                                                    </option>
                                                ))}
                                            </select>
                                        </div>
                                        <div className="col-sm-2">
                                            <label>Created By</label>
                                            <select
                                                className="form-control"
                                                name="created_by"
                                                onChange={(e) => setSearch({ ...search, created_by: e.target.value })}
                                                value={search.created_by}
                                            >
                                                <option value="">Search By User</option>
                                                {users.map((obj) => (
                                                    <option key={obj.user_id} value={obj.user_id}>
                                                        {obj.created_by}
                                                    </option>
                                                ))}
                                            </select>
                                        </div>
                                    </div>
                                    <div className="row my-3">
                                        <div className="col-sm-2">
                                            <label>Created From Date</label>
                                            <Form.Control
                                                type="date"
                                                name="created_from_date" 
                                                id="created_from_date" 
                                                placeholder="Enter from date" 
                                                value={search.created_from_date} 
                                                onChange={(e) => setSearch({ ...search, created_from_date: e.target.value })}
                                            />
                                            <div className="text-danger">
                                                { fromDateError }
                                            </div>
                                        </div>
                                        <div className="col-sm-2">
                                            <label>Created To Date</label>
                                            <Form.Control
                                                type="date"
                                                name="created_to_date" 
                                                id="created_to_date" 
                                                placeholder="Enter to date" 
                                                value={search.created_to_date} 
                                                onChange={(e) => setSearch({ ...search, created_to_date: e.target.value })}
                                            />
                                            <div className="text-danger">
                                                { toDateError }
                                            </div>
                                        </div>
                                        <div className='col-sm-2'>
                                            <label>&nbsp;</label>
                                            <Button type="submit" isLoading={ isLoading } onClick={handleResetFilter} className="btn btn-warning mr-2 form-control">Reset</Button>
                                        </div>
                                    </div>
                                    <DataTable
                                        columns={columns}
                                        data={notes}
                                        pagination
                                        paginationServer
                                        paginationTotalRows={totalRows}
                                        paginationDefaultPage={currentPage}
                                        onChangePage={handlePageChange}
                                        onChangeRowsPerPage={handlePerRowsChange}
                                        paginationPerPage={perPage}
                                        paginationRowsPerPageOptions={[5, 10, 20, 30, 50, 100]}
                                        progressPending={isLoading}
                                        sortServer
                                        onSort={handleSort}
                                        customStyles={customStyle}
                                    />
                                </div>
                                {/* /.card-body */}
                            </div>
                        </div>
                        {/* /.col */}
                    </div>
                    {/* /.row */}
                </div>
                {/* /.container-fluid */}
            </section>
            {/* /.content */}
        </div>
    )
};

export default ListNotes;