import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { Button } from '@components';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';
import { setWindowClass } from '@app/utils/helpers';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { Form, InputGroup } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { forgotPassword } from '@app/services/auth';
import { useDispatch } from 'react-redux';
import { setRecoverPasswordEmail } from '@store/reducers/auth';

const ForgotPassword = () => {
	const [t] = useTranslation();
	const [isLoading, setLoading] = useState(false);
	const dispatch = useDispatch();
	const navigate = useNavigate();

	const submit = async (payload) => {
		try {
			setLoading(true);
			const resp = await forgotPassword(payload);
			if (resp.success === true) {
				setLoading(false);
				dispatch(setRecoverPasswordEmail(payload.email));
				navigate('/recover-password');
			} else {
				setLoading(false);
				toast.error(resp?.errors[0]?.msg);
			}
		} catch (error: any) {
			setLoading(false);
			toast.error(error?.message || 'Something went wrong');
		}
	}

	const { handleChange, values, handleSubmit, touched, errors } = useFormik({
		initialValues: {
			email: ''
		},
		validationSchema: Yup.object({
			email: Yup.string().email('Invalid email address').required('Please enter email')
		}),
		onSubmit: (values) => {
			submit(values);
		}
	});

	setWindowClass('hold-transition login-page');

	return (
		<div className="login-box">
			<div className="card card-outline card-primary">
				<div className="card-header text-center">
					<Link to="/" className="h1">
						{/* <b>FCL </b> */}
						<span>Forgot Password</span>
					</Link>
				</div>
				<div className="card-body">
					<p className="login-box-msg">{t('recover.forgotYourPassword')}</p>
					<form onSubmit={handleSubmit}>
						<div className="mb-3">
							<InputGroup className="mb-3">
								<Form.Control
									id="email"
									name="email"
									type="email"
									placeholder="Email"
									onChange={handleChange}
									value={values.email}
									isValid={touched.email && !errors.email}
									isInvalid={touched.email && !!errors.email}
								/>
								{touched.email && errors.email ? (
									<Form.Control.Feedback type="invalid">
										{errors.email}
									</Form.Control.Feedback>
								) : (
									<InputGroup.Append>
										<InputGroup.Text>
											<FontAwesomeIcon icon={faEnvelope} />
										</InputGroup.Text>
									</InputGroup.Append>
								)}
							</InputGroup>
						</div>
						<div className="row">
							<div className="col-12">
								<Button type="submit" block isLoading={isLoading}>
									{t('recover.requestNewPassword')}
								</Button>
							</div>
						</div>
					</form>
					<p className="mt-3 mb-1">
						<Link to="/login">Login</Link>
					</p>
				</div>
			</div>
		</div>
	);
};

export default ForgotPassword;
