import { useFormik } from 'formik';
import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import * as Yup from 'yup';
import {toast} from 'react-toastify';
import { Button } from '@app/components';
import { useSelector } from 'react-redux';
import { addCommentOnNote, closeNote, getNoteById, getNoteDocumentById } from '@app/services/note';
import moment from 'moment';
import { confirmAlert } from 'react-confirm-alert';
import fileDownload from 'js-file-download';
import { getExtension } from '@app/services/opsManual';
import PreviewModal from '@app/pages/ops-manual/PreviewModal';

const ViewNoteDetails = () => {

    let obj: any = {}
    const [noteDetails, setNoteDetails] = useState(obj);
    const [fileList, setFileList] = useState([]);
    const [fileError, setFileError] = useState('');
    const [isLoading, setLoading] = useState(false);
    const [closed, setClosed] = useState(false);
    const [url, setUrl] = useState('');
    const [filename, setFileName] = useState('');
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const canAccess = useSelector((state: any) => state.auth.permissions.notes);
    const navigate = useNavigate();
    let { franchise_id, note_id } = useParams();

    useEffect(() => {
        getNoteDetailsById(note_id)
    },[]);

    const getNoteDetailsById = async (note_id) => {
        try {
            const resp = await getNoteById(note_id)
            if (resp?.success === true) {
                setNoteDetails(resp?.collections);
            } else {
                toast.error(resp?.errors[0]?.msg);
            }
        } catch (error: any) {
            toast.error(error?.message || 'Something went wrong');
        }
    };

    const addComment = async (payload) => {
        try {
            setLoading(true);
            const resp = await addCommentOnNote(payload);
            if (resp.success === true) {
                setLoading(false);
                toast.success(resp?.message);
                setFileList([])
                resetForm()
                getNoteDetailsById(note_id)
            } else {
                setLoading(false);
                toast.error(resp?.errors[0]?.msg);
            }
        } catch (error: any) {
            setLoading(false);
            toast.error(error?.message || 'Something went wrong');
        }
    };

    const markNoteAsClosed = async (payload) => {
        try {
            setLoading(true);
            const resp = await closeNote(payload);
            if (resp.success === true) {
                setLoading(false);
                toast.success(resp?.message);
                setClosed(true)
                getNoteDetailsById(note_id)
            } else {
                setLoading(false);
                toast.error(resp?.errors[0]?.msg);
            }
        } catch (error: any) {
            setLoading(false);
            toast.error(error?.message || 'Something went wrong');
        }
    };

    const { handleChange, values, handleSubmit, touched, errors, resetForm} = useFormik({
        initialValues: {
            franchise_id: franchise_id,
            note_id: note_id,
            comment: '',
        },
        validationSchema: Yup.object({
            comment: Yup.string().trim().required('Please enter comment'),
        }),
        onSubmit: (values) => {
            const data = new FormData();
            if (fileList.length) {
                Array.from(fileList).forEach((file) => {
                    data.append("comment_documents", file);
                });
            }
            Object.keys(values).forEach(fieldName => {
                data.append(fieldName, values[fieldName]);
            })
            addComment(data);
        }
    });

    const handleCommentDocument = (e) => {
        if(e.target.files.length > 10){
            setFileError('You can only upload a maximum of 10 documents')
            setFileList([])
        }else{
            setFileError('')
            setFileList(e.target.files);
        }
    };

    const handleMarkAsClosed = (e) => {
        console.log(e.target.checked)
        if(e.target.checked){
            confirmAlert({
                title: 'Confirm to submit',
                message: 'Are you sure to close this note?',
                buttons: [
                    {
                        label: 'Yes',
                        onClick: () => markNoteAsClosed({note_id: note_id, franchise_id: franchise_id})
                    },
                    {
                        label: 'No',
                        onClick: () => {}
                    }
                ]
            });
            e.preventDefault();
        }
    }

    const previewFile = async (docId, name, type) => {
        try{
            const resp = await getNoteDocumentById(docId, type);
            setUrl(URL.createObjectURL(resp))
            setFileName(name)
            handleShow();
        } catch (error) {
            setLoading(false);
            console.log(error)
        }
    }

    const downloadDocument = async (docId, name, type) => {
        try{
            setLoading(true);
            const resp = await getNoteDocumentById(docId, type);
            fileDownload(resp, name);
            setLoading(false);        
        } catch (error) {
            setLoading(false);
            console.log(error)
        }
    }

    return (
    <div>
        <section className="content">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-12">
                        <div className="card card-widget">
                            <div className="card-header">
                                {/* /.user-block */}
                                <div className="card-tools">
                                    { noteDetails?.status == 'open' && canAccess['close-note'] && (
                                        <div className="icheck-primary btn btn-tool">
                                            <input 
                                                type="checkbox" 
                                                className="form-check-input custom-control-input-danger" 
                                                checked={closed} 
                                                id='mark-as-closed' 
                                                name='mark-as-closed' 
                                                onChange={ (e) => handleMarkAsClosed(e) }
                                            />
                                            <label htmlFor='mark-as-closed' className="form-check-label">
                                                <span className='text-danger font-weight-bold'>Mark As Closed</span>
                                            </label>
                                        </div>
                                    )}
                                    <button type="button" style={{color: 'black'}} className="btn btn-tool ml-5" data-card-widget="close" onClick={() => navigate(-1)}>
                                        <i className="fas fa-lg fa-times-circle" />
                                    </button>
                                </div>
                                {/* /.card-tools */}
                                <div className='row mr-2'>
                                    <div className='col-sm-3'>
                                        <div className="text-muted">
                                            <p className="text-sm">Franchise Name
                                                <b className="d-block">{ noteDetails?.franchise_name }</b>
                                            </p>
                                        </div>        
                                    </div>
                                    <div className='col-sm-3'>
                                        <div className="text-muted">
                                            <p className="text-sm">Heading
                                                <b className="d-block">{ noteDetails?.heading }</b>
                                            </p>
                                        </div>        
                                    </div>
                                    <div className='col-sm-3'>
                                        <div className="text-muted">
                                            <p className="text-sm">Subheading
                                                <b className="d-block">{ noteDetails?.sub_heading ?? '---' }</b>
                                            </p>
                                        </div>        
                                    </div>
                                    <div className='col-sm-3'>
                                        <div className="text-muted">
                                            <p className="text-sm">Status
                                                <b className="d-block">
                                                    { noteDetails?.status ? (noteDetails?.status === 'open' ? <span className="badge bg-success">{ noteDetails?.status.charAt(0).toUpperCase() + noteDetails?.status.slice(1) } </span> : <span className="badge bg-danger">{ noteDetails?.status.charAt(0).toUpperCase() + noteDetails?.status.slice(1) } </span> ) : '---' }
                                                </b>
                                            </p>
                                        </div>        
                                    </div>
                                </div>
                            </div>
                            {/* /.card-header */}
                            <div className="card-body">
                                <div className='post'>
                                    <div className="user-block">
                                        <img className="img-circle img-bordered-sm" src="/img/default-profile.png" alt="user image" />
                                        <span className="username">
                                            { noteDetails?.created_by }
                                        </span>
                                        <span className="description">{ moment(noteDetails?.created_at).format('Do MMM YYYY hh:mm A') }</span>
                                    </div>
                                    {/* <img className="img-circle img-sm elevation-2" src="/img/default-profile.png" alt="User Image" />
                                    <span className="username" style={{color: '#495057', display: 'block', fontWeight: '600'}}>
                                        Maria Gonzales
                                        <span className="text-muted float-right">14th Dec 2024 8:03 PM</span>
                                    </span> */}
                                    {/* post text */}
                                    <p>{ noteDetails?.note } </p>
                                
                                    <div className='row'>
                                        { noteDetails?.note_documents && noteDetails?.note_documents.map((doc) => (
                                            <div className='col-sm-3' key={ doc?.id }>
                                                { (['docx', 'doc', 'xlsx', 'xls', 'wav'].findIndex((ext) => ext == getExtension(doc?.doc_name))) == -1 &&
                                                    <a href="javascript:void(0)"  
                                                        className="link-black text-sm"
                                                        onClick={ (e) => { previewFile(doc?.id, doc?.doc_name, 'note')}}
                                                        data-toggle="tooltip" title="Preview Document"
                                                    >
                                                            <i className="fas fa-link mr-1" /> { doc?.doc_name }
                                                    </a>
                                                }

                                                { (['docx', 'doc', 'xlsx', 'xls', 'wav'].findIndex((ext) => ext == getExtension(doc?.doc_name))) >= 0 &&
                                                    <a href="javascript:void(0)"  
                                                        className="link-black text-sm"
                                                        onClick={ (e) => { downloadDocument(doc?.id, doc?.doc_name, 'note')}}
                                                        data-toggle="tooltip" title="Download Document"
                                                    >
                                                            <i className="fas fa-link mr-1" /> { doc?.doc_name }
                                                    </a>
                                                }
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>
                            {/* /.card-body */}
                            <div className="card-footer card-comments">
                                <span style={{fontWeight: 600, fontSize: "1.25rem", color: '#6c757d'}}><i className="far fa-comments mr-1" ></i>Comments</span>
                                { noteDetails?.comments && noteDetails?.comments.map((comment, i) => (
                                    <div className="card-comment" key={i}>
                                        {/* User image */}
                                        <img className="img-circle img-sm elevation-2" src="/img/default-profile.png" alt="User Image" />
                                        <div className="comment-text">
                                            <span className="username">
                                                { comment?.created_by }
                                                <span className="text-muted float-right">{ moment(comment?.created_at).format('Do MMM YYYY hh:mm A') }</span>
                                            </span>{/* /.username */}
                                            { comment?.comment }
                                        </div>
                                        <div className='row my-2 ml-4'>
                                            { comment?.comment_documents && comment?.comment_documents.map((doc) => (

                                                <div className='col-sm-3' key={ doc?.id }>
                                                    { (['docx', 'doc', 'xlsx', 'xls', 'wav'].findIndex((ext) => ext == getExtension(doc?.doc_name))) == -1 &&
                                                        <a href="javascript:void(0)"  
                                                            className="link-black text-sm"
                                                            onClick={ (e) => { previewFile(doc?.id, doc?.doc_name, 'comment')}}
                                                            data-toggle="tooltip" title="Preview Document"
                                                        >
                                                                <i className="fas fa-link mr-1" /> { doc?.doc_name } 
                                                        </a>
                                                    }

                                                    { (['docx', 'doc', 'xlsx', 'xls', 'wav'].findIndex((ext) => ext == getExtension(doc?.doc_name))) >= 0 &&
                                                        <a href="javascript:void(0)"  
                                                            className="link-black text-sm"
                                                            onClick={ (e) => { downloadDocument(doc?.id, doc?.doc_name, 'comment')}}
                                                            data-toggle="tooltip" title="Download Document"
                                                        >
                                                                <i className="fas fa-link mr-1" /> { doc?.doc_name }
                                                        </a>
                                                    }
                                                </div>
                                            ))}
                                        </div>
                                        {/* /.comment-text */}
                                    </div>
                                ))}
                                { noteDetails?.comments && noteDetails?.comments.length == 0 && (
                                    <div className="card-comment">
                                        <p className='text-center'>No comments yet</p> 
                                    </div>
                                )}
                                {/* /.card-comment */}
                            </div>
                            {/* /.card-footer */}
                            { noteDetails?.status === 'open' && canAccess['add-comment'] && (
                                <div className="card-footer">
                                     <form onSubmit={handleSubmit}>
                                        <div className='row'>
                                            <div className='col-sm-7'>
                                                <input 
                                                    type="text" 
                                                    id="comment" 
                                                    name="comment" 
                                                    onChange={handleChange} 
                                                    value={values.comment} 
                                                    className="form-control" 
                                                    placeholder="Please enter comment" 
                                                />
                                                {(touched.comment && errors.comment) && (
                                                    <div className="text-danger">
                                                        {errors.comment}
                                                    </div>
                                                )}
                                            </div>
                                            <div className='col-sm-3'>
                                                <div className="input-group">
                                                    <div className="custom-file">
                                                        <input type="file" className="custom-file-input" id="note_documents" onChange={ handleCommentDocument } multiple/>
                                                        <label className="custom-file-label" htmlFor="note_documents">
                                                            {fileList.length > 0 ? `${fileList.length} file(s) selected` : 'Choose file'}
                                                        </label>
                                                    </div>
                                                </div>
                                                <div className="text-danger">
                                                    {fileError}
                                                </div>
                                            </div>
                                            <div className='col-sm-2'>
                                                <Button type="submit" isLoading={isLoading} className="btn btn-primary mr-2">Add Comment </Button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            )}
                            {/* /.card-footer */}
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <PreviewModal
            show={show}
            handleClose={handleClose}
            url={url}
            filename={filename}
        />
    </div>
  )
}

export default ViewNoteDetails;