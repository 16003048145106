import React, { useState } from 'react'
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import { Button } from '@app/components';
import { Form, Modal } from 'react-bootstrap';
import { addFeesCredits } from '@app/services/student';

const AddStudentFeeCreditModal = (props) => {

    const [isLoading, setLoading] = useState(false);
    const types = [{value: 'fee' , name:'Fee'}, {value: 'credit', name:'Credit'}];
    let vatTypes = []
    if(props?.feeCreditVAT)
        vatTypes = [{value: 'inclusive' , name:'Inclusive', isDisabled: false, isChecked: false}, {value: 'exclusive', name:'Exclusive', isDisabled: false, isChecked: false}, {value: 'not_applicable', name:'N/A', isDisabled: true, isChecked: false}]
    else
        vatTypes = [{value: 'inclusive' , name:'Inclusive', isDisabled: true, isChecked: false}, {value: 'exclusive', name:'Exclusive', isDisabled: true, isChecked: false}, {value: 'not_applicable', name:'N/A', isDisabled: false, isChecked: true}]

    const { handleChange, values, handleSubmit, touched, errors, setFieldValue, resetForm } = useFormik({
        initialValues: {
            student_id: props?.student?.id ?? '',
            type: '',
            amount: '',
            vat_status: props?.feeCreditVAT ? '' : 'not_applicable',
            description: ''
        },
        validationSchema: Yup.object({
            type: Yup.string().required('Please select type'),
            amount: Yup.number().required('Please enter amount')
                    .min(1, "Amount must be greater than or equal to 1")
                    .max(100000, "Amount must be less than or equal to 1,00,000"),
            vat_status: Yup.string().required('Please select VAT'),
            description: Yup.string().trim().required('Please enter description').max(250, "Description must be less than 250 characters"),
        }),
        onSubmit: (values) => {
            addStudentFeesCredit(values);
        },
        enableReinitialize: true,
    });

    const addStudentFeesCredit = async (payload) => {
        try {
            setLoading(true);
            const resp = await addFeesCredits(payload);
            if(resp.success === true){
                setLoading(false);
                toast.success(resp?.message);
                props.handleClose();
                props.callbackGetCreditFeesByStudentId();
                resetForm()
            }else{
                toast.error(resp?.errors[0]?.msg);
                setLoading(false);
            }
        } catch (error: any) {
            setLoading(false);
            toast.error(error?.message || 'Internal Server Error');
        }
    };

    return (
    <div>
        <Modal show={props.show} onHide={props.handleClose} size="sm">
            <Modal.Header closeButton>
                <Modal.Title>Add Fees/Credits </Modal.Title>
            </Modal.Header>
            <form onSubmit={handleSubmit}>
                <Modal.Body>
                    <div className='row'>
                        <div className="col-sm-12">
                            <div className="form-group">
                                <dt>Student Name </dt>
                                <dd>{ props?.student?.first_name } { props?.student?.last_name } </dd>
                            </div>
                        </div>
                        <div className="col-sm-12">
                            <div className="form-group">
                                <label htmlFor="type">Type <span className="required">*</span></label>
                                <select
                                    className="form-control"
                                    name="type"
                                    onChange={handleChange}
                                    value={values.type}
                                >
                                    <option value="">Select Type</option>
                                    {types.map((obj) => (
                                        <option key={obj.value} value={obj.value}>
                                            {obj.name}
                                        </option>
                                    ))}
                                </select>
                                {(touched.type && errors.type) && (
                                    <div className="text-danger">
                                        {errors.type}
                                    </div>
                                )}
                            </div>
                        </div>
                        <div className="col-sm-12">
                            <div className="form-group">
                                <label htmlFor="amount">Amount <span className="required">*</span></label>
                                <div className='input-group'>
                                    <div className="input-group-prepend">
                                        <span className="input-group-text">{ props?.currency }</span>
                                    </div>
                                    <Form.Control 
                                        type="number" 
                                        name="amount" 
                                        id="amount" 
                                        placeholder="Enter amount" 
                                        value={values.amount}
                                        onChange={handleChange}
                                        onWheel={ (e) => e.currentTarget.blur() }
                                    />
                                </div>
                                {(touched.amount && errors.amount) && (
                                    <div className="text-danger">
                                        {errors.amount}
                                    </div>
                                )}
                            </div>
                        </div>
                        <div className="col-sm-12">
                            <div className="form-group">
                                <label htmlFor="vat_status">VAT <span className="required">*</span></label>
                                {(vatTypes).map((obj) => (
                                    <div className="form-check col-sm-12" key={obj?.value}>
                                        <input 
                                            className="form-check-input" 
                                            id="vat_status"
                                            type="radio" 
                                            name="vat_status" 
                                            onChange={ (e) => {
                                                setFieldValue('vat_status', e.target.value)
                                            }}
                                            value={obj?.value}
                                            checked={obj?.value == values.vat_status}
                                            disabled={obj?.isDisabled}
                                        />
                                        <label className="form-check-label">{ obj?.name }</label><br/>
                                    </div>
                                )) }
                                {(touched.vat_status && errors.vat_status) && (
                                    <div className="text-danger">
                                        {errors.vat_status}
                                    </div>
                                )}
                            </div>
                        </div>
                        <div className="col-sm-12">
                            <div className="form-group">
                                <label htmlFor="description">Description <span className="required">*</span></label>
                                <textarea 
                                    className="form-control" 
                                    id="description" 
                                    placeholder="Enter description" 
                                    name="description" 
                                    onChange={handleChange} 
                                    value={values.description}
                                ></textarea>
                                {(touched.description && errors.description) && (
                                    <div className="text-danger">
                                        {errors.description}
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button type="submit" isLoading={isLoading} className="btn btn-primary mr-2">Add </Button>
                    <Button className='btn btn-default' onClick={props.handleClose}>Close</Button>
                </Modal.Footer>
            </form>
        </Modal>
    </div>
  )
}

export default AddStudentFeeCreditModal;