import { useFormik } from 'formik';
import React, { useEffect, useState } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'
import * as Yup from 'yup';
import {Form} from 'react-bootstrap';
import {toast} from 'react-toastify';
import { Button } from '@app/components';
import moment from 'moment';
import PreviewModal from '@app/pages/ops-manual/PreviewModal';
import { confirmAlert } from 'react-confirm-alert';
import { getExtension } from '@app/services/opsManual';
import fileDownload from 'js-file-download';
import { useSelector } from 'react-redux';
import { deleteDBSDocumentById, getDBSByUserId, getDBSDocumentById, getUserById, updateUserDBSDetails } from '@app/services/user';

const UpdateDBSCert = () => {

    let { id } = useParams();
    let obj: any = {}
    const [userData, setUserData] = useState(obj);
    const [dbsDocList, setDBSDocList] = useState([]);
    const [dbsDocError, setDBSDocError] = useState('');
    const [dbsDetails, setDBSDetails] = useState(obj);
    const [dbsDocuments, setDBSDocuments] = useState([]);
    const [isLoading, setLoading] = useState(false);
    const [url, setUrl] = useState('');
    const [filename, setFileName] = useState('');
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const navigate = useNavigate();
    const canAccess = useSelector((state: any) => state.auth.permissions.users);

    useEffect(() => {
        if(!canAccess['update-dbs']){
            navigate('/users');
        }
        getUserDetailsById(id)
        getDBSDetailsByUserId(id)

        return () => {
            setDBSDetails(obj)
            setDBSDocuments([])
        }
    }, []);

    const getUserDetailsById = async (id) => {
        try {
            const resp = await getUserById(id);
            if(resp.success === true){
                setUserData(resp.collections);
            }else{
                toast.error(resp?.errors[0]?.msg);
                navigate('/users');
            }
        } catch (error: any) {
            toast.error(error?.message || 'Failed');
        }
    };
    const updateDBSDetails = async (payload) => {
        try {
            setLoading(true);
            const resp = await updateUserDBSDetails(id, payload)
            if(resp.success === true){
                setLoading(false)
                toast.success(resp?.message)
                getDBSDetailsByUserId(id)
                setDBSDocList([])
                // resetForm()
                // navigate('/users/dbs/' + id);
            }else{
                setLoading(false)
                toast.error(resp?.errors[0]?.msg)
            }
          } catch (error: any) {
            setLoading(false)
            toast.error(error?.message || 'Something went wrong')
          }
    };

    const getDBSDetailsByUserId = async (id) => {
        try {
            setLoading(true);
            const resp = await getDBSByUserId(id);
            if(resp.success === true){
                setLoading(false);
                setDBSDetails(resp?.collections)
                if(Object.keys(resp?.collections).length > 0){
                    setDBSDocuments(resp?.collections?.dbs_documents)
                }
            }else{
                setLoading(false);
                toast.error(resp?.message);
                navigate('/users');
            }
        } catch (error: any) {
            setLoading(false);
            toast.error(error?.message || 'Something went wrong');
        }
    };

    const { handleChange, values, handleSubmit, touched, errors, resetForm } = useFormik({
        initialValues: {
            user_id: id,
            cert_date: dbsDetails?.cert_date || '',
            cert_number: dbsDetails?.cert_number || '',
            update_service: dbsDetails?.update_service || false,
            status_issues: dbsDetails?.status_issues || false,
            update_service_check_date: dbsDetails?.update_service_check_date || '',
            surname_cert: dbsDetails?.surname_cert || '',
        },
        validationSchema: Yup.object({
            cert_date: Yup.string().required('Please enter DBS cert date'), //.matches(/^(\d{4})(\/|-)(\d{1,2})(\/|-)(\d{1,2})$/, 'Please enter a valid date'),
            cert_number: Yup.string().required('Please enter DBS cert number'),
            update_service_check_date: Yup.string().required('Please enter DBS cert date'), //.matches(/^(\d{4})(\/|-)(\d{1,2})(\/|-)(\d{1,2})$/, 'Please enter a valid date'),
            surname_cert: Yup.string().required('Please enter surname per DBS cert'),
        }),
        onSubmit: (values) => {
            const data = new FormData();
            if (dbsDocList.length) {
                Array.from(dbsDocList).forEach((file) => {
                    data.append("dbs_documents", file);
                });
            }
            Object.keys(values).forEach(fieldName => {
                data.append(fieldName, values[fieldName]);
            })
            updateDBSDetails(data);
        },
        enableReinitialize: true
    });

    const handleDBSDocumentChange = (e) => {
        if(e.target.files.length > 10){
            setDBSDocError('You can only upload a maximum of 10 documents')
            setDBSDocList([])
        }else{
            setDBSDocError('')
            setDBSDocList(e.target.files);
        }
    };
    
    const previewFile = async (docId, name) => {
        try{
            const resp = await getDBSDocumentById(docId);
            setUrl(URL.createObjectURL(resp))
            setFileName(name)
            handleShow();

        } catch (error) {
            // setLoading(false);
            console.log(error)
        }
    }

    const downloadDocument = async (docId, name) => {
        try{
            // setLoading(true);
            const resp = await getDBSDocumentById(docId);
            fileDownload(resp, name);
            // setLoading(false);        
        } catch (error) {
            // setLoading(false);
            console.log(error)
        }
    }

    const deleteDocumentConfirm = (e, docId) => {
        confirmAlert({
            title: 'Confirm to submit',
            message: 'Are you sure you want to delete this document?',
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => deleteDocument(docId)
                },
                {
                    label: 'No',
                    onClick: () => {}
                }
            ]
        });
        e.preventDefault();
    };

    const deleteDocument = async (docId) => {
        try {
            setLoading(true);
            const resp = await deleteDBSDocumentById(docId);
            if(resp.success === true){
                toast.success(resp?.message);
                setLoading(false);
                getDBSDetailsByUserId(id);
            }else{
                toast.error(resp?.errors[0]?.msg);
                setLoading(false);
            }
          } catch (error: any) {
            setLoading(false);
            toast.error(error?.message || 'Something went wrong.');
          }
    };

    return (
    <div>
        <section className="content">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-12">
                        <div className="card card-primary">
                            <div className="card-header">
                                <h3 className="card-title">Update DBS Certificate - { userData?.first_name + ' ' + userData?.last_name } </h3>
                            </div>
                            <form onSubmit={handleSubmit}>
                                <div className="card-body">
                                    <div className='row'>
                                        <div className="col-sm-4">
                                            <div className="form-group">
                                                <label htmlFor='cert_date'>DBS Cert. Date <span className="required">*</span></label>
                                                <Form.Control 
                                                    type="date" 
                                                    id='cert_date' 
                                                    name='cert_date'
                                                    placeholder="Enter date for update service check" 
                                                    value={moment(values.cert_date).format('YYYY-MM-DD')}
                                                    // min={moment((batches.find(obj => { return obj.id == formik.values.batches[index].id }))?.start_date).format('YYYY-MM-DD')}
                                                    // max={moment((batches.find(obj => { return obj.id == formik.values.batches[index].id }))?.end_date).format('YYYY-MM-DD')}
                                                    onChange={handleChange}
                                                />
                                                {(touched.cert_date && errors.cert_date) && (
                                                    <div className="text-danger">
                                                        {errors.cert_date}
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                        <div className="col-sm-4">
                                            <div className="form-group">
                                                <label htmlFor="cert_number">DBS Cert. Number <span className="required">*</span></label>
                                                <div className='input-group'>
                                                    <Form.Control 
                                                        type="text" 
                                                        className="form-control" 
                                                        id="cert_number" 
                                                        placeholder="Enter DBS cert number"
                                                        name="cert_number" 
                                                        onChange={handleChange} 
                                                        value={values.cert_number}
                                                    />
                                                </div>
                                                {(touched.cert_number && errors.cert_number) && (
                                                    <div className="text-danger">
                                                        {errors.cert_number}
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                        <div className="col-sm-4">
                                            <div className="form-group">
                                                <label htmlFor='update_service_check_date'>DOB For Update Service Check <span className="required">*</span></label>
                                                <Form.Control 
                                                    type="date" 
                                                    id='update_service_check_date' 
                                                    name='update_service_check_date'
                                                    placeholder="Enter date for update service check" 
                                                    value={moment(values.update_service_check_date).format('YYYY-MM-DD')}
                                                    // min={moment((batches.find(obj => { return obj.id == formik.values.batches[index].id }))?.start_date).format('YYYY-MM-DD')}
                                                    // max={moment((batches.find(obj => { return obj.id == formik.values.batches[index].id }))?.end_date).format('YYYY-MM-DD')}
                                                    onChange={handleChange}
                                                />
                                                {(touched.update_service_check_date && errors.update_service_check_date) && (
                                                    <div className="text-danger">
                                                        {errors.update_service_check_date}
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                        <div className="col-sm-4">
                                            <div className="form-group">
                                                <label className='col-form-label'>Update Service</label>
                                                <div className="custom-control custom-switch">
                                                    <input 
                                                        type="checkbox" 
                                                        name='update_service' 
                                                        className="custom-control-input" 
                                                        id="update_service"
                                                        onChange={handleChange}
                                                        checked={values.update_service} 
                                                    />
                                                    <label className="custom-control-label" htmlFor="update_service">Yes/No</label>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-sm-4">
                                            <div className="form-group">
                                                <label className='col-form-label'>DBS Cert Copy Held?</label>
                                                <div className="custom-control custom-switch">
                                                    <input 
                                                        type="checkbox" 
                                                        name='status_issues' 
                                                        className="custom-control-input" 
                                                        id="status_issues"
                                                        onChange={handleChange}
                                                        checked={values.status_issues} 
                                                    />
                                                    <label className="custom-control-label" htmlFor="status_issues">Yes/No</label>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-sm-4">
                                            <div className="form-group">
                                                <label htmlFor="surname_cert">Surname Per DBS Cert For Update Service Check <span className="required">*</span></label>
                                                <div className='input-group'>
                                                    <Form.Control 
                                                        type="text" 
                                                        className="form-control" 
                                                        id="surname_cert" 
                                                        placeholder="Enter Surname per DBS Cert For Update Service Check"
                                                        name="surname_cert" 
                                                        onChange={handleChange} 
                                                        value={values.surname_cert}
                                                        onWheel={ (e) => e.currentTarget.blur() }
                                                        />
                                                </div>
                                                {(touched.surname_cert && errors.surname_cert) && (
                                                    <div className="text-danger">
                                                        {errors.surname_cert}
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                        <div className="col-sm-4">
                                            <div className="form-group">
                                                <label htmlFor="dbs_documents">Upload DBS Document(s)</label>
                                                <div className="input-group">
                                                    <div className="custom-file">
                                                        <input type="file" className="custom-file-input" id="dbs_documents" onChange={handleDBSDocumentChange} multiple/>
                                                        <label className="custom-file-label" htmlFor="dbs_documents">
                                                            {dbsDocList.length > 0 ? `${dbsDocList.length} file(s) selected` : 'Choose file'}
                                                        </label>
                                                    </div>
                                                    {/* <div className="input-group-append">
                                                        <span className="input-group-text">Upload</span>
                                                    </div> */}
                                                </div>
                                                <div className="text-danger">
                                                    {dbsDocError}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="card-footer">
                                    <Button type="submit" isLoading={isLoading} className="btn btn-primary mr-2">Update </Button>
                                    <Link className="btn btn-default" to={'/users'}>Cancel</Link>
                                </div>
                            </form>
                        </div>
                    </div>
                    <div className="col-md-12">
                        <div className="card card-primary">
                            <div className="card-header">
                                <h3 className="card-title">DBS Documents - { userData?.first_name + ' ' + userData?.last_name }</h3>
                            </div>
                            <div className="card-body">
                                <div className="row">
                                    {dbsDocuments.length > 0 && (
                                        <table className="table table-bordered" id="client_id_table">
                                            <tbody>
                                                <tr className='text-center'>
                                                    <th>Document</th>
                                                    <th>Action</th>
                                                </tr>
                                                {dbsDocuments.length > 0 && dbsDocuments.map(file => (
                                                    <tr key={file?.id}>
                                                        <td>
                                                            <a href='javascript:void(0)' onClick={ (e) => { downloadDocument(file?.id, file?.name)} } data-toggle="tooltip" title="Preview" className='mx-3'> 
                                                                {file?.name}
                                                            </a>
                                                        </td>
                                                        <td className='pl-5'>
                                                            <a href='javascript:void(0)'
                                                                onClick={ (e) => { deleteDocumentConfirm(e, file?.id)} } 
                                                                data-toggle="tooltip" title="Delete Document">
                                                                <i className="fas fa-trash fa-sm mr-2"/>
                                                            </a>
                                                            <a href='javascript:void(0)' onClick={ (e) => { downloadDocument(file?.id, file?.name)} } data-toggle="tooltip" title="Download Document">
                                                                <i className="fas fa-download mr-2"></i>
                                                            </a> 
                                                            { (['docx', 'doc', 'xlsx', 'xls', 'wav'].findIndex((ext) => ext == getExtension(file?.name))) < 0 &&
                                                                <a href='javascript:void(0)'
                                                                onClick={ (e) => { previewFile(file?.id, file?.name)} }
                                                                    data-toggle="tooltip" title="Preview Document">
                                                                    <i className="fas fa-eye fa-sm mr-2"/>
                                                                </a>
                                                            }
                                                        </td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    )}

                                    {dbsDocuments.length == 0 && (
                                        <span className='text-danger'>No DBS documents have been uploaded for this user.</span>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <PreviewModal
            show={show}
            handleClose={handleClose}
            url={url}
            filename={filename}
        />
    </div>
  )
}

export default UpdateDBSCert
