import { notesURL } from "@app/constants/ApiEndPoints";
import axios from "axios";

export const getNotesByFranchiseWithPagination = async (franchise_id, page, perPage, sortField, sortDirection, search) => {
	try {
		const response = await axios.get(notesURL + `${franchise_id}/franchise?page=${page}&perPage=${perPage}&sortField=${sortField}&sortDirection=${sortDirection}&note=${search.note}&heading_id=${search.heading_id}
			&sub_heading_id=${search.sub_heading_id}&status=${search.status}&created_by=${search.created_by}&created_from_date=${search.created_from_date}&created_to_date=${search.created_to_date}`);
		if (response.status === 200 /*&& response.statusText === "OK"*/) {
			return response.data
		}
	} catch (error) {
		return error?.response?.data;
	}
};

export const getAllActiveHeadings = async () => {
	try {
		const response = await axios.get(notesURL + 'headings');
		if (response.status === 200 /*&& response.statusText === "OK"*/) {
			return response.data
		}
	} catch (error) {
		return error?.response?.data;
	}
};

export const getAllSubHeadingsById = async (headingId) => {
	try {
		const response = await axios.get(notesURL + `${headingId}/subheadings`);
		if (response.status === 200 /*&& response.statusText === "OK"*/) {
			return response.data
		}
	} catch (error) {
		return error?.response?.data;
	}
};

export const createNote = async (payload) => {
	try {
		const response = await axios.post(notesURL + 'create', payload);
		if (response.status === 201 /*&& response.statusText === "Created"*/) {
			return response.data
		}
	} catch (error) {
		return error?.response?.data;
	}
};

export const getNoteById = async (id: number) => {
	try {
		const response = await axios.get(notesURL + id);
		if (response.status === 200 /*&& response.statusText === "OK"*/) {
			return response.data
		}
	} catch (error) {
		return error?.response?.data;
	}
};

export const addCommentOnNote = async (payload) => {
	try {
		const response = await axios.post(notesURL + 'create/comment', payload);
		if (response.status === 200 /*&& response.statusText === "Created"*/) {
			return response.data
		}
	} catch (error) {
		return error?.response?.data;
	}
};

export const closeNote = async (payload) => {
	try {
		const response = await axios.put(notesURL + 'close', payload);
		if (response.status === 200 /*&& response.statusText === "OK"*/) {
			return response.data
		}
	} catch (error) {
		return error?.response?.data;
	}
};

export const getNoteDocumentById = async (docId: number, docType: string) => {
	try {
		const response = await axios.get(notesURL + 'document/' + docId + `/${docType}`, { responseType: 'blob' });
		if (response.status === 200 /*&& response.statusText === "OK"*/) {
			return response.data
		}
	} catch (error) {
		return error?.response?.data;
	}
};

export const getNoteUsers = async (franchiseId: number) => {
	try {
		const response = await axios.get(notesURL + `${franchiseId}/users`);
		if (response.status === 200 /*&& response.statusText === "OK"*/) {
			return response.data
		}
	} catch (error) {
		return error?.response?.data;
	}
};