import { Modal } from 'react-bootstrap';
import moment from 'moment';
import CurrencyFormat from 'react-currency-format';

const StudentSubjectHistoryModal = (props) => {

    const vatTypes = [{value: 'inclusive' , name:'Inclusive'}, {value: 'exclusive', name:'Exclusive'}, {value: 'not_applicable', name:'N/A'}];

    return (
    <div>
        <Modal show={props.show} onHide={props.handleClose} size="lg">
            <Modal.Header closeButton>
                <Modal.Title>{props.subjectName} - {props.historyType == 'rate' ? 'History' : 'First Invoice History'}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className='row'>
                    <div className="col-md-12">
                        {(props.historyData)?.length > 0 && props.historyType == 'rate' && (
                            <table className="table table-bordered" id="client_id_table" key='student-subject-history'>
                                <tbody>
                                    <tr className='text-center'>
                                        <th>Rate</th>
                                        <th>VAT</th>
                                        <th>Status</th>
                                        <th>Start Date</th>
                                        <th>End Date</th>
                                        <th>Created Date</th>
                                    </tr>
                                    {(props.historyData)?.length > 0  && (props.historyData).map((subject, i) => (
                                        <tr key={subject.id + '-' + i} className='text-center'>
                                            <td><CurrencyFormat value={ subject?.rate } decimalScale={2} displayType={'text'} thousandSeparator={true} prefix={props?.currency + ' '} /> </td>
                                            <td>
                                                { subject.vat_status ? (vatTypes.find(obj => { return obj.value === subject.vat_status}))?.name : '---'}
                                            </td>
                                            <td>{ subject.status === 'active' ? 
                                                <span className="badge bg-success">Active</span> 
                                                : (subject.status === 'inactive' ? <span className="capitalize badge bg-danger">{subject.status.charAt(0).toUpperCase() + subject.status.slice(1)}</span> : <span className="capitalize badge bg-warning">{subject.status.charAt(0).toUpperCase() + subject.status.slice(1)}</span>)  }
                                            </td>
                                            <td>{ (subject.start_date != null) ? moment(subject.start_date).format('DD/MM/YYYY') : 'N/A'}</td>
                                            <td>{ (subject.end_date != null) ? moment(subject.end_date).format('DD/MM/YYYY') : 'N/A'}</td>
                                            <td>{ (subject.created_at != null) ? moment(subject.created_at).format('DD/MM/YYYY HH:mm') : 'N/A'}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        )}
                        {(props.historyData)?.length > 0 && props.historyType == 'first-invoice-rate' && (
                            <table className="table table-bordered" id="client_id_table" key='first-invoice-history'>
                                <tbody>
                                    <tr className='text-center'>
                                        <th>First Invoice Amount</th>
                                        <th>VAT</th>
                                        {/* <th>Status</th> */}
                                        <th>Start Date</th>
                                        <th>Invoiced Month</th>
                                        <th>Created Date</th>
                                    </tr>
                                    {(props.historyData)?.length > 0  && (props.historyData).map((subject, i) => (
                                        <tr key={subject.id + '-' + i} className='text-center'>
                                            <td><CurrencyFormat value={ subject?.rate } decimalScale={2} displayType={'text'} thousandSeparator={true} prefix={props?.currency + ' '} /> </td>
                                            <td>
                                                { subject.vat_status ? (vatTypes.find(obj => { return obj.value === subject.vat_status}))?.name : '---'}
                                            </td>
                                            {/* <td>
                                                { subject.status === 'active' ? <span className="badge bg-success">Active</span> 
                                                    : <span className="badge badge-secondary">{subject.status.charAt(0).toUpperCase() + subject.status.slice(1)}</span>  }
                                            </td> */}
                                            <td>{ (subject.start_date != null) ? moment(subject.start_date).format('DD/MM/YYYY') : 'N/A'}</td>
                                            <td>{ (subject?.month != null) ? moment(subject.year + '-' +subject.month).format('MMM-YYYY') : 'N/A'}</td>
                                            <td>{ (subject.created_at != null) ? moment(subject.created_at).format('DD/MM/YYYY HH:mm') : 'N/A'}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        )}
                        {(props.historyData)?.length == 0 && (
                            <span style={{ color: "red", textAlign: "center" }}>There are no records to display</span>
                        )}
                    </div>
                </div>
            </Modal.Body>
            {/* <Modal.Footer>
                <Button className='btn btn-default' onClick={props.handleClose}>Close</Button>
            </Modal.Footer> */}
        </Modal>
    </div>
  )
}

export default StudentSubjectHistoryModal;