import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom';
import { deleteUserById, getAllUsers } from '@app/services/user';
import { confirmAlert } from 'react-confirm-alert';
import { toast } from 'react-toastify';
import DataTable, { TableStyles } from 'react-data-table-component';
import { Button } from '@app/components';
import { getAllRolesExceptEU } from '@app/services/role';
import { useSelector } from 'react-redux';

const ListUsers = () => {

    const [users, setUsers] = useState([]);
    const [roles, setRoles] = useState([]);
    const [isLoading, setLoading] = useState(false);
    const [totalRows, setTotalRows] = useState(0);
    const [perPage, setPerPage] = useState(10);
    const [currentPage, setCurrentPage] = useState(1);
    const [sortField, setSortField] = useState('');
    const [sortDirection, setSortDirection] = useState('');
    const [search, setSearch] = useState({firstName: '', lastName: '', email: '', phone: '', role: '', status: ''});
    const userStatus = [{label:'Active', value:'active'}, {label:'Inactive', value:'inactive'}]
    const permissions = useSelector((state: any) => state.auth.permissions);

    const customStyle: TableStyles = {
        head: {
            style: {
                color: "#5E5873",
                fontSize: "13px",
                fontWeight: 900,
                // borderBottomWidth: '0px',
                // borderBottomColor: theme.divider.default,
                // borderBottomStyle: 'solid',
            },
        },
        headCells: {
            style: {
                borderColor: "#E9ECEF",
                textTransform: "uppercase",
                letterSpacing: "1px",
            },
        },
        headRow: {
            style: {
                backgroundColor: "#F7F7F8",
                minHeight: "44px",
                fontWeight: 500,
            },
        },
    };
      
    useEffect(() => {
        getAllUsersData(1, perPage, sortField, sortDirection);
    },[search]);

    useEffect(() => {
        getAllRolesData();
    },[]);

    const getAllRolesData = async () => {
        try {
            const roleGroupsResp = await getAllRolesExceptEU();
            if (roleGroupsResp.success === true) {
                setRoles(roleGroupsResp.collections);
            } else {
                toast.error(roleGroupsResp?.errors[0]?.msg);
            }
        } catch (error: any) {
            toast.error(error?.message || 'Failed');
        }
    };

    const getAllUsersData = async (page, perPage, sortField, sortDirection) => {
        try {
            setLoading(true);
            const usersResp = await getAllUsers(page, perPage, sortField, sortDirection, search);
            if (usersResp.success === true) {
                setLoading(false);
                setUsers(usersResp.collections.data);
                setTotalRows(usersResp?.collections?.pagination?.total);
            } else {
                setLoading(false);
                toast.error(usersResp?.errors[0]?.msg);
            }
        } catch (error: any) {
            setLoading(false);
            toast.error(error?.message || 'Failed');
        }
    };

    const columns = [
        // {
        //     name: '#ID',
        //     selector: (row: { id: number; }) => row.id,
        //     sortable: true,
        // },
        {
            name: 'First Name',
            selector: (row: { first_name: string; }) => row.first_name,
            sortable: true,
            sortField: 'first_name',
        },
        {
            name: 'Last Name',
            selector: (row: { last_name: string; }) => row.last_name,
            sortable: true,
            sortField: 'last_name',
        },
        {
            name: 'Email',
            selector: (row: { email: string; }) => row.email,
            sortable: true,
            sortField: 'email',
            wrap: true,
        },
        {
            name: 'Phone',
            selector: (row: { phone: string; }) => row.phone ? row.phone : '---',
            sortable: true,
            sortField: 'phone',
        },
        // {
        //     name: 'Address',
        //     selector: (row: { address: string; }) => row.address,
        //     sortable: true,
        //     sortField: 'address',
        // },
        {
            name: 'Role(s)',
            selector: (row: { roles: string; }) => { return Array.prototype.map.call(row.roles, function(item) { return item.name; }).join(", "); },
            sortable: true,
            sortField: 'role',
            wrap: true,
        },
        {
            name: 'Status',
            selector: (row: { status: string; }) => row.status,
            cell: (row: { status: string; }) => (
                row.status === 'active' ? <span className="badge bg-success">Active</span> : <span className="badge bg-danger">Inactive</span>
            ),
            sortable: true,
            sortField: 'status',
        },
        {  
            name: 'Action',
            cell: (row: { id: number; }) => (
              <>
                { permissions?.users?.edit && 
                    <Link
                    to={`/users/edit/${row.id}`}
                    role="button"
                    state={{ id: row.id }}
                    >
                    <i className="fas fa-pen mr-2"/>
                    </Link>
                }
                { permissions?.users?.view && 
                    <Link
                    to={`/users/view/${row.id}`}
                    role="button"
                    state={{ id: row.id }}
                    data-toggle="tooltip" title="View"
                    >
                    <i className="fas fa-eye mr-2"/>
                    </Link>
                }
                { permissions?.users['update-dbs'] && 
                    <Link
                        to={`/users/dbs/${row.id}`}
                        role="button"
                        state={{ id: row.id }}
                        data-toggle="tooltip" title="Update DBS Cert Details"
                    >
                        <i className="fas fa-file-signature mr-2"/>
                    </Link>
                }
                { permissions?.users?.delete &&
                    <a href='#' onClick={ (e) => { deleteUserConfirm(e, row.id)} }>
                        <i className="fas fa-trash mr-2"/>
                    </a>
                }
              </>
            ),
        },
    ];

    const deleteUserConfirm = (e, id) => {
        confirmAlert({
            title: 'Confirm to submit',
            message: 'Are you sure to delete this?',
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => deleteUser(id)
                },
                {
                    label: 'No',
                    onClick: () => {}
                }
            ]
        });
        e.preventDefault();
    };

    const deleteUser = async (id) => {
        try {
            setLoading(true);
            const resp = await deleteUserById(id);
            if(resp.success === true){
                toast.success(resp?.message);
                setLoading(false);
                getAllUsersData(currentPage, perPage, sortField, sortDirection);
            }else{
                toast.error(resp?.errors[0]?.msg);
                setLoading(false);
            }
          } catch (error: any) {
            setLoading(false);
            toast.error(error?.message || 'Failed');
          }
    };

    const handlePageChange = page => {
        getAllUsersData(page, perPage, sortField, sortDirection);
        setCurrentPage(page);
    };

    const handlePerRowsChange = async (newPerPage, page) => {
        getAllUsersData(page, newPerPage, sortField, sortDirection);
        setPerPage(newPerPage);
    };

    const handleSort = async (column, sortDirection) => {
        setSortField(column.sortField);
        setSortDirection(sortDirection);
        getAllUsersData(currentPage, perPage, column.sortField, sortDirection);
    };

    const handleResetFilter = async () => {
        setSearch({firstName: '', lastName: '', email: '', phone: '', role: '', status: ''});
    };
    
    return (
        <div>
            <div>
                {/* Content Header (Page header) */}
                <section className="content-header">
                    <div className="container-fluid">
                        <div className="row mb-2">
                            <div className="col-sm-6">
                                <h1>Manage Users</h1>
                            </div>
                            <div className="col-sm-6">
                                <ol className="breadcrumb float-sm-right">
                                    <li className="breadcrumb-item"><a href="/">Home</a></li>
                                    <li className="breadcrumb-item active">Manage Users</li>
                                </ol>
                            </div>
                        </div>
                    </div>{/* /.container-fluid */}
                </section>
                {/* Main content */}
                <section className="content">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-12">
                                <div style={{ marginBottom: 10 }} className="row">
                                    
                                </div>
                                <div className="card">
                                    <div className="card-header">
                                        <h3 className="card-title">Manage Users</h3>
                                        <div className="col-lg-12 text-right">
                                            { permissions?.users?.create && <Link className="btn btn-primary" to="/users/add" > + Add New User </Link> }
                                        </div>
                                    </div>
                                    {/* /.card-header */}
                                    <div className="card-body">
                                        <div className="row mb-2">
                                            <div className="col-sm-2">
                                                <input type="text" name='firstName' className="form-control" value={search.firstName} onChange={(e) => setSearch({ ...search, firstName: e.target.value })} placeholder="Search First Name" />
                                            </div>
                                            <div className="col-sm-2">
                                                <input type="text" name='lastName' className="form-control" value={search.lastName} onChange={(e) => setSearch({ ...search, lastName: e.target.value })} placeholder="Search Last Name" />
                                            </div>
                                            <div className="col-sm-2">
                                                <input type="text" name='email' className="form-control" value={search.email} onChange={(e) => setSearch({ ...search, email: e.target.value })} placeholder="Search Email" />
                                            </div>
                                            <div className="col-sm-2">
                                                <input type="text" name='phone' className="form-control" value={search.phone} onChange={(e) => setSearch({ ...search, phone: e.target.value })} placeholder="Search Phone" />
                                            </div>
                                            <div className="col-sm-2">
                                                <select
                                                    className="form-control"
                                                    name="role"
                                                    onChange={(e) => setSearch({ ...search, role: e.target.value })}
                                                    value={search.role}
                                                >
                                                    <option value="">Search By Role</option>
                                                    {roles.map((obj) => (
                                                        <option key={obj.slug} value={obj.slug}>
                                                            {obj.name}
                                                        </option>
                                                    ))}
                                                </select>
                                            </div>
                                            <div className="col-sm-2">
                                                <select
                                                    className="form-control"
                                                    name="status"
                                                    onChange={(e) => setSearch({ ...search, status: e.target.value })}
                                                    value={search.status}
                                                >
                                                    <option value="">Search By Status</option>
                                                    {userStatus.map((obj) => (
                                                        <option key={obj.value} value={obj.value}>
                                                            {obj.label}
                                                        </option>
                                                    ))}
                                                </select>
                                            </div>
                                        </div>
                                        <div className='row mb-2'>
                                            <div className='col-sm-2'>
                                                <Button type="submit" isLoading={isLoading} onClick={handleResetFilter} className="btn btn-warning">Reset Filter</Button>
                                            </div>
                                        </div>
                                        <DataTable
                                            columns={columns}
                                            data={users}
                                            pagination
                                            paginationServer
                                            paginationTotalRows={totalRows}
                                            paginationDefaultPage={currentPage}
                                            onChangePage={handlePageChange}
                                            onChangeRowsPerPage={handlePerRowsChange}
                                            paginationPerPage={perPage}
                                            paginationRowsPerPageOptions={[5, 10, 20, 30, 50, 100]}
                                            progressPending={isLoading}
                                            sortServer
                                            onSort={handleSort}
                                            // paginationComponent
                                            // selectableRows
                                            customStyles={customStyle}
                                        />
                                    </div>
                                    {/* /.card-body */}
                                </div>
                            </div>
                            {/* /.col */}
                        </div>
                        {/* /.row */}
                    </div>
                    {/* /.container-fluid */}
                </section>
                {/* /.content */}
            </div>

        </div>
    )
};

export default ListUsers;
