import axios from "axios";
import { createUserURL, deleteUserURL, getAllUsersURL, getCentreAdminsURL, getFranchiseAdminsURL, getUserByIdURL, updateUserPasswordURL, updateUserProfileURL, updateUserURL, userDBSURL, viewUserByIdURL } from "@app/constants/ApiEndPoints";

export const getAllUsers = async (page, perPage, sortField, sortDirection, search) => {
	try {
		const response = await axios.get(getAllUsersURL + `?page=${page}&perPage=${perPage}&sortField=${sortField}&sortDirection=${sortDirection}
															&firstName=${search.firstName}&lastName=${search.lastName}
															&email=${search.email}&phone=${search.phone}
															&role=${search.role}&status=${search.status}`);
		if (response.status === 200 /*&& response.statusText === "OK"*/) {
			return response.data
		}
	} catch (error) {
		return error?.response?.data;
	}
};

export const createUser = async (payload) => {
	try {
		const response = await axios.post(createUserURL, payload);
		if (response.status === 201 /*&& response.statusText === "Created"*/) {
			return response.data
		}
	} catch (error) {
		return error?.response?.data;
	}
};

export const getUserById = async (id: number) => {
	try {
		const response = await axios.get(getUserByIdURL + id);
		if (response.status === 200 /*&& response.statusText === "OK"*/) {
			return response.data
		}
	} catch (error) {
		return error?.response?.data;
	}
};

export const updateUser = async (id, payload) => {
	try {
		const response = await axios.put(updateUserURL + id, payload);
		if (response.status === 200 /*&& response.statusText === "OK"*/) {
			return response.data
		}
	} catch (error) {
		return error?.response?.data;
	}
};

export const deleteUserById = async (id: number) => {
	try {
		const response = await axios.delete(deleteUserURL + id);
		if (response.status === 200 /*&& response.statusText === "OK"*/) {
			return response.data;
		}
		return response?.data;
	} catch (error) {
		return error?.response?.data;
	}
};

export const updateUserPassword = async (payload) => {
	try {
		const response = await axios.patch(updateUserPasswordURL, payload);
		if (response.status === 200 /*&& response.statusText === "OK"*/) {
			return response.data
		}
	} catch (error) {
		return error?.response?.data;
	}
};

export const updateUserProfile = async (payload) => {
	try {
		const response = await axios.patch(updateUserProfileURL, payload);
		if (response.status === 200 /*&& response.statusText === "OK"*/) {
			return response.data
		}
	} catch (error) {
		return error?.response?.data;
	}
};

export const getFranchiseAdmins = async () => {
	try {
		const response = await axios.get(getFranchiseAdminsURL);
		if (response.status === 200 /*&& response.statusText === "OK"*/) {
			return response.data
		}
	} catch (error) {
		return error?.response?.data;
	}
};

export const getCentreAdmins = async () => {
	try {
		const response = await axios.get(getCentreAdminsURL);
		if (response.status === 200 /*&& response.statusText === "OK"*/) {
			return response.data
		}
	} catch (error) {
		return error?.response?.data;
	}
};

export const viewUserById = async (id: number) => {
	try {
		const response = await axios.get(viewUserByIdURL + id + '/view');
		if (response.status === 200 /*&& response.statusText === "OK"*/) {
			return response.data
		}
	} catch (error) {
		return error?.response?.data;
	}
};

export const getDBSByUserId = async (id: number) => {
	try {
		const response = await axios.get(userDBSURL + id);
		if (response.status === 200 /*&& response.statusText === "OK"*/) {
			return response.data
		}
	} catch (error) {
		return error?.response?.data;
	}
};

export const updateUserDBSDetails = async (id, payload) => {
	try {
		const response = await axios.put(userDBSURL + id, payload);
		if (response.status === 201 /*&& response.statusText === "OK"*/) {
			return response.data
		}
	} catch (error) {
		return error?.response?.data;
	}
};

export const getDBSDocumentById = async (docId: number) => {
	try {
		const response = await axios.get(userDBSURL + 'document/' + docId, { responseType: 'blob' });
		if (response.status === 200 /*&& response.statusText === "OK"*/) {
			return response.data
		}
	} catch (error) {
		return error?.response?.data;
	}
};

export const deleteDBSDocumentById = async (docId: number) => {
	try {
		const response = await axios.delete(userDBSURL + 'document/' + docId);
		if (response.status === 201 /*&& response.statusText === "OK"*/) {
			return response.data
		}
	} catch (error) {
		return error?.response?.data;
	}
};

export const getDBSUsersByFranchiseId = async (franchise_id: number) => {
	try {
		const response = await axios.get(userDBSURL + 'franchise/' + franchise_id );
		if (response.status === 200 /*&& response.statusText === "OK"*/) {
			return response.data
		}
	} catch (error) {
		return error?.response?.data;
	}
};