import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import { loadUser, loginUser, setPermissions, setSelectedRole } from '@store/reducers/auth';
import { Checkbox, Button } from '@components';
import { faEnvelope, faLock } from '@fortawesome/free-solid-svg-icons';
import { setWindowClass } from '@app/utils/helpers';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import jwt_decode from "jwt-decode";
import * as Yup from 'yup';

import { Form, InputGroup } from 'react-bootstrap';
import * as AuthService from '../../services/auth';
import { getCompactPermissionByRoleId } from '@app/services/permission';
import { setNavbarVariant, setSidebarSkin } from '@app/store/reducers/ui';
let base64 = require('base-64');
let utf8 = require('utf8');

const Login = () => {
	const [isAuthLoading, setAuthLoading] = useState(false);
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const [t] = useTranslation();

	const login = async (email: string, password: string) => {
		try {
			setAuthLoading(true);
			const resp = await AuthService.loginByAuth({ email: email, password: password });
			if (resp.success === true) {
				// toast.success(resp?.message);
				setAuthLoading(false);
				// dispatch(loginUser(resp.token));

				const decoded = jwt_decode(resp.token);
				const userData = {
					id: decoded['id'],
					first_name: decoded['first_name'],
					last_name: decoded['last_name'],
					email: decoded['email'],
					phone: decoded['phone'],
					roles: decoded['roles'],
					picture: null,
					token: resp.token,
				};
				dispatch(loadUser(userData));

				if(process.env.REACT_APP_ENV === 'production'){
					dispatch(setNavbarVariant('navbar-light'));
					dispatch(setSidebarSkin('sidebar-dark-primary'));
				}else{
					dispatch(setNavbarVariant('navbar-lightblue navbar-dark'));
					dispatch(setSidebarSkin('sidebar-light-lightblue'));
				}
				
				if(decoded['roles'].length > 1){
					navigate('/choose-role');
				}else{
					const resp = await getCompactPermissionByRoleId(decoded['roles'][0].id);
					if (resp.success === true) {
						setAuthLoading(false);
						const decoded = jwt_decode(resp.token);
						let permissions = JSON.parse(utf8.decode(base64.decode(decoded['access'])));
						
						dispatch(setSelectedRole(decoded['roles'][0]));
						dispatch(setPermissions(permissions));
						dispatch(loginUser(resp.token));
						navigate('/');
					} else {
						setAuthLoading(false);
						toast.error(resp?.message);
					}
				}
			} else {
				toast.error(resp?.errors[0]?.msg);
				setAuthLoading(false);
			}
		} catch (error: any) {
			setAuthLoading(false);
			toast.error(error.message || 'Something went wrong');
		}
	};

	const { handleChange, values, handleSubmit, touched, errors } = useFormik({
		initialValues: {
			email: '',
			password: ''
		},
		validationSchema: Yup.object({
			email: Yup.string().email('Invalid email address').required('Please enter email'),
			password: Yup.string()
				.min(5, 'Must be 5 characters or more')
				.max(30, 'Must be 30 characters or less')
				.required('Please enter password')
		}),
		onSubmit: (values) => {
			login(values.email, values.password);
		}
	});

	setWindowClass('hold-transition login-page');

	return (
		<div className="login-box">
			<div className="card card-outline card-primary">
				<div className="card-header text-center">
					<Link to="/" className="h1">
						<b>FCL </b>
						<span>Login</span>
					</Link>
				</div>
				<div className="card-body">
					<p className="login-box-msg">{t('login.label.signIn')}</p>
					<form onSubmit={handleSubmit}>
						<div className="mb-3">
							<InputGroup className="mb-3">
								<Form.Control
									id="email"
									name="email"
									type="email"
									placeholder="Email"
									onChange={handleChange}
									value={values.email}
									isValid={touched.email && !errors.email}
									isInvalid={touched.email && !!errors.email}
								/>
								{touched.email && errors.email ? (
									<Form.Control.Feedback type="invalid">
										{errors.email}
									</Form.Control.Feedback>
								) : (
									<InputGroup.Append>
										<InputGroup.Text>
											<FontAwesomeIcon icon={faEnvelope} />
										</InputGroup.Text>
									</InputGroup.Append>
								)}
							</InputGroup>
						</div>
						<div className="mb-3">
							<InputGroup className="mb-3">
								<Form.Control
									id="password"
									name="password"
									type="password"
									placeholder="Password"
									onChange={handleChange}
									value={values.password}
									isValid={touched.password && !errors.password}
									isInvalid={touched.password && !!errors.password}
								/>
								{touched.password && errors.password ? (
									<Form.Control.Feedback type="invalid">
										{errors.password}
									</Form.Control.Feedback>
								) : (
									<InputGroup.Append>
										<InputGroup.Text>
											<FontAwesomeIcon icon={faLock} />
										</InputGroup.Text>
									</InputGroup.Append>
								)}
							</InputGroup>
						</div>

						<div className="row">
							<div className="col-8">
								{/* <Checkbox type="icheck" checked={false}>
									{t('login.label.rememberMe')}
								</Checkbox> */}
							</div>
							<div className="col-4">
								<Button
									block
									type="submit"
									isLoading={isAuthLoading}
								>
									{t('login.button.signIn.label')}
								</Button>
							</div>
						</div>
					</form>

					<p className="mb-1">
						<Link to="/forgot-password">{t('login.label.forgotPass')}</Link>
					</p>
				</div>
			</div>
		</div>
	);
};

export default Login;
